import React, { useCallback, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';

import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { REQ_STATE_PENDING } from 'ecto-common/lib/utils/requestStatus';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API from 'ecto-common/lib/API/API';
import T from 'ecto-common/lib/lang/Language';
import Switch from 'ecto-common/lib/Switch/Switch';
import styles from 'js/components/EMPTools/PowerControl/Dispatch/AutomaticScheduleProviderToolbarButton.module.css';
import { SignalTypeIds } from 'ecto-common/lib/utils/constants';
import { useOperatorSelector } from 'js/reducers/storeOperator';

const automaticSchedulesEnabledProviderType = 'AutomaticPowerControlSchedules';

const AutomaticScheduleProviderToolbarButton = () => {
  const [
    showAutomaticSchedulesModal,
    onShowAutomaticSchedulesModal,
    onHideAutomaticSchedulesModal
  ] = useDialogState('show-enable-auto-schedules');
  const signalProvidersReqState = useOperatorSelector(
    (state) => state.schedule.signals
  );
  const [lastSignalValue, setLastSignalValue] = useState<number>(null);

  const automaticScheduleProvider = useMemo(() => {
    return _.find(signalProvidersReqState.payload, [
      'signalProviderType',
      automaticSchedulesEnabledProviderType
    ]);
  }, [signalProvidersReqState]);

  const automaticSchedulesEnabledSignal = useMemo(() => {
    return _.find(automaticScheduleProvider?.signals, [
      'signalTypeId',
      SignalTypeIds.AUTOMATIC_SCHEDULES_ENABLED_SIGNAL_TYPE_ID
    ]);
  }, [automaticScheduleProvider?.signals]);

  const [getLastSignalValueIsLoading, getLastSignalValue] = usePromiseCall({
    promise: API.Signals.getLastValue,
    onSuccess: (response) => {
      const signalValue = response?.[0].signals?.[0]?.value;
      setLastSignalValue(signalValue);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useEffect(() => {
    if (automaticSchedulesEnabledSignal) {
      getLastSignalValue([automaticSchedulesEnabledSignal.signalId]);
    }
  }, [automaticSchedulesEnabledSignal, getLastSignalValue]);

  const [setSignalValueIsLoading, setSignalValue] = usePromiseCall({
    promise: API.Signals.setSignalValueForProviderId,
    onSuccess: () => {
      const responseText = lastSignalValue
        ? T.powercontrol.dispatch.automatic.schedule.success.disable
        : T.powercontrol.dispatch.automatic.schedule.success.enable;
      toastStore.addSuccessToast(responseText);
      getLastSignalValue([automaticSchedulesEnabledSignal.signalId]);
      onHideAutomaticSchedulesModal();
    },
    onError: () => {
      const responseText = lastSignalValue
        ? T.powercontrol.dispatch.automatic.schedule.failure.enable
        : T.powercontrol.dispatch.automatic.schedule.failure.disable;
      toastStore.addErrorToast(responseText);
    }
  });

  const onSetSignalValue = useCallback(() => {
    setSignalValue(
      automaticScheduleProvider.signalProviderId,
      automaticSchedulesEnabledSignal.signalId,
      [
        {
          time: new Date().toISOString(),
          value: lastSignalValue ? 0 : 1
        }
      ]
    );
  }, [
    automaticScheduleProvider?.signalProviderId,
    automaticSchedulesEnabledSignal?.signalId,
    lastSignalValue,
    setSignalValue
  ]);

  const automaticScheduleProviderIsLoading =
    signalProvidersReqState.state === REQ_STATE_PENDING ||
    setSignalValueIsLoading ||
    getLastSignalValueIsLoading;

  return (
    <>
      {automaticScheduleProvider && (
        <ToolbarItem>
          <div className={styles.automaticSchedulesContainer}>
            <label>{T.powercontrol.dispatch.automatic.schedule.label}: </label>

            <Switch
              isOn={lastSignalValue === 1.0}
              onClick={onShowAutomaticSchedulesModal}
              isLoading={automaticScheduleProviderIsLoading}
              disabled={automaticScheduleProviderIsLoading}
            />
          </div>
        </ToolbarItem>
      )}

      <ActionModal
        isOpen={showAutomaticSchedulesModal}
        onConfirmClick={onSetSignalValue}
        onModalClose={onHideAutomaticSchedulesModal}
        title={T.powercontrol.dispatch.automatic.schedule.label}
        actionText={lastSignalValue ? T.common.disable : T.common.enable}
        isLoading={automaticScheduleProviderIsLoading}
      >
        <>
          {lastSignalValue
            ? T.powercontrol.dispatch.automatic.schedule.verify.disable
            : T.powercontrol.dispatch.automatic.schedule.verify.enable}
        </>
      </ActionModal>
    </>
  );
};

export default AutomaticScheduleProviderToolbarButton;
