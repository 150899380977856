import React, { useCallback, useMemo, useState } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import ToolbarMenuDivider from 'ecto-common/lib/Toolbar/ToolbarMenuDivider';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import DashboardDeleteModal from 'ecto-common/lib/DashboardEditor/DashboardDeleteModal';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import EditDashboardPropertiesModal from 'ecto-common/lib/DashboardEditor/EditDashboardPropertiesModal';
import { InitialDashboard } from 'ecto-common/lib/DashboardEditor/DashboardConstants';
import UUID from 'uuidjs';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import styles from './DashboardEditor.module.css';
import _ from 'lodash';
import useUndoShortcuts from 'ecto-common/lib/hooks/useUndoShortcuts';
import { DashboardResponseModel } from 'ecto-common/lib/API/APIGen';

const optionTitle = (
  name: string,
  dashboardId: string,
  dashboardsWithChanges: Record<string, boolean>
) => {
  if (dashboardsWithChanges[dashboardId] != null) {
    return T.format(T.dashboard.titlewithchangesformat, name);
  }

  return name;
};

interface DashboardMenuProps {
  allDashboards?: DashboardResponseModel[];
  isLoadingDashboards?: boolean;
  enableSelect?: boolean;
  onChangeDashboard?: (newDashboard: DashboardResponseModel) => void;
  dashboardsWithChanges?: Record<string, boolean>;
  dashboard?: DashboardResponseModel;
  onAddPanel?: React.MouseEventHandler<HTMLButtonElement>;
  onDashboardUpdated?(
    editedDashboard: DashboardResponseModel,
    isNew: boolean
  ): void;
  onDeleteDashboard?(deleteItem: DashboardResponseModel): void;
  showAddNewDashboard?: boolean;
  onSave?: React.MouseEventHandler<HTMLButtonElement>;
  disableEditing?: boolean;
  hasChanges?: boolean;
  dashboardUserId?: string;
  isSavingDashboards?: boolean;
  hasError?: boolean;
  undo: () => void;
  enableUndo: boolean;
  redo: () => void;
  enableRedo: boolean;
  handlePersonalDashboards?: boolean;
}

const DashboardMenu = ({
  allDashboards = null,
  isLoadingDashboards = false,
  isSavingDashboards = false,
  enableSelect = true,
  onChangeDashboard,
  dashboardsWithChanges = {},
  dashboard,
  onDashboardUpdated,
  onDeleteDashboard,
  showAddNewDashboard,
  onAddPanel,
  onSave,
  disableEditing,
  hasChanges,
  dashboardUserId,
  hasError = false,
  undo,
  enableUndo,
  redo,
  enableRedo,
  handlePersonalDashboards = false
}: DashboardMenuProps) => {
  const [showingDeleteModal, showDeleteModal, hideDeleteModal] = useDialogState(
    'show-delete-dashboard'
  );
  const [
    showingPropertyEditorModal,
    showPropertyEditorModal,
    hidePropertyEditorModal
  ] = useDialogState('show-edit-dashboard');
  const [newDashboard, setNewDashboard] =
    useState<DashboardResponseModel>(null);

  const selectOptions: GenericSelectOption<DashboardResponseModel>[] = useMemo(
    () =>
      _.map(allDashboards, (selectDashboard) => ({
        label: optionTitle(
          selectDashboard.name,
          selectDashboard.dashboardId,
          dashboardsWithChanges
        ),
        value: selectDashboard
      })),
    [allDashboards, dashboardsWithChanges]
  );

  const selectedOption = _.find(selectOptions, ['value', dashboard]);

  const selector = enableSelect && (
    <ToolbarItem>
      <Select<GenericSelectOption<DashboardResponseModel>, false>
        className={styles.dashboardSelector}
        options={selectOptions}
        onChange={(option: GenericSelectOption<DashboardResponseModel>) => {
          onChangeDashboard(option.value);
        }}
        value={selectedOption}
        placeholder={
          isLoadingDashboards ? T.common.loading : T.dashboard.selectdashboard
        }
        disabled={
          isSavingDashboards ||
          (!isLoadingDashboards && _.isEmpty(selectOptions))
        }
        isLoading={isLoadingDashboards}
      />
    </ToolbarItem>
  );

  const onAddNewDashboard = useCallback(() => {
    setNewDashboard({
      ...InitialDashboard,
      userId: dashboardUserId,
      dashboardId: UUID.generate()
    });
    showPropertyEditorModal();
  }, [dashboardUserId, showPropertyEditorModal]);

  const _onDashboardUpdated = useCallback(
    (editedDashboard: DashboardResponseModel, isNew: boolean) => {
      setNewDashboard(null);
      onDashboardUpdated(editedDashboard, isNew);
    },
    [onDashboardUpdated]
  );

  const disableButtons = disableEditing || isSavingDashboards;
  useUndoShortcuts(undo, redo, enableUndo || enableRedo);
  const onCloseEditDashboardModal = useCallback(() => {
    setNewDashboard(null);
    hidePropertyEditorModal();
  }, [hidePropertyEditorModal]);
  return (
    <>
      {selector}

      <ToolbarMenu>
        {showAddNewDashboard && (
          <>
            <ToolbarMenuButton
              tooltipText={T.admin.dashboards.add}
              icon={<Icons.Add />}
              disabled={isLoadingDashboards || isSavingDashboards || hasError}
              onClick={onAddNewDashboard}
            />
            <ToolbarMenuDivider />
          </>
        )}
        <ToolbarMenuButton
          tooltipText={T.admin.dashboards.panels.add}
          icon={<Icons.AddPanel />}
          disabled={disableButtons}
          onClick={onAddPanel}
        />
        <ToolbarMenuButton
          tooltipText={T.admin.dashboards.edit}
          icon={<Icons.Edit />}
          onClick={showPropertyEditorModal}
          disabled={disableButtons}
        />
        <ToolbarMenuDivider />
        <ToolbarMenuButton
          tooltipText={T.common.save}
          icon={<Icons.Save />}
          onClick={onSave}
          disabled={!hasChanges || disableButtons}
        />
        <ToolbarMenuButton
          tooltipText={T.admin.dashboards.deletebutton}
          icon={<Icons.Delete />}
          onClick={showDeleteModal}
          disabled={disableButtons}
        />
        {(redo || undo) && (
          <>
            <ToolbarMenuDivider />
            {undo && (
              <ToolbarMenuButton
                tooltipText={T.common.undo}
                icon={<Icons.Undo />}
                onClick={undo}
                disabled={disableButtons || !enableUndo}
              />
            )}
            {redo && (
              <ToolbarMenuButton
                tooltipText={T.common.redo}
                icon={<Icons.Redo />}
                onClick={redo}
                disabled={disableButtons || !enableRedo}
              />
            )}
          </>
        )}
      </ToolbarMenu>
      <DashboardDeleteModal
        onSuccess={onDeleteDashboard}
        onModalClose={hideDeleteModal}
        deleteItem={showingDeleteModal ? dashboard : null}
        handlePersonalDashboards={handlePersonalDashboards}
      />

      <EditDashboardPropertiesModal
        isOpen={showingPropertyEditorModal}
        onCloseModal={onCloseEditDashboardModal}
        dashboard={newDashboard ?? dashboard}
        onDashboardUpdated={_onDashboardUpdated}
        isNew={newDashboard != null}
      />
    </>
  );
};

export default React.memo(DashboardMenu);
