import React, { useCallback, useMemo } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';

import _ from 'lodash';

const ReactGridLayout = WidthProvider(RGL);
import Panel from './Panel';
import styles from './Dashboard.module.css';
import panelStyles from './Panel.module.css';
import dimensions from 'ecto-common/lib/styles/dimensions';
import { withSize } from 'react-sizeme';
import { generateResponsiveDashboardLayout } from 'ecto-common/lib/Dashboard/util/responsiveDashboardUtil';
import { PanelDropDownOption } from 'ecto-common/lib/Dashboard/PanelDropDownMenu';
import { DashboardFile } from 'ecto-common/lib/DashboardEditor/DashboardConstants';

export const DASHBOARD_COLUMN_COUNT = 24;
const DASHBOARD_ROW_HEIGHT = 30;
const DASHBOARD_VMARGIN = dimensions.standardMargin;
const DASHBOARD_HMARGIN = DASHBOARD_VMARGIN;

const PANEL_MIN_WIDTH = 4;
const PANEL_MIN_HEIGHT = 4;

interface DashboardProps {
  data: DashboardFile;
  isResizable?: boolean;
  isDraggable?: boolean;
  size: { width?: number; height?: number };
  onLayoutChange?: (layout: RGL.Layout[]) => void;
  menuOptions?: PanelDropDownOption[];
  responsive?: boolean;
}

const Dashboard = ({
  responsive = false,
  data,
  isResizable = false,
  isDraggable = false,
  size,
  onLayoutChange = undefined,
  menuOptions
}: DashboardProps) => {
  const isCompact = size.width < 900;
  const cacheContext = useMemo(() => new Map(), []);
  const layout = useMemo(() => {
    if (!responsive) {
      return _.map(data.panels, (panel) => ({ i: panel.id, ...panel.gridPos }));
    }

    const _layout = _.map(data?.panels, (panel) => ({
      x: panel.gridPos.x,
      y: panel.gridPos.y,
      w: Math.max(panel.gridPos.w, PANEL_MIN_WIDTH),
      h: Math.max(panel.gridPos.h, PANEL_MIN_HEIGHT),
      minW: PANEL_MIN_WIDTH,
      minH: PANEL_MIN_HEIGHT,
      i: panel.id
    }));

    return generateResponsiveDashboardLayout(
      _layout,
      data?.panels ?? [],
      size.width
    );
  }, [data.panels, size.width, responsive]);

  const panelComponents = useMemo(() => {
    return (
      _.map(_.orderBy(data.panels, ['gridPos.y', 'gridPos.x']), (panel) => {
        let dataGrid;

        if (!responsive) {
          dataGrid = {
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            w: Math.max(panel.gridPos.w, PANEL_MIN_WIDTH),
            h: Math.max(panel.gridPos.h, PANEL_MIN_HEIGHT),
            minW: PANEL_MIN_WIDTH,
            minH: PANEL_MIN_HEIGHT
          };
        }
        return (
          <div
            key={panel.id}
            className={isCompact && styles.panelFullWidth}
            data-grid={dataGrid}
          >
            <Panel
              panel={panel}
              menuOptions={menuOptions}
              cacheContext={cacheContext}
            />
          </div>
        );
      }) ?? []
    );
  }, [data.panels, isCompact, menuOptions, cacheContext, responsive]);

  const handleResize = useCallback(() => {
    _.defer(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }, []);
  return (
    <ReactGridLayout
      useCSSTransforms
      autoSize
      containerPadding={[0, 0]}
      cols={DASHBOARD_COLUMN_COUNT}
      layout={layout}
      margin={[DASHBOARD_VMARGIN, DASHBOARD_HMARGIN]}
      rowHeight={DASHBOARD_ROW_HEIGHT}
      onLayoutChange={onLayoutChange}
      onResizeStop={handleResize}
      isResizable={isResizable}
      isDraggable={isDraggable}
      draggableHandle={'.' + panelStyles.panelTitle}
      draggableCancel={'.' + panelStyles.buttonContainer}
    >
      {panelComponents}
    </ReactGridLayout>
  );
};

const DashboardWithSize = withSize({ monitorWidth: true })(Dashboard);
export default React.memo(DashboardWithSize);
