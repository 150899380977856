import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';

import cssDurationToMilliseconds from 'ecto-common/lib/utils/cssDurationToMilliseconds';
import animations from 'ecto-common/lib/styles/variables/animations';
import Portal from 'ecto-common/lib/Portal/Portal';
import Transition from 'ecto-common/lib/Transition/Transition';
import { RemoveIcon } from 'ecto-common/lib/Icon/index';
import styles from 'ecto-common/lib/Modal/Modal.module.css';
import { ESC } from 'ecto-common/lib/utils/KeyboardShortcuts';

const modalSpeed = cssDurationToMilliseconds(animations.modalSpeed);

const transitionStyles = {
  appear: styles.enter,
  appearActive: styles.enterActive,
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive
};

interface ModalProps {
  /**
   * The content of the Modal. Should be Modal content classes (ModalHeader, ModalBody, ModalFooter)
   */
  children?: React.ReactNode;
  /**
   * Whether the dialog is open. Use this instead of { isOpen && <Modal ...> } to get correct animations.
   */
  isOpen: boolean;
  /**
   * Called whenever the modal closes.
   */
  onModalClose(): void;
  /**
   * Used to override the appearance of the modal. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Disables close button.
   */
  disableClose?: boolean;

  preventCloseDropdownMenu?: boolean;

  style?: React.CSSProperties;
}

/**
 * A Modal component renders its content in a window dialog that is centered on the screen.
 */
const Modal = ({
  children,
  isOpen,
  onModalClose,
  className,
  disableClose,
  preventCloseDropdownMenu,
  style = null
}: ModalProps) => {
  const modalRef = useRef(null);

  const onCloseClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event) {
        event.stopPropagation();
      }

      if (!disableClose) {
        onModalClose();
      }
    },
    [disableClose, onModalClose]
  );

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.keyCode === ESC.keyCode) {
        // Find the topmost modal container in the body (the top one of document.body that holds our
        // container class name). If the modal container contains the modalRef, close the modal (because
        // that means that we are at the top).
        const bodyChildren = Array.from(document.body.children);

        for (let i = bodyChildren.length - 1; i >= 0; i--) {
          const child = bodyChildren[i];

          // Check if the className contains the specified string
          if (child.className.includes(styles.modalContainer)) {
            // Check if the child contains the referenced element
            if (child.contains(modalRef.current)) {
              onModalClose();
            }
            break; // Stop iterating once the topmost matching child is found
          }
        }
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    }

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onModalClose]);

  return (
    <Portal isOpen={isOpen} closeTimeout={modalSpeed}>
      <Transition
        className={classNames(styles.modalContainer)}
        classNames={transitionStyles}
        timeout={{
          appear: modalSpeed,
          enter: modalSpeed,
          exit: modalSpeed
        }}
      >
        {isOpen && (
          <div className={styles.modalBackdrop}>
            <div
              ref={modalRef}
              style={style}
              className={classNames(styles.modal, className)}
              data-disableclosedropdownmenuonclick={
                preventCloseDropdownMenu ? true : undefined
              }
            >
              <div className={styles.modalBackground} />

              <div
                className={classNames(
                  styles.closeButton,
                  disableClose && styles.disabled
                )}
                onClick={disableClose ? undefined : onCloseClick}
              >
                <RemoveIcon
                  className={styles.closeIcon}
                  height={24}
                  width={24}
                />
              </div>

              {children}
            </div>
          </div>
        )}
      </Transition>
    </Portal>
  );
};

export default Modal;
