import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  Dispatch,
  SetStateAction
} from 'react';
import T from 'ecto-common/lib/lang/Language';
import Dashboard, {
  DASHBOARD_COLUMN_COUNT
} from 'ecto-common/lib/Dashboard/Dashboard';
import EditDashboardPanelModal, {
  InitialPanel
} from 'ecto-common/lib/DashboardEditor/EditDashboardPanelModal';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';
import _ from 'lodash';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import UUID from 'uuidjs';
import Icons from 'ecto-common/lib/Icons/Icons';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';
import { PanelDropDownOption } from 'ecto-common/lib/Dashboard/PanelDropDownMenu';
import RGL from 'react-grid-layout';
import { DashboardFile } from 'ecto-common/lib/DashboardEditor/DashboardConstants';

export const PANEL_INITIAL_SIZE = 6;

// Find a suitable x,y coordinate to place a new panel
export const findPanelInsertionPos = (
  panels: DashboardPanel[],
  width: number
) => {
  const gridPositions = _.map(panels, 'gridPos');
  const maxY = _.maxBy(gridPositions, 'y')?.y ?? 0;
  const gridPositionsOnRow = _.filter(
    gridPositions,
    (gridPos) => gridPos.y === maxY
  );
  const maxXPanelOnRow = _.maxBy(gridPositionsOnRow, (gridPos) => gridPos.x);
  const maxXOnRow =
    maxXPanelOnRow == null ? 0 : maxXPanelOnRow.x + maxXPanelOnRow.w;

  if (maxXOnRow + width <= DASHBOARD_COLUMN_COUNT) {
    return { x: maxXOnRow, y: maxY };
  }

  const maxYNewLineGridPos = _.maxBy(
    gridPositions,
    (gridPos) => gridPos.y + gridPos.h
  ) ?? { y: 0, h: 0 };
  return { x: 0, y: maxYNewLineGridPos.y + maxYNewLineGridPos.h };
};

interface DashboardEditorProps {
  dashboardFile?: DashboardFile;
  isLoading?: boolean;
  setDashboardFile: Dispatch<SetStateAction<DashboardFile>>;
  error?: React.ReactNode;
  triggerAddPanel?: number;
  onDashboardFileChanged?(newData: DashboardFile, file: DashboardFile): unknown;
  menuOptions?: PanelDropDownOption[];
  panelEditItem?: DashboardPanel;
  setPanelEditItem: (panel: DashboardPanel) => void;
}

const DashboardEditor = ({
  dashboardFile,
  isLoading,
  setDashboardFile,
  onDashboardFileChanged,
  triggerAddPanel,
  error,
  panelEditItem,
  setPanelEditItem,
  menuOptions: additionalMenuOptions
}: DashboardEditorProps) => {
  const setDashboardFileAndVersion = useCallback(
    (newDashboard: (file: DashboardFile) => DashboardFile) => {
      const newData = newDashboard(dashboardFile);
      if (newData !== dashboardFile) {
        onDashboardFileChanged(newData, dashboardFile);
      }

      setDashboardFile(newData);
    },
    [dashboardFile, onDashboardFileChanged, setDashboardFile]
  );

  const externalPanelTrigger = useRef(triggerAddPanel);

  useEffect(() => {
    if (externalPanelTrigger.current !== triggerAddPanel) {
      setPanelEditItem({
        ...InitialPanel,
        id: UUID.generate(),
        title: T.admin.dashboards.panels.initialtitle
      });
      externalPanelTrigger.current = triggerAddPanel;
    }
  }, [setPanelEditItem, triggerAddPanel]);

  const hideEditPanel = useCallback(
    () => setPanelEditItem(null),
    [setPanelEditItem]
  );
  const onEditPanel = useCallback(
    (panelToEdit: DashboardPanel) => setPanelEditItem(panelToEdit),
    [setPanelEditItem]
  );

  const onCopyPanel = useCallback(
    (panelToCopy: DashboardPanel) => {
      setDashboardFileAndVersion((oldDashboardFile: DashboardFile) => {
        const copy = _.cloneDeep(panelToCopy);
        copy.gridPos = {
          ...copy.gridPos,
          ...findPanelInsertionPos(oldDashboardFile.panels, PANEL_INITIAL_SIZE)
        };
        copy.id = UUID.generate();
        const newDashboard = {
          ...oldDashboardFile,
          panels: [...oldDashboardFile.panels]
        };
        newDashboard.panels.push(copy);
        return newDashboard;
      });
    },
    [setDashboardFileAndVersion]
  );

  const onDeletePanel = useCallback(
    (panel: DashboardPanel) => {
      setDashboardFileAndVersion((oldDashboardFile: DashboardFile) => {
        const itemPos = _.findIndex(oldDashboardFile.panels, ['id', panel.id]);
        const newDashboard = {
          ...oldDashboardFile,
          panels: [...oldDashboardFile.panels]
        };
        newDashboard.panels.splice(itemPos, 1);
        return newDashboard;
      });

      hideEditPanel();
    },
    [hideEditPanel, setDashboardFileAndVersion]
  );

  const menuOptions = useMemo<PanelDropDownOption[]>(
    () => [
      {
        icon: <Icons.Edit />,
        label: T.common.edit,
        action: onEditPanel
      },
      {
        icon: <Icons.Copy />,
        label: T.admin.dashboards.duplicate,
        action: onCopyPanel
      },
      {
        icon: <Icons.Delete />,
        label: T.common.delete,
        action: onDeletePanel
      },
      ...(additionalMenuOptions ?? [])
    ],
    [onEditPanel, onDeletePanel, additionalMenuOptions, onCopyPanel]
  );

  const onSubmitPanel = useCallback(
    (panel: DashboardPanel) => {
      const newPanel = { ...panel };

      setDashboardFileAndVersion((oldDashboardFile: DashboardFile) => {
        const itemPos = _.findIndex(oldDashboardFile?.panels, [
          'id',
          panelEditItem.id
        ]);
        const newDashboardFile = {
          ...oldDashboardFile,
          panels: [...oldDashboardFile.panels]
        };

        if (itemPos === -1) {
          newPanel.gridPos = {
            w: PANEL_INITIAL_SIZE,
            h: PANEL_INITIAL_SIZE,
            ...findPanelInsertionPos(
              oldDashboardFile.panels,
              PANEL_INITIAL_SIZE
            )
          };
          newDashboardFile.panels.push(newPanel);
        } else {
          newDashboardFile.panels[itemPos] = newPanel;
        }

        return newDashboardFile;
      });

      hideEditPanel();
    },
    [panelEditItem, hideEditPanel, setDashboardFileAndVersion]
  );

  const onLayoutChange = useCallback(
    (layout: RGL.Layout[]) => {
      setDashboardFileAndVersion((oldDashboardFile: DashboardFile) => {
        const newPanels = _.map(oldDashboardFile.panels, (panel) => {
          const newGridPos = layout.find((x) => x.i === panel.id);
          const oldGridPos = _.pick(panel.gridPos, ['x', 'y', 'w', 'h']);
          const _newGridPos = _.pick(newGridPos, ['x', 'y', 'w', 'h']);
          if (!_.isEqual(oldGridPos, _newGridPos) && !_.isEmpty(_newGridPos)) {
            return {
              ...panel,
              gridPos: _newGridPos
            };
          }
          return panel;
        });

        // If any of the actual grid positions have been changed, update the file. We need this since
        // we get layout changes initially without user input or changes.
        if (
          !_.isEqual(
            _.map(oldDashboardFile.panels, 'gridPos'),
            _.map(newPanels, 'gridPos')
          )
        ) {
          return { ...oldDashboardFile, panels: newPanels };
        }

        return oldDashboardFile;
      });
    },
    [setDashboardFileAndVersion]
  );

  const hasError = error != null;
  const showNoDataMessage =
    !hasError && dashboardFile != null && _.isEmpty(dashboardFile?.panels);

  return (
    <>
      {error && <ErrorNotice>{error}</ErrorNotice>}

      {!hasError && (
        <LoadingContainer isLoading={isLoading}>
          <NoDataMessage
            message={T.admin.dashboards.empty}
            visible={showNoDataMessage}
          />
          {dashboardFile && (
            <Dashboard
              data={dashboardFile}
              isResizable
              isDraggable
              onLayoutChange={onLayoutChange}
              menuOptions={menuOptions}
            />
          )}
        </LoadingContainer>
      )}
      <EditDashboardPanelModal
        isOpen={panelEditItem != null}
        onModalClose={hideEditPanel}
        item={panelEditItem}
        onSubmitPanel={onSubmitPanel}
        onDeletePanel={onDeletePanel}
        isNew={
          _.findIndex(dashboardFile?.panels, ['id', panelEditItem?.id]) === -1
        }
      />
    </>
  );
};

export default DashboardEditor;
