import React, { useCallback, useContext, useEffect, useState } from 'react';

import ToastContainer, {
  toastStore
} from 'ecto-common/lib/Toast/ToastContainer';
import Layout from 'ecto-common/lib/Layout/Layout';
import ContentArea from 'ecto-common/lib/Layout/ContentArea/ContentArea';
import { setUserSettings } from 'js/actions/settings';
import BaseContainer from 'ecto-common/lib/BaseContainer/BaseContainer';
import { cancellablePromiseList } from 'ecto-common/lib/API/API';
import { useDispatchResultRequest } from 'ecto-common/lib/hooks/useDispatchPromise';
import T from 'ecto-common/lib/lang/Language';
import API from 'ecto-common/lib/API/API';
import { AuthenticationErrorComponent } from 'ecto-common/lib/AuthenticationWrapper/AuthenticationWrapper';
import { setSignalTypes } from 'ecto-common/lib/actions/setSignalTypes';
import { setEnums } from 'ecto-common/lib/actions/getEnums';
import { setSignalTypeFolders } from 'ecto-common/lib/actions/setSignalTypeFolders';
import useAuthentication from 'ecto-common/lib/hooks/useAuthentication';

import { setNodeDashboardCollectionRelations } from 'js/actions/setDashboardRelations';
import { setDefaultDashboardCollection } from 'js/actions/setDefaultDashboardCollection';

import styles from 'js/containers/OperatorContainer.module.css';
import 'css/global.css';
import IdentityServiceAPI from 'ecto-common/lib/utils/IdentityServiceAPI';
import LoadingScreenWithMenu from 'ecto-common/lib/LoadingScreen/LoadingScreenWithMenu';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import { setNodes } from 'ecto-common/lib/actions/getNodes';
import { setNodeTags } from 'ecto-common/lib/actions/getNodeTags';
import { setEquipmentTypes } from 'ecto-common/lib/actions/getEquipmentTypes';
import {
  useOperatorSelector,
  useOperatorDispatch
} from 'js/reducers/storeOperator';
import { TenantUserSettingsModel } from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { CACHE_KEY_NODES } from 'ecto-common/lib/utils/cacheKeys';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import {
  HTTP_STATUS_NOT_FOUND,
  ResourceType
} from 'ecto-common/lib/constants/index';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { AuthError } from '@azure/msal-browser';
import APIGen from 'ecto-common/lib/API/APIGen';
import { useInitialUserSettings } from 'ecto-common/lib/Application/useInitialUserSettings';
import {
  AlarmEvent,
  AlarmUpdateProvider,
  AlarmUpdateContext,
  AlarmEventTypes
} from 'ecto-common/lib/Alarms/V2/useAlarmUpdates';
import { Outlet } from 'react-router-dom';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { getAlarmUrlV2 } from 'js/utils/routeConstants';
import { NavLink } from 'react-router-dom';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';

export function getNodesPromise(contextSettings: ApiContextSettings) {
  return cancellablePromiseList([
    API.Nodes.getAllNodes(contextSettings),
    API.Nodes.getGrids(contextSettings)
  ] as const);
}

const AlarmToastEmitter = ({ children }: { children: React.ReactNode }) => {
  const { tenantId } = useContext(TenantContext);
  const { addListener, removeListener } = useContext(AlarmUpdateContext);
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const equipmentMap = useCommonSelector((state) => state.general.equipmentMap);

  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );

  useEffect(() => {
    const listener = (data: AlarmEvent) => {
      if (!featureFlagState.newalarms) {
        return;
      }

      if (data.eventType === AlarmEventTypes.Activated) {
        const equipment = equipmentMap[data.nodeId];
        const node =
          equipment != null ? nodeMap[equipment.nodeId] : nodeMap[data.nodeId];

        const name =
          equipment != null
            ? equipment.name + ' - ' + (node?.name ?? '')
            : node?.name;

        let alarmText = '';

        if (!isNullOrWhitespace(data.signalName)) {
          alarmText = `${data.signalName} - ${name}`;
        } else {
          alarmText = name ?? T.common.unknown;
        }
        const url = getAlarmUrlV2(
          tenantId,
          node.nodeId,
          equipment?.equipmentId
        );

        const alarmMessage = T.format(
          T.alarms.alarmtriggeredformat,
          <NavLink key="navlink" to={url}>
            {alarmText}
          </NavLink>
        );

        toastStore.addSuccessToast(alarmMessage);
      }
    };

    addListener(listener);

    return () => {
      removeListener(listener);
    };
  }, [
    addListener,
    equipmentMap,
    featureFlagState.newalarms,
    nodeMap,
    removeListener,
    tenantId
  ]);

  return <> {children} </>;
};

const CoreWrapper = React.memo(() => {
  const { tenantId } = useContext(TenantContext);
  const [hasError, setHasError] = useState(false);
  const nodeTree = useOperatorSelector((state) => state.general.nodeTree);
  const { userId } = useContext(UserContext);

  const dispatch = useOperatorDispatch();
  const apiEnvironment = getApiEnvironment();
  const { isAuthenticated, instance, currentAccount } = useAuthentication(
    apiEnvironment.scopes.gateway
  );

  const [isLoadingNode, loadNodes] = useDispatchResultRequest(
    getNodesPromise,
    setNodes,
    setHasError,
    CACHE_KEY_NODES
  );
  const [isLoadingNodeTags, loadNodeTags] = useDispatchResultRequest(
    API.Nodes.getNodeTags,
    setNodeTags,
    setHasError
  );

  const updateSettings = useCallback(
    (result: TenantUserSettingsModel) => {
      const settings =
        result?.settings && typeof result?.settings === 'string'
          ? JSON.parse(result.settings)
          : {};
      dispatch(setUserSettings(settings));
    },
    [dispatch]
  );

  const clearSettings = useCallback(() => {
    dispatch(setUserSettings({}));
  }, [dispatch]);

  const handleDashboardError = useCallback(
    (error: { response?: { status: number } }) => {
      if (error?.response?.status === HTTP_STATUS_NOT_FOUND) {
        dispatch(setDefaultDashboardCollection(null));
      } else {
        setHasError(true);
      }
    },
    [dispatch]
  );

  const [, loadSettings] = useDispatchResultRequest(
    IdentityServiceAPI.TenantUser.getSettings,
    updateSettings,
    clearSettings
  );
  const [isLoadingDefaultDashboardCollection, loadDefaultDashboardCollection] =
    useDispatchResultRequest(
      APIGen.Dashboard.getDefaultDashboardCollectionView.promise,
      setDefaultDashboardCollection,
      null,
      null,
      handleDashboardError
    );
  const [
    isLoadingNodeDashboardCollectionRelations,
    loadNodeDashboardCollectionRelations
  ] = useDispatchResultRequest(
    API.Dashboard.getAllRelations,
    setNodeDashboardCollectionRelations,
    setHasError
  );
  const [isLoadingEquipmentTypes, loadEquipmentTypes] =
    useDispatchResultRequest(
      API.Equipments.getEquipmentTypes,
      setEquipmentTypes,
      setHasError
    );
  const [isLoadingSignalTypes, loadSignalTypes] = useDispatchResultRequest(
    API.SignalTypes.getAllSignalTypes,
    setSignalTypes,
    setHasError
  );
  const [isLoadingEnums, loadEnums] = useDispatchResultRequest(
    API.Enums.getEnums,
    setEnums,
    setHasError
  );
  const [isLoadingSignalTypeFolders, loadSignalTypeFolders] =
    useDispatchResultRequest(
      API.SignalTypeFolders.getAllSignalTypeFolders,
      setSignalTypeFolders,
      setHasError
    );

  useEffect(() => {
    /**
     * Make sure that account is logged in
     */
    if (userId && tenantId) {
      loadSettings();
      loadNodes(tenantId); // TODO: Replace caching mechanism with react-query
      loadNodeTags();
      loadEquipmentTypes();
      loadEnums();
      loadSignalTypes();
      loadSignalTypeFolders();
      loadNodeDashboardCollectionRelations();
      loadDefaultDashboardCollection();
    }
  }, [
    loadEquipmentTypes,
    loadNodeTags,
    loadNodes,
    loadSettings,
    loadEnums,
    loadSignalTypes,
    userId,
    loadSignalTypeFolders,
    loadNodeDashboardCollectionRelations,
    loadDefaultDashboardCollection,
    tenantId
  ]);

  useEffect(() => {
    if (hasError && isAuthenticated) {
      toastStore.addErrorToast(T.common.baserequesterror);
    } else if (!isAuthenticated) {
      setHasError(false);
    }
  }, [hasError, isAuthenticated, setHasError]);

  const isLoading =
    isLoadingNodeTags ||
    isLoadingEquipmentTypes ||
    isLoadingSignalTypes ||
    isLoadingSignalTypeFolders ||
    isLoadingNodeDashboardCollectionRelations ||
    isLoadingDefaultDashboardCollection ||
    isLoadingEnums ||
    (isLoadingNode && nodeTree.length === 0);

  if (isLoading) {
    return <LoadingScreenWithMenu isLoading />;
  }

  return (
    <BaseContainer msalConfiguration={instance} currentAccount={currentAccount}>
      <AlarmUpdateProvider>
        <AlarmToastEmitter>
          <Outlet />
        </AlarmToastEmitter>
      </AlarmUpdateProvider>
    </BaseContainer>
  );
});

const OperatorContainerContent = React.memo(() => {
  const apiEnvironment = getApiEnvironment();

  const { isLoadingTenants, tenantsFailedToLoad, tenantResources } =
    useContext(TenantContext);
  const {
    isLoading: authenticationIsLoading,
    errorMessage,
    instance,
    currentAccount,
    isLoggingOut
  } = useAuthentication(apiEnvironment.scopes.gateway);
  let _errorMessage = errorMessage;

  const userSettingsQuery = useInitialUserSettings(
    !!currentAccount && !_errorMessage
  );

  const isLoading =
    isLoadingTenants ||
    authenticationIsLoading ||
    isLoggingOut ||
    userSettingsQuery.isLoading;

  if (tenantsFailedToLoad) {
    _errorMessage = AuthError.createUnexpectedError(
      T.tenants.error.failedtoload
    );
  }

  let content: React.ReactNode = null;

  if (isLoading) {
    content = <LoadingScreenWithMenu isLoading />;
  } else if (hasAccessToResource(ResourceType.CORE, tenantResources)) {
    content = <CoreWrapper />;
  } else if (currentAccount && !_errorMessage) {
    content = (
      <BaseContainer
        msalConfiguration={instance}
        currentAccount={currentAccount}
      >
        <Outlet />
      </BaseContainer>
    );
  }

  return (
    <>
      {!_errorMessage ? (
        <div className={styles.baseContainer}>{content}</div>
      ) : (
        <AuthenticationErrorComponent error={_errorMessage} />
      )}
    </>
  );
});

const OperatorContainer = () => {
  const { tenantId } = useContext(TenantContext);

  return (
    <Layout
      contentArea={
        <ContentArea>
          <OperatorContainerContent key={tenantId} />
          <ToastContainer />
        </ContentArea>
      }
    />
  );
};

export default OperatorContainer;
