import React, {
  MouseEvent,
  MouseEventHandler,
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import dropdownStyles from './DropdownButton.module.css';
import classNames from 'classnames';
import Button from 'ecto-common/lib/Button/Button';
import { DropdownButtonOptionType } from 'ecto-common/lib/DropdownButton/DropdownButton';
import { useMenuState } from 'ecto-common/lib/hooks/useDialogState';
import UUID from 'uuidjs';
import ReactDOM from 'react-dom';
import dimensions from 'ecto-common/lib/styles/dimensions';

type DropdownMenuProps = {
  options?: DropdownButtonOptionType[];
  header?: React.ReactNode;
  footer?: React.ReactNode;
  isShowing: boolean;
  outerContainerClassName?: string;
  closeButtonText?: string;
  outerStyle?: React.CSSProperties;
  outerAttributes?: React.HTMLAttributes<HTMLDivElement>;
  onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOut?: React.MouseEventHandler<HTMLDivElement>;
  disableWrap?: boolean;
};

export type DropdownMenuRef = {
  outerContainer: HTMLDivElement;
  innerContainer: HTMLDivElement;
};

const emptyOptions: DropdownButtonOptionType[] = [];

type DropdownMenuItemProps = {
  menuOption: DropdownButtonOptionType;
  idx: number;
  onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOut?: React.MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
  disableWrap: boolean;
};

export const DropdownOpenFileFooter = React.memo(
  ({
    onChange,
    label,
    icon
  }: {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    label: React.ReactNode;
    icon?: React.ReactNode;
  }) => {
    const id = useMemo(() => {
      return 'dropdown-file' + UUID.generate();
    }, []);

    return (
      <>
        <label
          htmlFor={id}
          className={classNames(
            dropdownStyles.dropdownItem,
            dropdownStyles.hasAction
          )}
        >
          {icon && <div className={dropdownStyles.icon}>{icon}</div>}
          <div className={dropdownStyles.label}>{label}</div>
        </label>
        <input
          className={dropdownStyles.fileInput}
          id={id}
          onChange={onChange}
          type="file"
        />
      </>
    );
  }
);
const stopPropagation =
  (callback?: MouseEventHandler<HTMLElement>) =>
  (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    return callback?.(event);
  };

const DropdownMenuItem = React.forwardRef<
  HTMLDivElement,
  DropdownMenuItemProps
>(
  (
    {
      menuOption,
      idx,
      onMouseOver,
      onMouseOut,
      disableWrap,
      children
    }: DropdownMenuItemProps,
    ref
  ) => {
    const isEnabled =
      menuOption.isEnabled != null ? menuOption.isEnabled : true;
    const hasAction =
      menuOption.action != null ||
      menuOption.nestedFooter != null ||
      menuOption.nestedHeader != null ||
      menuOption.nestedOptions != null;

    return (
      <div
        className={classNames(
          dropdownStyles.dropdownItem,
          hasAction && dropdownStyles.hasAction,
          !isEnabled && dropdownStyles.disabledItem,
          disableWrap && dropdownStyles.disableWrap
        )}
        key={idx}
        ref={ref}
        data-disableclosedropdownmenuonclick={
          menuOption.disableCloseOnClick === true ? true : undefined
        }
        onMouseDown={stopPropagation(null)}
        onMouseUp={stopPropagation(null)}
        onMouseOver={stopPropagation(onMouseOver)}
        onMouseOut={stopPropagation(onMouseOut)}
        onClick={stopPropagation(isEnabled ? menuOption.action : undefined)}
      >
        {menuOption.icon && (
          <div className={dropdownStyles.icon}>{menuOption.icon}</div>
        )}
        <div
          className={dropdownStyles.label}
          onMouseDown={stopPropagation(null)}
          onMouseUp={stopPropagation(null)}
          onClick={stopPropagation(isEnabled ? menuOption.action : undefined)}
        >
          {menuOption.label}
          {menuOption.subtitle && (
            <div className={dropdownStyles.subtitle}>{menuOption.subtitle}</div>
          )}
        </div>
        <div className={dropdownStyles.space} />
        {menuOption.rightSideIcon && (
          <span className={dropdownStyles.rightsideOptionIcon}>
            {menuOption.rightSideIcon}
          </span>
        )}
        {children}
      </div>
    );
  }
);

const DropdownMenuItemWithNestedMenu = React.memo(
  ({
    menuOption,
    idx,
    isShowingParent,
    disableWrap
  }: {
    menuOption: DropdownButtonOptionType;
    idx: number;
    isShowingParent: boolean;
    disableWrap: boolean;
  }) => {
    const [showingSubmenu, showSubmenu, hideSubmenu] = useMenuState();
    const [childRef, setChildRef] = useState<DropdownMenuRef>(null);
    const [referenceElement, setReferenceElement] =
      React.useState<HTMLElement>(null);

    if (!isShowingParent && showingSubmenu) {
      hideSubmenu();
    }

    const [outerStyle, setOuterStyle] = useState<React.CSSProperties>({
      position: 'absolute',
      top: '100px',
      left: '100px'
    });

    useLayoutEffect(() => {
      if (
        childRef?.innerContainer &&
        childRef?.outerContainer &&
        referenceElement
      ) {
        const outerRect = childRef.outerContainer.getBoundingClientRect();
        const buttonRect = referenceElement.getBoundingClientRect();
        let top =
          buttonRect.top + buttonRect.height * 0.5 - outerRect.height * 0.5;

        top = Math.max(dimensions.plainBoxPadding, top);
        top = Math.min(
          top,
          window.innerHeight - outerRect.height - dimensions.plainBoxPadding
        );

        setOuterStyle((oldOuterStyle) => ({
          ...oldOuterStyle,
          top,
          left: buttonRect.left + buttonRect.width
        }));
      }
    }, [showingSubmenu, childRef, referenceElement]);

    const onMouseOut: React.MouseEventHandler<HTMLDivElement> = (e) => {
      if (!childRef?.outerContainer.contains(e.relatedTarget as HTMLElement)) {
        hideSubmenu();
      }
    };

    const onMouseOver: React.MouseEventHandler<HTMLDivElement> = () => {
      showSubmenu();
    };

    const onMouseOutMenu: React.MouseEventHandler<HTMLDivElement> = (e) => {
      const newElement = e.relatedTarget as HTMLElement;

      if (
        newElement !== referenceElement &&
        !referenceElement.contains(newElement) &&
        !childRef?.outerContainer.contains(newElement)
      ) {
        hideSubmenu();
      }
    };

    return (
      <>
        <DropdownMenuItem
          ref={setReferenceElement}
          idx={idx}
          menuOption={menuOption}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          disableWrap={disableWrap}
        />
        {ReactDOM.createPortal(
          <DropdownMenu
            disableWrap={disableWrap}
            isShowing={showingSubmenu}
            options={menuOption.nestedOptions ?? emptyOptions}
            header={menuOption.nestedHeader}
            footer={menuOption.nestedFooter}
            ref={setChildRef}
            outerStyle={outerStyle}
            onMouseOut={onMouseOutMenu}
          />,
          document.body
        )}
      </>
    );
  }
);

const DropdownMenu = forwardRef<DropdownMenuRef, DropdownMenuProps>(
  function DropDownMenu(
    {
      header,
      footer,
      isShowing,
      outerContainerClassName,
      closeButtonText,
      options,
      outerStyle,
      outerAttributes,
      onMouseOut,
      onMouseOver,
      disableWrap
    }: DropdownMenuProps,
    ref
  ) {
    const [innerContainerRef, setInnerContainerRef] =
      React.useState<HTMLDivElement>(null);
    const [outerContainerRef, setOuterContainerRef] =
      React.useState<HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        get outerContainer() {
          return outerContainerRef;
        },
        get innerContainer() {
          return innerContainerRef;
        }
      }),
      [innerContainerRef, outerContainerRef]
    );

    return (
      <div
        ref={setOuterContainerRef}
        className={classNames(
          dropdownStyles.dropdown,
          isShowing && dropdownStyles.dropdownVisible,
          outerContainerClassName
        )}
        style={outerStyle}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        {...outerAttributes}
      >
        <div
          className={dropdownStyles.innerDropdown}
          ref={setInnerContainerRef}
        >
          {header && <div data-disableclosedropdownmenuonclick>{header}</div>}
          {options?.map((menuOption, idx) => {
            const showNested =
              menuOption.nestedFooter != null ||
              menuOption.nestedOptions != null ||
              menuOption.nestedHeader;

            if (showNested) {
              return (
                <DropdownMenuItemWithNestedMenu
                  isShowingParent={isShowing}
                  menuOption={menuOption}
                  idx={idx}
                  key={idx}
                  disableWrap={disableWrap}
                />
              );
            }

            return (
              <DropdownMenuItem
                menuOption={menuOption}
                idx={idx}
                key={idx}
                disableWrap={disableWrap}
              />
            );
          })}
          {footer && <div data-disableclosedropdownmenuonclick>{footer}</div>}
          {closeButtonText && (
            <div className={dropdownStyles.closeFooter}>
              <Button>{closeButtonText}</Button>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default React.memo(DropdownMenu);
