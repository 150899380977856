import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import Toolbar from 'ecto-common/lib/Toolbar/Toolbar';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarLocationPicker from 'ecto-common/lib/ToolbarContentPage/ToolbarLocationPicker';
import LocationPageHeader from 'ecto-common/lib/LocationPageHeader/LocationPageHeader';
import styles from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage.module.css';
import PageTreeView from 'ecto-common/lib/Page/PageTreeView';
import classNames from 'classnames';
import AddButton from 'ecto-common/lib/Button/AddButton';
import ToolbarSearch from '../Toolbar/ToolbarSearch';
import { SET_LOCATION_PICKER_OPEN } from 'ecto-common/lib/actions/actionTypes';
import ErrorBoundary from 'ecto-common/lib/utils/ErrorBoundary';
import { useLocation } from 'react-router-dom';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import {
  useCommonSelector,
  useCommonDispatch
} from 'ecto-common/lib/reducers/storeCommon';
import _ from 'lodash';

interface ToolbarContentPageProps {
  toolbarItems?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  title?: React.ReactNode;
  showLocationPicker?: boolean;
  wrapContent?: boolean;
  padContent?: boolean;
  selectEquipment?: boolean;
  urlBuilder?(
    tenantId: string,
    nodeId: string,
    equipmentId?: string,
    searchParams?: URLSearchParams
  ): string;
  onSearchInput?(searchPhrase: string): void;
  searchString?: string;
  addActionButton?: React.ReactNode;
  addAction?(): void;
  addActionTitle?: string;
  helpPath?: string;
  disablePageChange?: boolean;
  locationPickerOpen?: boolean;
  setLocationPickerOpen?(pickerIsOpen: boolean): void;
  removeTopMargin?: boolean;
  removeBottomMargin?: boolean;
  removeSideMargin?: boolean;
  dockToolbar?: boolean;
  resetScroll?: number;
  /**
   * @param Set this if you want to display a different menu where the page tree view is usually placed.
   */
  sideMenu?: React.ReactNode;
  navigationControl?: React.ReactNode;
  navigationTree?: React.ReactNode;
}

const ToolbarContentPage = ({
  toolbarItems,
  children,
  className,
  showLocationPicker,
  title,
  navigationControl,
  wrapContent,
  padContent,
  selectEquipment,
  urlBuilder,
  onSearchInput,
  searchString,
  addActionButton,
  addAction,
  addActionTitle,
  helpPath,
  disablePageChange,
  locationPickerOpen = null,
  setLocationPickerOpen = null,
  removeSideMargin = false,
  removeTopMargin = false,
  removeBottomMargin = false,
  dockToolbar = false,
  sideMenu = null,
  resetScroll = null,
  navigationTree = null
}: ToolbarContentPageProps) => {
  const globalLocationPickerOpen = useCommonSelector(
    (state) => state.general.locationPickerOpen
  );

  if (locationPickerOpen == null) {
    locationPickerOpen = globalLocationPickerOpen;
  }

  const dispatch = useCommonDispatch();

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      if (setLocationPickerOpen) {
        setLocationPickerOpen(isOpen);
      } else {
        dispatch({
          type: SET_LOCATION_PICKER_OPEN,
          payload: { locationPickerOpen: isOpen }
        });
      }
    },
    [setLocationPickerOpen, dispatch]
  );

  const renderToolbar =
    toolbarItems != null || addAction != null || onSearchInput != null;
  const toolbarInnerContent = renderToolbar && (
    <Toolbar isPageHeadingToolbar className={styles.pageToolbar}>
      {(addAction || onSearchInput) && <ToolbarFlexibleSpace />}
      {toolbarItems}
      {onSearchInput && (
        <ToolbarItem>
          <ToolbarSearch
            onChange={onSearchInput}
            value={searchString}
            autoFocus
            showFocusHighlight
          />
        </ToolbarItem>
      )}
      {addActionButton && !addAction && (
        <ToolbarItem key="add">{addActionButton}</ToolbarItem>
      )}
      {!addActionButton && addAction && (
        <ToolbarItem>
          <AddButton onClick={addAction}>{addActionTitle}</AddButton>
        </ToolbarItem>
      )}
    </Toolbar>
  );

  const toolbarDockRef = useRef<HTMLDivElement>();
  const pageContentRef = useRef<HTMLDivElement>();

  let toolbarContent = toolbarInnerContent;

  if (renderToolbar && dockToolbar) {
    toolbarContent = (
      <>
        <div className={styles.toolbarDockParent} ref={toolbarDockRef}>
          {toolbarInnerContent}
        </div>
      </>
    );
  }

  const scrollHandler: React.UIEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (toolbarDockRef.current && e.target === pageContentRef.current) {
        if ((e.target as HTMLElement).scrollTop > 0) {
          toolbarDockRef.current.className = classNames(
            styles.toolbarDockParent,
            styles.toolbarShadow
          );
        } else {
          toolbarDockRef.current.className = styles.toolbarDockParent;
        }
      }
    },
    []
  );

  const location = useLocation();

  useEffect(() => {
    if (pageContentRef.current) {
      if (!isNullOrWhitespace(location.hash)) {
        const elem = document.getElementById(location.hash.replace('#', ''));
        if (elem != null) {
          elem.scrollIntoView();
        }
      }

      if (toolbarDockRef.current) {
        toolbarDockRef.current.className = styles.toolbarDockParent;
      }
    }
  }, [location.pathname, location.hash, location.search, resetScroll]);

  useEffect(() => {
    if (pageContentRef.current && dockToolbar) {
      pageContentRef.current.scrollTop = 0;
    }
  }, [dockToolbar, searchString]);

  const headerChildren =
    showLocationPicker || navigationControl != null ? (
      <>
        {showLocationPicker && (
          <ToolbarLocationPicker
            isOpen={locationPickerOpen}
            setIsOpen={setIsOpen}
          />
        )}
        {navigationControl}
      </>
    ) : undefined;

  return (
    <Fragment>
      <ErrorBoundary showFullscreenError>
        {title && (
          <LocationPageHeader title={title} helpPath={helpPath}>
            {headerChildren}
          </LocationPageHeader>
        )}
        <div className={styles.outerContainer}>
          {sideMenu}
          {navigationTree}
          {showLocationPicker && (
            <PageTreeView
              isOpen={locationPickerOpen}
              selectEquipment={selectEquipment}
              urlBuilder={urlBuilder}
              disablePageChange={disablePageChange}
            />
          )}
          <div
            className={styles.outerPage}
            onScroll={scrollHandler}
            ref={pageContentRef}
            data-parent-scroller="true"
          >
            {toolbarContent}

            <div
              className={classNames(
                styles.pageContainer,
                removeSideMargin && styles.noSidePadding
              )}
            >
              <div
                className={classNames(
                  styles.innerContent,
                  (renderToolbar || removeTopMargin) && styles.noTopPadding,
                  !removeBottomMargin && styles.bottomMargin
                )}
              >
                {wrapContent && (
                  <PlainBox
                    isPageBox
                    className={classNames(
                      className,
                      !padContent && styles.noPadding
                    )}
                  >
                    {children}
                  </PlainBox>
                )}
                {!wrapContent && children}
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </Fragment>
  );
};

ToolbarContentPage.defaultProps = {
  showLocationPicker: true,
  wrapContent: true,
  padContent: true,
  selectEquipment: false,
  disablePageChange: false,
  removeTopMargin: false,
  dockToolbar: false
};

export default ToolbarContentPage;
