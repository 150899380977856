import { useMutation } from '@tanstack/react-query';
import EctoplannerAPIGen, {
  BuildResponse,
  WeatherCountryResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import Icons from 'ecto-common/lib/Icons/Icons';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import T from 'ecto-common/lib/lang/Language';
import {
  EctoplannerConfirmSaveBeforeNewDialog,
  useEctoplannerToolbarMenuOptions
} from 'js/components/Ectoplanner/EctoplannerNavigationControls';
import _ from 'lodash';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router';
import {
  EctoplannerParams,
  getEctoplannerUrl
} from '../../utils/routeConstants';
import Button from 'ecto-common/lib/Button/Button';
import {
  ectoplannerCalculationIsLoading,
  saveAndBuildPromise,
  saveEctoplannerFormPromise
} from 'js/components/Ectoplanner/EctoplannerUtils';
import {
  EctoplannerBuildStatus,
  EctoplannerProjectTypes,
  calculateSecosimChecksum
} from 'js/components/Ectoplanner/EctoplannerTypes';
import CollapsingSegmentControlPicker, {
  OptionWithIcon
} from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import {
  formatNumberUnit,
  isNullOrWhitespace
} from 'ecto-common/lib/utils/stringUtils';
import {
  OptionWithIconAndView,
  getEctoplannerInfoView
} from 'js/components/Ectoplanner/useEctoplannerFormOptions';
import DataTable, {
  DataTableColumnProps,
  DataTableSectionHeader
} from 'ecto-common/lib/DataTable/DataTable';
import {
  BatterySections,
  BoilersSections,
  BufferTankSections,
  BuildingTimeSeriesSections,
  CoolingSections,
  GridConnectionSections,
  HeatPumpSections,
  PVSections,
  ScenarioDefinitionModelSections,
  TimeSeriesSections
} from 'js/components/Ectoplanner/SecosimModels';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import ectoplannerStyles from 'js/components/Ectoplanner/EditEctoplannerProject.module.css';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import EctoplannerGraphBrowser from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerGraphBrowser';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import SecosimKPIGraphs from './Secosim/SecosimKPIGraphs';
import {
  SecosimBuilding,
  SecosimForm,
  SecosimResult,
  SecosimResultAndFlagsType
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import EditEctoplannerLocation from 'js/components/Ectoplanner/EditEctoplannerLocation';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Notice from 'ecto-common/lib/Notice/Notice';
import dimensions from 'ecto-common/lib/styles/dimensions';
import { useNavigate } from 'react-router-dom';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';

const SectionParameters = 'parameters';

const SectionTable = 'table';
const SectionKPIGraphs = 'kpigraphs';
const SectionGraphs = 'graphs';

const SecosimFormEditor = ({
  form,
  weatherCountries,
  setForm
}: {
  form: SecosimForm;
  setForm: Dispatch<SetStateAction<SecosimForm>>;
  weatherCountries: WeatherCountryResponse[];
}) => {
  const params = useParams<EctoplannerParams>();
  const [buildingIndex, setBuildingIndex] = useState(-1);
  const sections = useMemo(() => {
    return [...ScenarioDefinitionModelSections, ...TimeSeriesSections];
  }, []);

  const buildingSections = useMemo(() => {
    if (buildingIndex === -1) {
      return [];
    }

    return [
      ...PVSections(buildingIndex),
      ...BatterySections(buildingIndex),
      ...GridConnectionSections(buildingIndex),
      ...HeatPumpSections(buildingIndex),
      ...CoolingSections(buildingIndex),
      ...BufferTankSections(buildingIndex),
      ...BoilersSections(buildingIndex),
      ...BuildingTimeSeriesSections(buildingIndex)
    ];
  }, [buildingIndex]);

  const buildingColumns: DataTableColumnProps<SecosimBuilding>[] =
    useMemo(() => {
      return [
        {
          dataKey: 'name',
          label: null,
          linkColumn: true
        }
      ];
    }, []);

  const onClickRow = useCallback((_unused: unknown, rowIndex: number) => {
    setBuildingIndex(rowIndex);
  }, []);

  return (
    <>
      {<Notice>{T.ectoplanner.secosim.releasenote}</Notice>}
      <div
        style={{
          width: '100%',
          display: 'flex',
          marginTop: dimensions.standardMargin
        }}
      >
        <EditEctoplannerLocation
          form={form}
          setFormFromUserInput={setForm}
          weatherCountries={weatherCountries}
          disableNetworkParams
        />
      </div>
      <div>
        <div className={ectoplannerStyles.flexWrapLayout}>
          <ModelForm<SecosimForm>
            input={form}
            key={params.projectId + '-' + params.buildId}
            sections={sections}
            setInput={setForm}
            sectionClassName={ectoplannerStyles.section}
            useTooltipHelpTexts
          />
        </div>
        <ActionModal
          isOpen={buildingIndex !== -1}
          onModalClose={() => setBuildingIndex(-1)}
          title={form?.buildings?.[buildingIndex]?.name}
          onConfirmClick={() => setBuildingIndex(-1)}
          headerIcon={Icons.Building}
          className={ectoplannerStyles.editBuildingModal}
          disableCancel
          actionText={T.common.done}
        >
          <ModelForm
            input={form}
            sections={buildingSections}
            setInput={setForm}
          />
        </ActionModal>
        <DataTable
          columns={buildingColumns}
          data={form.buildings}
          disableHeader
          onClickRow={onClickRow}
        />
      </div>
    </>
  );
};

type SecosimResultDefinition = {
  kpi: string;
  total: number;
  description: string;
  unit: string;
};

const secosimColumns: DataTableColumnProps<SecosimResultDefinition>[] = [
  {
    dataKey: 'kpi',
    label: T.ectoplanner.secosim.columns.kpi
  },
  {
    dataKey: 'total',
    label: T.ectoplanner.secosim.columns.total,
    dataFormatter: (total: number, def) => {
      return formatNumberUnit(total, def.unit);
    }
  },
  {
    dataKey: 'description',
    label: T.ectoplanner.secosim.columns.description
  }
];

const SecosimResultWrapper = ({
  children,
  formIsUpToDate,
  isRunningCalculation,
  resultAndFlags,
  buildStatus,
  isLoading
}: {
  formIsUpToDate: boolean;
  resultAndFlags: SecosimResultAndFlagsType;
  children: React.ReactNode;
  isRunningCalculation: boolean;
  buildStatus: EctoplannerBuildStatus;
  isLoading: boolean;
}) => {
  const infoView = getEctoplannerInfoView(
    isRunningCalculation,
    resultAndFlags != null && !resultAndFlags?.flags.optimal_solution_found,
    resultAndFlags == null && !isLoading,
    buildStatus,
    ectoplannerStyles.paddedArea
  );

  const showingInfoView = infoView != null;
  let content: React.ReactNode = null;

  if (infoView != null) {
    content = infoView;
  } else {
    content = children;
  }

  return (
    <>
      {!showingInfoView && !formIsUpToDate && !isRunningCalculation && (
        <div className={ectoplannerStyles.paddedArea}>
          <ErrorNotice>{T.ectoplanner.calculations.newchangesinfo}</ErrorNotice>
        </div>
      )}
      {content}
    </>
  );
};

const SecosimResultTable = ({ result }: { result: SecosimResult }) => {
  const data = useMemo(() => {
    if (result == null) {
      return [];
    }

    return [
      DataTableSectionHeader(T.ectoplanner.secosim.sections.opex),
      {
        kpi: T.ectoplanner.secosim.kpis.cumulative_opex,
        total: result.cumulative_opex,
        description: T.ectoplanner.secosim.kpis.descriptions.cumulative_opex,
        unit: T.ectoplanner.units.eur
      },
      {
        kpi: T.ectoplanner.secosim.kpis.savings_opex,
        total: result.savings_opex,
        description: T.ectoplanner.secosim.kpis.descriptions.savings_opex,
        unit: T.ectoplanner.units.percent
      },
      DataTableSectionHeader(T.ectoplanner.secosim.sections.savings),
      {
        kpi: T.ectoplanner.secosim.kpis.cumulative_energy_savings,
        total: result.cumulative_energy_savings,
        description:
          T.ectoplanner.secosim.kpis.descriptions.cumulative_energy_savings,
        unit: T.ectoplanner.units.kw
      },
      {
        kpi: T.ectoplanner.secosim.kpis.cumulative_eur_savings,
        total: result.cumulative_eur_savings,
        description:
          T.ectoplanner.secosim.kpis.descriptions.cumulative_eur_savings,
        unit: T.ectoplanner.units.eur
      },
      {
        kpi: T.ectoplanner.secosim.kpis.savings_building_demand,
        total: result.savings_building_demand,
        description:
          T.ectoplanner.secosim.kpis.descriptions.savings_building_demand,
        unit: T.ectoplanner.units.percent
      },
      {
        kpi: T.ectoplanner.secosim.kpis.savings_cooling_demand,
        total: result.savings_cooling_demand,
        description:
          T.ectoplanner.secosim.kpis.descriptions.savings_cooling_demand,
        unit: T.ectoplanner.units.percent
      },
      {
        kpi: T.ectoplanner.secosim.kpis.savings_heating_demand,
        total: result.savings_heating_demand,
        description:
          T.ectoplanner.secosim.kpis.descriptions.savings_heating_demand,
        unit: T.ectoplanner.units.percent
      },
      {
        kpi: T.ectoplanner.secosim.kpis.savings_dhw_demand,
        total: result.savings_dhw_demand,
        description: T.ectoplanner.secosim.kpis.descriptions.savings_dhw_demand,
        unit: T.ectoplanner.units.percent
      },
      DataTableSectionHeader(T.ectoplanner.secosim.sections.co2),
      {
        kpi: T.ectoplanner.secosim.kpis.cumulative_co2_emission,
        total: result.cumulative_co2_emission,
        description:
          T.ectoplanner.secosim.kpis.descriptions.cumulative_co2_emission,
        unit: T.ectoplanner.units.tonnco2
      },
      {
        kpi: T.ectoplanner.secosim.kpis.savings_co2_vs_historical,
        total: result.savings_co2_vs_historical,
        description:
          T.ectoplanner.secosim.kpis.descriptions.savings_co2_vs_historical,
        unit: T.ectoplanner.units.percent
      },
      DataTableSectionHeader(T.ectoplanner.secosim.sections.peakpower),
      {
        kpi: T.ectoplanner.secosim.kpis.peak_power_absorption_grid,
        total: result.peak_power_absorption_grid,
        description:
          T.ectoplanner.secosim.kpis.descriptions.peak_power_absorption_grid,
        unit: T.ectoplanner.units.percent
      },
      DataTableSectionHeader(T.ectoplanner.secosim.sections.feedin),
      {
        kpi: T.ectoplanner.secosim.kpis.feed_in_energy,
        total: result.feed_in_energy,
        description: T.ectoplanner.secosim.kpis.descriptions.feed_in_energy,
        unit: T.ectoplanner.units.kwh
      },
      {
        kpi: T.ectoplanner.secosim.kpis.feed_in_revenues,
        total: result.feed_in_revenues,
        description: T.ectoplanner.secosim.kpis.descriptions.feed_in_revenues,
        unit: T.ectoplanner.units.eur
      },
      DataTableSectionHeader(T.ectoplanner.secosim.sections.pv),
      {
        kpi: T.ectoplanner.secosim.kpis.pv_self_consumption,
        total: result.pv_self_consumption,
        description:
          T.ectoplanner.secosim.kpis.descriptions.pv_self_consumption,
        unit: T.ectoplanner.units.percent
      },
      {
        kpi: T.ectoplanner.secosim.kpis.pv_to_heating_and_cooling_demand,
        total: result.pv_to_heating_and_cooling_demand,
        description:
          T.ectoplanner.secosim.kpis.descriptions
            .pv_to_heating_and_cooling_demand,
        unit: T.ectoplanner.units.percent
      },
      {
        kpi: T.ectoplanner.secosim.kpis.pv_generated_going_to_grid_export,
        total: result.pv_generated_going_to_grid_export,
        description:
          T.ectoplanner.secosim.kpis.descriptions
            .pv_generated_going_to_grid_export,
        unit: T.ectoplanner.units.kwh
      }
    ];
  }, [result]);

  return (
    <DataTable<SecosimResultDefinition> columns={secosimColumns} data={data} />
  );
};

const EditSecosimProject = ({
  navigationControl,
  navigationTree,
  onClickAddNew,
  onClickShare,
  onClickDelete,
  onClickEditName,
  onClickExport,
  selectedBuild,
  form,
  setForm,
  clearHasChanges,
  hasChanges,
  onRecalculate,
  isLoadingForm,
  isExporting,
  weatherCountries
}: {
  navigationControl: React.ReactNode;
  navigationTree: React.ReactNode;
  onClickAddNew: () => void;
  onClickShare: () => void;
  onClickDelete: () => void;
  onClickEditName: () => void;
  onClickExport: () => void;
  selectedBuild: BuildResponse;
  form: SecosimForm;
  setForm: React.Dispatch<SetStateAction<SecosimForm>>;
  clearHasChanges: () => void;
  hasChanges: boolean;
  onRecalculate: (buildResponse: BuildResponse) => void;
  isLoadingForm: boolean;
  isExporting: boolean;
  weatherCountries: WeatherCountryResponse[];
}) => {
  const [confirmingSave, showConfirmSave, hideConfirmSave] =
    useSimpleDialogState();

  const params = useParams<EctoplannerParams>();
  const { tenantId, contextSettings } = useContext(TenantContext);
  const buildResultsQuery =
    EctoplannerAPIGen.EctoGridBuilds.resultsDetail.useQuery(
      {
        buildId: selectedBuild?.id
      },
      {
        enabled:
          !!selectedBuild &&
          selectedBuild?.status === EctoplannerBuildStatus.ResultsDone
      }
    );

  const excelQuery =
    EctoplannerAPIGen.EctoGridBuilds.excelfilelinkDetail.useQuery(
      {
        buildId: selectedBuild?.id
      },
      {
        enabled: selectedBuild?.status === EctoplannerBuildStatus.ResultsDone
      }
    );

  const lastWeatherStationId = useRef<string>(null);
  const saveMutation = useMutation(saveEctoplannerFormPromise, {
    onSuccess: () => {
      lastWeatherStationId.current = form.location.city.id;
      clearHasChanges();

      if (confirmingSave) {
        hideConfirmSave();
        onClickAddNew();
      }
    },
    onError: (e) => {
      toastStore.addErrorToast(T.ectoplanner.saveerror);
      console.error(e);
    }
  });

  const isLoading = isExporting || isLoadingForm || saveMutation.isLoading;

  const verifyOnClickAddNew = useCallback(() => {
    if (hasChanges) {
      showConfirmSave();
    } else {
      onClickAddNew();
    }
  }, [hasChanges, onClickAddNew, showConfirmSave]);

  const save = useCallback(() => {
    saveMutation.mutate({
      buildId: params.buildId,
      projectId: params.projectId,
      formData: {
        projectType: EctoplannerProjectTypes.Secosim,
        data: form
      },
      contextSettings,
      lastWeatherStationId: lastWeatherStationId.current
    });
  }, [contextSettings, form, params.buildId, params.projectId, saveMutation]);

  const toolbarMenuOptions = useEctoplannerToolbarMenuOptions({
    saveButton: (
      <ToolbarMenuButton
        icon={<Icons.Save />}
        disabled={!hasChanges || saveMutation.isLoading}
        onClick={save}
        tooltipText={T.common.save}
      />
    ),
    onClickAddNew: verifyOnClickAddNew,
    onClickShare,
    onClickDelete,
    onClickEditName,
    selectedBuild,
    onClickExport,
    airTemp: null,
    cityData: null,
    currentWeatherStationId: null,
    form,
    isLoading
  });

  const buildMutation = useMutation(saveAndBuildPromise, {
    onSuccess: (buildResponse) => {
      clearHasChanges();
      onRecalculate(buildResponse);
    },
    onError: () => {
      toastStore.addErrorToast(T.ectoplanner.calculateerror);
    }
  });
  const calculationIsLoading = ectoplannerCalculationIsLoading(selectedBuild);
  const isRunningCalculation = calculationIsLoading || buildMutation.isLoading;

  const checksum = useMemo(() => {
    return calculateSecosimChecksum(form);
  }, [form]);

  const formIsUpToDate =
    selectedBuild == null ||
    isNullOrWhitespace(checksum) ||
    checksum === selectedBuild?.checksum;

  const resultAndFlags = buildResultsQuery.data as SecosimResultAndFlagsType;
  const result = resultAndFlags?.results;

  const sections: OptionWithIconAndView[] = useMemo(() => {
    return _.compact([
      {
        icon: <Icons.File />,
        value: SectionParameters,
        label: <>{T.ectoplanner.secosim.tabs.input}</>,
        view: (
          <SecosimFormEditor
            form={form}
            setForm={setForm}
            weatherCountries={weatherCountries}
          />
        )
      },
      {
        icon: <Icons.Table />,
        value: SectionTable,
        label: <>{T.ectoplanner.secosim.tabs.result}</>,
        view: (
          <SecosimResultWrapper
            resultAndFlags={resultAndFlags}
            formIsUpToDate={formIsUpToDate}
            isRunningCalculation={isRunningCalculation}
            buildStatus={selectedBuild?.status as EctoplannerBuildStatus}
            isLoading={buildResultsQuery.isLoading}
          >
            {result && <SecosimResultTable result={result} />}
          </SecosimResultWrapper>
        )
      },
      {
        icon: <Icons.BarGraph />,
        value: SectionKPIGraphs,
        label: <>{T.ectoplanner.secosim.tabs.kpigraphs}</>,
        view: (
          <SecosimResultWrapper
            resultAndFlags={resultAndFlags}
            formIsUpToDate={formIsUpToDate}
            isRunningCalculation={isRunningCalculation}
            buildStatus={selectedBuild?.status as EctoplannerBuildStatus}
            isLoading={buildResultsQuery.isLoading}
          >
            {result?.timeseries && (
              <SecosimKPIGraphs
                result={result}
                buildId={selectedBuild?.id}
                form={form}
              />
            )}
          </SecosimResultWrapper>
        )
      },
      {
        icon: <Icons.Signal />,
        value: SectionGraphs,
        label: <>{T.ectoplanner.secosim.tabs.graphs}</>,
        view: (
          <SecosimResultWrapper
            resultAndFlags={resultAndFlags}
            formIsUpToDate={formIsUpToDate}
            isRunningCalculation={isRunningCalculation}
            buildStatus={selectedBuild?.status as EctoplannerBuildStatus}
            isLoading={buildResultsQuery.isLoading}
          >
            {result?.timeseries && (
              <EctoplannerGraphBrowser
                withPadding={false}
                build={selectedBuild}
                timeseries={result?.timeseries}
                isRunningCalculation={isRunningCalculation}
                formIsUpToDate={formIsUpToDate}
              />
            )}
          </SecosimResultWrapper>
        )
      }
    ]);
  }, [
    buildResultsQuery.isLoading,
    form,
    formIsUpToDate,
    isRunningCalculation,
    result,
    resultAndFlags,
    selectedBuild,
    setForm,
    weatherCountries
  ]);

  const navigate = useNavigate();

  const calculate = useCallback(() => {
    const newChecksum = calculateSecosimChecksum(form);

    buildMutation.mutate({
      contextSettings,
      projectId: params.projectId,
      buildId: params.buildId,
      formData: {
        projectType: EctoplannerProjectTypes.Secosim,
        data: form
      },
      hasChanges,
      lastWeatherStationId: null,
      checksum: newChecksum
    });

    navigate(
      getEctoplannerUrl(
        tenantId,
        params.projectType,
        params.projectId,
        params.buildId,
        SectionTable
      )
    );
  }, [
    buildMutation,
    contextSettings,
    form,
    hasChanges,
    navigate,
    params.buildId,
    params.projectId,
    params.projectType,
    tenantId
  ]);

  let curSection = _.findIndex(sections, ['value', params.section]);
  curSection = curSection === -1 ? 0 : curSection;
  const onChangeValue = useCallback(
    (newValue: OptionWithIcon) => {
      navigate(
        getEctoplannerUrl(
          tenantId,
          params.projectType,
          params.projectId,
          params.buildId,
          newValue.value
        )
      );
    },
    [navigate, tenantId, params.projectType, params.projectId, params.buildId]
  );

  const allToolbarItems = useMemo(() => {
    return (
      <>
        <ToolbarItem>
          <ToolbarMenu>{toolbarMenuOptions}</ToolbarMenu>
        </ToolbarItem>
        <ToolbarItem expanding>
          <CollapsingSegmentControlPicker
            options={sections}
            value={sections[curSection]}
            onChangeValue={onChangeValue}
          />
        </ToolbarItem>
        <ToolbarItem>
          <GreyButton
            disabled={excelQuery.data?.link == null}
            onClick={() => {
              const url = excelQuery.data?.link;
              window.open(url);
            }}
          >
            <Icons.Download />
            Excel file
          </GreyButton>
        </ToolbarItem>
        <ToolbarItem>
          <Button onClick={calculate}>
            <Icons.Calculator />
            {T.ectoplanner.calculate}
          </Button>
        </ToolbarItem>
      </>
    );
  }, [
    calculate,
    curSection,
    excelQuery.data?.link,
    onChangeValue,
    sections,
    toolbarMenuOptions
  ]);

  return (
    <ToolbarContentPage
      navigationControl={navigationControl}
      navigationTree={navigationTree}
      showLocationPicker={false}
      title={T.ectoplanner.secosim.title}
      toolbarItems={allToolbarItems}
      wrapContent={params.section !== SectionKPIGraphs || result == null}
    >
      {sections[curSection].view}

      <EctoplannerConfirmSaveBeforeNewDialog
        isOpen={confirmingSave}
        onSave={save}
        onHideConfirmSave={hideConfirmSave}
        onClickAddNew={onClickAddNew}
        isLoading={saveMutation.isLoading}
      />
    </ToolbarContentPage>
  );
};

export default React.memo(EditSecosimProject);
