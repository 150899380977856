import React, { Component, CSSProperties } from 'react';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import cssDurationToMilliseconds from '../utils/cssDurationToMilliseconds';
import animations from '../styles/variables/animations';
import zIndex from '../styles/variables/zindex';
import * as directions from './directions';
import Portal from '../Portal/Portal';
import Transition from '../Transition/Transition';
import TooltipContent from './TooltipContent';
import styles from './Tooltip.module.css';
import { DirectionType } from './directions';
import TooltipQuestionMark from 'ecto-common/lib/Tooltip/TooltipQuestionMark';

const tooltipSpeed = cssDurationToMilliseconds(animations.tooltipSpeed);

const transitionStyles = {
  appear: styles.enter,
  appearActive: styles.enterActive,
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive
};

interface TooltipProps {
  /**
   * The direction that the tooltip will appear in.
   */
  direction: DirectionType;

  /**
   * The text that will be shown in the tooltip.
   */
  text: React.ReactNode;
  /**
   * The content that the tooltip will appear over.
   */
  children: React.ReactNode;
  /**
   * Used to override the appearance of the tooltip. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * If set to true the tooltip will not appear.
   */
  disableTooltip?: boolean;
  /**
   * If set to true then the text will appear over multiple lines.
   */
  multiline?: boolean;
  /**
   * This function will be called in order to determine if the tooltip should be shown. The function should return a bool.
   */
  visibilityFn: () => boolean;

  /**
   * TODO: Should not be used directly, since the tooltip creates a parent div and passes on its props to it we can in theory
   * attach event handlers etc to it, which a few places do. Should be refactored away.
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;

  /**
   * This can be used to add a small question mark to the right of the content
   */
  withIcon?: boolean;
}

/**
 * A tooltip is a small informative dialog that offers more detailed information about another component. It is shown when the user hovers over the associated component.
 */
class Tooltip extends Component<TooltipProps> {
  static defaultProps = {
    direction: directions.N,
    visibilityFn: () => true
  };

  //
  // Local variables

  state = {
    isVisible: false,
    calculatedDirection: null as string
  };

  _parentRef: HTMLDivElement = null;

  //
  // Lifecycle

  onMouseOver: _.DebouncedFunc<never> = null;
  onMouseOut: () => void = null;

  constructor(props: TooltipProps) {
    super(props);
    this.onMouseOver = _.debounce(this._onMouseOver.bind(this), 250);
    this.onMouseOut = this._onMouseOut.bind(this);
  }

  componentWillUnmount() {
    this.onMouseOver.cancel();
  }

  //
  // Listeners

  _onMouseOver(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // Ugly, ugly workaround - for React select options, we don't want to show
    // the main tooltip for select options, as that messes up the life cycle of
    // enter/leave events. Sadly, this would break tooltips for individual options,
    // but that would probably be an anti-pattern anyways.
    if ((e.target as Element)?.classList.contains('Select__option')) {
      this._onMouseOut();
    } else {
      this.setState({ isVisible: true });
    }
  }

  _onMouseOut() {
    this.onMouseOver.cancel();

    this.setState({
      isVisible: false,
      calculatedDirection: null
    });
  }

  //
  // Control

  @autobind
  _getTooltipStyles(direction: string) {
    if (!this._parentRef) {
      return;
    }

    const parentRect = this._parentRef.getBoundingClientRect();

    const tooltipStyles: CSSProperties = {
      position: 'fixed',
      height: parentRect.height,
      width: parentRect.width,
      zIndex: zIndex.tooltipZIndex,
      pointerEvents: 'none',
      top: 0,
      left: 0
    };

    switch (direction) {
      case directions.SE:
      case directions.SW:
      case directions.S:
        tooltipStyles.top = parentRect.top + parentRect.height;
        tooltipStyles.left = parentRect.left;
        break;
      case directions.NW:
      case directions.NE:
      case directions.N:
        tooltipStyles.top = parentRect.top;
        tooltipStyles.left = parentRect.left;
        break;
      case directions.W:
      case directions.E:
        tooltipStyles.top = parentRect.top + parentRect.height / 2;
        tooltipStyles.left = parentRect.left;
        break;
      default:
        throw new Error(`Unhandled tooltip direction ${direction}`);
    }

    return tooltipStyles;
  }

  @autobind
  _setParentRef(ref: HTMLDivElement) {
    this._parentRef = ref;
  }

  @autobind
  _setDirection(calculatedDirection: string) {
    this.setState({ calculatedDirection });
  }

  //
  // Render method

  render() {
    const { children, withIcon, ...props } = this.props;
    const otherProps = _.omit(props, [
      'visibilityFn',
      'disableTooltip',
      'direction',
      'text',
      'multiline',
      'withIcon'
    ]);

    return (
      <div
        ref={this._setParentRef}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        {...otherProps}
      >
        {children}
        {withIcon && <TooltipQuestionMark />}
        {this._renderTooltip()}
      </div>
    );
  }

  @autobind
  _renderTooltip() {
    const { isVisible, calculatedDirection } = this.state;

    const { disableTooltip, direction, text, multiline, visibilityFn } =
      this.props;

    if (!isVisible || disableTooltip || !this._parentRef || !visibilityFn()) {
      return null;
    }

    const tooltipStyles = this._getTooltipStyles(direction);

    let tooltipContent = text;

    if (_.isFunction(text)) {
      tooltipContent = text();
    }

    if (!calculatedDirection) {
      // Disable rendering temporarily until we have calculated the direction - removes flickering
      const tempStyles = {
        ...tooltipStyles,
        visibility: 'hidden'
      } as React.CSSProperties;

      return (
        <Portal isOpen closeTimeout={tooltipSpeed}>
          <div style={tempStyles} className={styles.outerTooltipContainer}>
            <TooltipContent
              text={tooltipContent}
              multiline={multiline}
              direction={direction}
              directionCallback={this._setDirection}
            />
          </div>
        </Portal>
      );
    }

    return (
      <Portal isOpen closeTimeout={tooltipSpeed}>
        <Transition
          classNames={transitionStyles}
          timeout={{
            appear: tooltipSpeed,
            enter: tooltipSpeed,
            exit: tooltipSpeed
          }}
          style={tooltipStyles}
        >
          <TooltipContent
            text={tooltipContent}
            multiline={multiline}
            direction={calculatedDirection}
          />
        </Transition>
      </Portal>
    );
  }
}

export default Tooltip;
