import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { matchPath } from 'react-router-dom';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import DashboardEditor from 'ecto-common/lib/DashboardEditor/DashboardEditor';
import {
  DashboardFile,
  DashboardId,
  InitialDashboardFileData
} from 'ecto-common/lib/DashboardEditor/DashboardConstants';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';
import DashboardMenu from 'ecto-common/lib/DashboardEditor/DashboardMenu';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { useNavigate, useParams } from 'react-router-dom';

import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import TimeRangeContext from 'ecto-common/lib/Dashboard/context/TimeRangeContext';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import useTimeRangeSelector from 'ecto-common/lib/Dashboard/context/useTimeRangeSelector';
import { applyDashboardMigrations } from 'ecto-common/lib/Dashboard/migrations/migrations';
import DashboardDataContext, {
  useDashboardDataFromRedux
} from 'ecto-common/lib/hooks/DashboardDataContext';
import HelpPaths from 'ecto-common/help/tocKeys';

import styles from 'js/components/DashboardPage/DashboardPage.module.css';
import useDashboardMenu from 'js/components/DashboardPage/useDashboardMenu';
import DashboardConfirmSaveModal from 'js/components/DashboardPage/DashboardConfirmSaveModal';
import { getUserDashboardsUrl, LocationRoute } from 'js/utils/routeConstants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import useUndoHistories from 'ecto-common/lib/hooks/useUndoHistories';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { DashboardResponseModel } from 'ecto-common/lib/API/APIGen';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';
import IdentityServiceAPIGenV2, {
  UserDashboardModel
} from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { InitialPanel } from 'ecto-common/lib/DashboardEditor/EditDashboardPanelModal';
import { usePromptFunction } from 'ecto-common/lib/hooks/useBlockerListener';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';

const fullPathBuilder = (tenantId: string, nodeId: string, subPage: string) =>
  `/${tenantId}/home/${nodeId}/userdashboards/${subPage}`;

const fullPathForLocation = (
  tenantId: string,
  nodeId: string,
  pathname: string
) => {
  const params = matchPath<NodeParams, typeof LocationRoute.path>(
    LocationRoute.path,
    pathname
  ).params;
  return fullPathBuilder(tenantId, nodeId, params.subPage);
};

const urlBuilder = (tenantId: string, nodeId: string) => {
  return fullPathForLocation(tenantId, nodeId, window.location.pathname);
};

export const createDashboardResponseModel = (
  dashboard: UserDashboardModel
): DashboardResponseModel => {
  if (dashboard == null) {
    return null;
  }

  return {
    dashboardId: dashboard.id,
    name: dashboard.name,
    description: dashboard.description
  };
};

// TODO: Migrate to only use new api once the other places have been moved
const createUserDashboardModel = (
  oldDashboard: DashboardResponseModel
): UserDashboardModel => {
  return {
    id: oldDashboard.dashboardId,
    name: oldDashboard.name,
    description: oldDashboard.description,
    jsonData: InitialDashboardFileData
  };
};

const PersonalDashboardPage = ({
  onTitleChanged
}: {
  onTitleChanged: (title: string[]) => void;
}) => {
  const [triggerAddPanel, setTriggerAddPanel] = useReloadTrigger();
  const [panelEditItem, setPanelEditItem] = useState<DashboardPanel>(null);

  const [dashboardsWithChanges, setDashboardsWithChanges] = useState<
    Record<string, boolean>
  >({});

  const params = useParams<NodeParams>();
  const navigate = useNavigate();
  const [
    showingConfirmSaveDialog,
    showConfirmSaveDialog,
    hideConfirmSaveDialog
  ] = useSimpleDialogState();
  const nodeId = useOperatorSelector((state) => state.general.nodeId);
  const enums = useOperatorSelector((state) => state.general.enums);
  const signalTypesNameMap = useOperatorSelector(
    (state) => state.general.signalTypesNameMap
  );
  const selectedDashboardId = params.subPage;

  const { tenantId } = useContext(TenantContext);
  const userDashboardsQuery =
    IdentityServiceAPIGenV2.User.getUserDashboards.useQuery({
      refetchOnWindowFocus: false
    });

  const isLoadingCollection = userDashboardsQuery.isLoading;

  const [allDashboardsData, setAllDashboardsData] =
    useState<Record<DashboardId, UserDashboardModel>>(null);

  useEffect(() => {
    setAllDashboardsData(
      _.reduce(
        userDashboardsQuery.data?.dashboards,
        (result, dashboard) => {
          const jsonData = applyDashboardMigrations(
            (dashboard.jsonData as DashboardFile) ?? {
              ...InitialDashboardFileData
            },
            enums,
            signalTypesNameMap
          );
          result[dashboard.id] = { ...dashboard, jsonData };
          return result;
        },
        {} as Record<string, UserDashboardModel>
      )
    );
  }, [enums, signalTypesNameMap, userDashboardsQuery.data?.dashboards]);

  const userDashboards = useMemo(
    () => _.map(allDashboardsData),
    [allDashboardsData]
  );
  const selectedDashboard = allDashboardsData?.[selectedDashboardId];
  const dashboardFile = selectedDashboard?.jsonData as DashboardFile;

  const [panelEditItemId, setPanelEditItemId] = useState<string>(null);
  const existingPanelId = panelEditItem?.id ?? null;

  if (panelEditItemId !== existingPanelId && dashboardFile != null) {
    const panel = dashboardFile.panels.find((p) => p.id === panelEditItemId);
    if (panel == null && panelEditItemId != null) {
      setPanelEditItem({
        ...InitialPanel,
        id: panelEditItemId,
        title: T.admin.dashboards.panels.initialtitle
      });
    } else {
      setPanelEditItem(panel);
    }
  }

  const onSetPanelEditItem = useCallback(
    (panel: DashboardPanel) => {
      setPanelEditItem(panel);
      setPanelEditItemId(panel?.id ?? null);
    },
    [setPanelEditItem, setPanelEditItemId]
  );

  const onChangeDashboard = useCallback(
    (dashboardOldModel: DashboardResponseModel) => {
      navigate(
        getUserDashboardsUrl(tenantId, nodeId, dashboardOldModel.dashboardId),
        { replace: true }
      );
    },
    [navigate, nodeId, tenantId]
  );

  const setCurrentDashboardData = useCallback(
    (newDashboardData: UserDashboardModel) => {
      setAllDashboardsData((oldAllData) => {
        const newAllData = { ...oldAllData };
        newAllData[newDashboardData.id] = _.cloneDeep(newDashboardData);
        return newAllData;
      });
    },
    []
  );

  const [
    enableUndo,
    enableRedo,
    pushDashboardHistory,
    undoDashboardHistory,
    redoDashboardHistory
  ] = useUndoHistories<UserDashboardModel>(
    selectedDashboardId,
    selectedDashboard,
    setCurrentDashboardData
  );

  const onDashboardUpdated = useCallback(
    (dashboard: DashboardResponseModel, isNew: boolean) => {
      const newModel = createUserDashboardModel(dashboard);

      if (isNew) {
        setAllDashboardsData((oldAllData) => {
          const newAllData = { ...oldAllData };
          newAllData[newModel.id] = newModel;
          return newAllData;
        });
        toastStore.addSuccessToast(T.admin.dashboards.added.success);
      } else {
        setAllDashboardsData((oldAllData) => {
          const newAllData = { ...oldAllData };
          const oldData = oldAllData[dashboard.dashboardId];
          newAllData[newModel.id] = {
            ...oldData,
            ...newModel,
            jsonData: oldData.jsonData
          };
          return newAllData;
        });
        pushDashboardHistory(newModel);

        setDashboardsWithChanges((oldDashboardsWithChanges) => ({
          ...oldDashboardsWithChanges,
          [newModel.id]: true
        }));
      }
    },
    [pushDashboardHistory]
  );

  const onDeleteDashboard = useCallback((dashboard: DashboardResponseModel) => {
    setAllDashboardsData((oldAllData) => {
      const newAllData = { ...oldAllData };
      delete newAllData[dashboard.dashboardId];
      return newAllData;
    });
  }, []);

  const saveDashboardMutation =
    IdentityServiceAPIGenV2.User.createOrUpdateUserDashboards.useMutation({
      onMutate: (request) => {
        // Optimistic update
        setDashboardsWithChanges((oldDashboardsWithChanges) => {
          const removeChanges = { ...oldDashboardsWithChanges };
          for (const dashboard of request.dashboards) {
            delete removeChanges[dashboard.id];
          }
          return removeChanges;
        });
      },
      onSuccess: () => {
        toastStore.addSuccessToast(T.admin.dashboards.save.success);
        hideConfirmSaveDialog();
      },
      onError: (_error, request) => {
        // Restore state
        setDashboardsWithChanges((oldDashboardsWithChanges) => {
          const restoreChanges = { ...oldDashboardsWithChanges };
          for (const dashboard of request.dashboards) {
            restoreChanges[dashboard.id] = true;
          }
          return restoreChanges;
        });
        toastStore.addErrorToast(T.admin.dashboards.save.failure);
      }
    });

  const { menuOptions, menuComponent } = useDashboardMenu({});

  const onConfirmSave = useCallback(
    (saveAll: boolean) => {
      const keysToSave = saveAll
        ? _.keys(dashboardsWithChanges)
        : [selectedDashboard?.id];
      const dashboards = _.map(keysToSave, (dashboardId) => ({
        ...allDashboardsData[dashboardId]
      }));
      saveDashboardMutation.mutate({ dashboards });
    },
    [
      allDashboardsData,
      dashboardsWithChanges,
      saveDashboardMutation,
      selectedDashboard?.id
    ]
  );

  const onSaveDashboardFile = useCallback(() => {
    if (_.size(dashboardsWithChanges) > 1) {
      showConfirmSaveDialog();
    } else {
      onConfirmSave(true);
    }
  }, [showConfirmSaveDialog, onConfirmSave, dashboardsWithChanges]);

  const { userId } = useContext(UserContext);

  const [timeRange, timeRangeComponent] = useTimeRangeSelector();

  const setDashboardFile = useCallback(
    (newValue: DashboardFile) => {
      setAllDashboardsData((oldAllData) => {
        const jsonData = _.isFunction(newValue)
          ? newValue(oldAllData[selectedDashboard?.id]?.jsonData)
          : newValue;

        if (jsonData !== oldAllData[selectedDashboard?.id].jsonData) {
          const newAllData = { ...oldAllData };
          newAllData[selectedDashboard?.id].jsonData = jsonData;
          return newAllData;
        }
        return oldAllData;
      });
    },
    [selectedDashboard?.id]
  );

  const setCurrentDashboardModified = useCallback(() => {
    setDashboardsWithChanges((oldDashboardsWithChanges) => ({
      ...oldDashboardsWithChanges,
      [selectedDashboard?.id]: true
    }));
  }, [selectedDashboard?.id]);

  const undoDashboard = useCallback(() => {
    undoDashboardHistory();
    setCurrentDashboardModified();
  }, [setCurrentDashboardModified, undoDashboardHistory]);

  const redoDashboard = useCallback(() => {
    redoDashboardHistory();
    setCurrentDashboardModified();
  }, [redoDashboardHistory, setCurrentDashboardModified]);

  const showingDialog = panelEditItem != null;
  const isSavingDashboards = saveDashboardMutation.isLoading;
  const selectedDashboardConverted = useMemo(
    () => createDashboardResponseModel(selectedDashboard),
    [selectedDashboard]
  );

  const menu = useMemo(() => {
    const allDashboardsConverted = userDashboards.map(
      createDashboardResponseModel
    );

    return (
      <>
        <DashboardMenu
          showAddNewDashboard
          allDashboards={allDashboardsConverted}
          isLoadingDashboards={isLoadingCollection}
          isSavingDashboards={!showingConfirmSaveDialog && isSavingDashboards}
          dashboardsWithChanges={dashboardsWithChanges}
          onChangeDashboard={onChangeDashboard}
          dashboard={selectedDashboardConverted}
          onDashboardUpdated={onDashboardUpdated}
          onDeleteDashboard={onDeleteDashboard}
          onAddPanel={setTriggerAddPanel}
          onSave={onSaveDashboardFile}
          hasChanges={dashboardsWithChanges[selectedDashboard?.id] != null}
          disableEditing={selectedDashboard == null}
          dashboardUserId={userId}
          hasError={userDashboardsQuery.isError}
          undo={undoDashboard}
          enableUndo={enableUndo && !showingDialog}
          redo={redoDashboard}
          enableRedo={enableRedo && !showingDialog}
          handlePersonalDashboards
        />
        <ToolbarFlexibleSpace />
        <ToolbarItem className={styles.timerange}>
          {timeRangeComponent}
        </ToolbarItem>
      </>
    );
  }, [
    userDashboards,
    isLoadingCollection,
    showingConfirmSaveDialog,
    isSavingDashboards,
    dashboardsWithChanges,
    onChangeDashboard,
    selectedDashboardConverted,
    onDashboardUpdated,
    onDeleteDashboard,
    setTriggerAddPanel,
    onSaveDashboardFile,
    selectedDashboard,
    userId,
    userDashboardsQuery.isError,
    undoDashboard,
    enableUndo,
    showingDialog,
    redoDashboard,
    enableRedo,
    timeRangeComponent
  ]);

  const onDashboardFileChanged = useCallback(
    (newDashboardFile: DashboardFile) => {
      pushDashboardHistory({
        ...selectedDashboard,
        jsonData: newDashboardFile
      });
      setCurrentDashboardModified();
    },
    [pushDashboardHistory, selectedDashboard, setCurrentDashboardModified]
  );

  useEffect(() => {
    if (selectedDashboard == null && userDashboards.length > 0) {
      navigate(
        getUserDashboardsUrl(tenantId, nodeId, _.head(userDashboards).id),
        { replace: true }
      );
    }
  }, [navigate, nodeId, selectedDashboard, userDashboards, tenantId]);

  const setNode = useCallback(
    (newNodeId: string) => {
      navigate(fullPathBuilder(tenantId, newNodeId, selectedDashboard?.id));
    },
    [tenantId, navigate, selectedDashboard?.id]
  );

  const reduxDashboardDataValue = useDashboardDataFromRedux();
  const dashboardDataValue = useMemo(
    () => ({ nodeId, setNode, ...reduxDashboardDataValue }),
    [nodeId, setNode, reduxDashboardDataValue]
  );

  usePromptFunction(
    useCallback(
      ({ nextLocation }) => {
        const fullPath = fullPathForLocation(
          tenantId,
          nodeId,
          nextLocation.pathname
        );

        return fullPath === nextLocation.pathname
          ? null
          : T.admin.form.unsavedstate;
      },
      [nodeId, tenantId]
    ),
    !_.isEmpty(dashboardsWithChanges)
  );

  usePageTitleCallback({
    mainTitle: T.location.tabs.userdashboards,
    subTitle: selectedDashboard?.name,
    onTitleChanged
  });

  return (
    <ToolbarContentPage
      showLocationPicker
      wrapContent={false}
      title={T.location.tabs.userdashboards}
      toolbarItems={menu}
      urlBuilder={urlBuilder}
      helpPath={HelpPaths.docs.operator.personal_dashboards}
    >
      <NoDataMessage
        message={T.dashboard.error.personaldashboardsempty}
        visible={
          !userDashboardsQuery.isError &&
          !isLoadingCollection &&
          _.isEmpty(userDashboards)
        }
      />
      <DashboardDataContext.Provider value={dashboardDataValue}>
        <TimeRangeContext.Provider value={timeRange}>
          <DashboardEditor
            panelEditItem={panelEditItem}
            setPanelEditItem={onSetPanelEditItem}
            dashboardFile={dashboardFile}
            key={selectedDashboard?.id}
            setDashboardFile={setDashboardFile}
            isLoading={
              isLoadingCollection ||
              (!showingConfirmSaveDialog && isSavingDashboards)
            }
            triggerAddPanel={triggerAddPanel}
            error={
              userDashboardsQuery.isError
                ? T.dashboard.error.personaldashboards
                : null
            }
            onDashboardFileChanged={onDashboardFileChanged}
            menuOptions={menuOptions}
          />
        </TimeRangeContext.Provider>
      </DashboardDataContext.Provider>

      {menuComponent}

      <DashboardConfirmSaveModal
        isOpen={showingConfirmSaveDialog}
        onModalClose={hideConfirmSaveDialog}
        onConfirm={onConfirmSave}
        isLoading={isSavingDashboards}
      />
    </ToolbarContentPage>
  );
};

export default PersonalDashboardPage;
