class ArrayUtils {
  static sum(values) {
    return values.reduce(
      (currentSum, currentValue) => currentSum + currentValue,
      0
    );
  }

  static max(values) {
    return Math.max(...values);
  }

  static min(values) {
    return Math.min(...values);
  }

  static mean(values) {
    return this.sum(values) / values.length;
  }

  static zeros(length) {
    return new Array(length).fill(0);
  }

  static ones(length) {
    return new Array(length).fill(1);
  }

  // Scale profile linear with factor
  static scaleLin(profile, factor) {
    let scaledProfile = [];
    for (const elm of profile) {
      scaledProfile.push(elm * factor);
    }
    return scaledProfile;
  }

  // Sum of two arrays
  static arraySum = (arr1, arr2) => {
    let sumArr = [];
    for (let k = 0; k < arr1.length; k++) {
      sumArr.push(arr1[k] + arr2[k]);
    }
    return sumArr;
  };

  // Subtract two arrays
  static arraySubtract = (arr1, arr2) => {
    let subArr = [];
    for (let k = 0; k < arr1.length; k++) {
      subArr.push(arr1[k] - arr2[k]);
    }
    return subArr;
  };

  // Average of two arrays
  static arrayAverage = (arr1, arr2) => {
    let avgArr = [];
    for (let k = 0; k < arr1.length; k++) {
      avgArr.push((arr1[k] + arr2[k]) / 2.0);
    }
    return avgArr;
  };

  // Create array of defined value and defined length
  static createConstantArray(length, val) {
    let constArray = [];
    for (let t = 0; t < length; t++) {
      constArray.push(val);
    }
    return constArray;
  }

  // Round all numbers of array
  static roundArray(array, decimalPlaces) {
    const multiplier = this.getMultiplierRounding(decimalPlaces);
    array = array.map(function (value) {
      return Number(
        Math.round((value + Number.EPSILON) * multiplier) / multiplier
      );
    });
    return array;
  }

  // Get multiplier for rounding: If decimalPlaces=2, then multiplier=100; if decimalPlaces=3, then multiplier=1000
  static getMultiplierRounding(decimalPlaces) {
    if (decimalPlaces === 0) {
      return 1;
    } else {
      let multiplier;
      for (let k = 0; k < 10; k++) {
        if (decimalPlaces === k) {
          multiplier = Math.pow(10, k);
        }
      }
      return multiplier;
    }
  }

  // Count elements in array
  static countElements(arr, dictTypes) {
    const counts = {};
    // Initialize count dictionary
    Object.keys(dictTypes).forEach((dayType) => {
      counts[dictTypes[dayType]] = 0;
    });
    arr.forEach((el) => {
      counts[el] += 1;
    });
    return counts;
  }

  // Normalize profile, so that the sum is 1
  static normalizeProfile(profile) {
    let profileSum = ArrayUtils.sum(profile);
    if (profileSum > 0) {
      let normProfile = [];
      for (let t = 0; t < profile.length; t++) {
        let normValue = profile[t] / profileSum;
        normProfile.push(normValue);
      }
      return normProfile;
    } else {
      // If profile with only zeros
      return profile;
    }
  }

  // Slicing method with indices included
  static slicePlus = (array, idxBegin, idxEnd) => {
    // const testArray = [ 0, 1, 2, 3, 4, 5]
    //               //    0  1  2  3  4  5
    //               //   -6 -5 -4 -3 -2 -1

    // TEST CODE SNIPPET (do not delete!):

    // const arrLength = Array.from(Array(24),(x,i)=>i)
    // arrLength.forEach(k => {
    // let newArray = ArrayUtils.slicePlus(testArray, k-7, k-5)
    // console.log("(..., " + (k-7).toString() + ", " + (k-5).toString() + "): ")
    // console.log(newArray)
    // })

    const arrLength = array.length;

    // Error, if start > end
    if (idxBegin > idxEnd) {
      return array.slice(idxBegin, idxEnd + 1);

      // If start == end
    } else if (idxBegin === idxEnd) {
      return [array[idxBegin]];

      // If start index to small or end index to large
    } else if ((idxBegin < -1 * arrLength) | (idxEnd > 2 * arrLength - 1)) {
      // console.log("If start index to small or end index to large")
      return null;

      // Begin index >= 0 and end index >= 0 but also < array length
    } else if ((idxBegin >= 0) & (idxEnd < arrLength)) {
      // console.log("Begin index >= 0 and end index < array length")
      return array.slice(idxBegin, idxEnd + 1);

      // Both indices are < -1
    } else if ((idxBegin < 0) & (idxEnd + 1 < 0)) {
      // console.log("Both indices are < -1")
      return array.slice(arrLength - -1 * idxBegin, idxEnd + 1);

      // Begin index is < 0, end index is >= -1
    } else if ((idxBegin < 0) & (idxEnd <= arrLength)) {
      // console.log("Begin index is < 0, end index is >= -1")
      const firstPart = array.slice(idxBegin);
      const secondPart = array.slice(0, idxEnd + 1);
      const joinedArray = firstPart.concat(secondPart);
      return joinedArray;

      // Begin index is < array length, end index is >= array length
    } else if ((idxBegin < arrLength) & (idxEnd >= arrLength)) {
      // console.log("Begin index is < array length, end index is >= array length")
      const firstPart = array.slice(idxBegin);
      // console.log(firstPart)
      const secondPart = array.slice(0, idxEnd + 1 - arrLength);
      // console.log(secondPart)
      const joinedArray = firstPart.concat(secondPart);
      return joinedArray;

      // Both indices >= array length
    } else if (idxBegin >= arrLength) {
      // console.log("Both indices >= array length")
      return array.slice(idxBegin - arrLength, idxEnd + 1 - arrLength);
    }
  };

  // The array division, return arr1 / arr2
  static divArray = (arr1, arr2) => {
    let divArr = [];
    for (let k = 0; k < arr1.length; k++) {
      if (arr2[k] != 0) {
        divArr.push(arr1[k] / arr2[k]);
      } else {
        divArr.push(null);
      }
    }
    return divArr;
  };
}

export default ArrayUtils;
