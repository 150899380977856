import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  ChangeEventHandler
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { FileIcon } from 'ecto-common/lib/Icon';
import { downloadBlobFromText } from 'ecto-common/lib/utils/downloadBlob';
import API from 'ecto-common/lib/API/API';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';

import { formatDate } from 'js/utils/dateUtils';
import {
  ControlledDataOptions,
  DEFAULT_DATA_OPTIONS,
  OptionKey,
  OptionType
} from 'js/components/ExportData/DataOptions';
import {
  FileFormatOptions,
  FileFormatTypeStrings
} from 'js/components/ExportData/types';
import styles from 'js/components/OperatorChart/ExportDialog.module.css';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { GraphSettingsType } from 'ecto-common/lib/types/EctoCommonTypes';
import { DateRangeType } from 'ecto-common/lib/utils/dateUtils';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';

import {
  AggregationType,
  SamplingInterval,
  SignalsGetSignalValuesByTimeRangeExportToCsvParams
} from 'ecto-common/lib/API/APIGen';

export type ExportGraphImageOptions = {
  filename: string;
  type: string;
  sourceWidth: number;
  sourceHeight: number;
};

const getQuery = (
  signalsToFetch: ChartSignal[] = [],
  dateFrom: number,
  dateTo: number,
  aggregation: AggregationType,
  samplingInterval: SamplingInterval
): SignalsGetSignalValuesByTimeRangeExportToCsvParams => {
  if (
    dateFrom < 0 ||
    dateTo < 0 ||
    !signalsToFetch ||
    signalsToFetch.length === 0
  ) {
    return null;
  }

  const signalIds = _.map(signalsToFetch, 'item.signalId');
  const region = new Intl.DateTimeFormat();

  return {
    SignalIds: signalIds,
    StartDate: formatDate(dateFrom),
    EndDate: formatDate(dateTo),
    SamplingInterval: samplingInterval,
    Aggregation: aggregation,
    TimeZone: region.resolvedOptions().timeZone
  };
};

const DEFAULT_FORMAT_OPTIONS: FileFormatOptions[] = [
  FileFormatOptions.CSV,
  FileFormatOptions.SVG,
  FileFormatOptions.PNG,
  FileFormatOptions.JPEG,
  FileFormatOptions.PDF
];

const DEFAULT_IMAGE_WIDTH = 1024;
const DEFAULT_IMAGE_HEIGHT = 760;

const validDimension = (value: string | number) => {
  const parsedValue = _.isString(value) ? _.parseInt(value) : value;

  return parsedValue > 100 && parsedValue < 10000;
};

interface ExportDialogProps {
  isOpen?: boolean;
  onModalClose: () => void;
  selectedSignals: ChartSignal[];
  zoomRange?: DateRangeType;
  onExportGraphImage(
    options: ExportGraphImageOptions,
    resetDimensions: () => void
  ): void;
  graphSettings?: GraphSettingsType;
}

const ExportDialog = ({
  isOpen,
  onModalClose,
  selectedSignals,
  zoomRange,
  onExportGraphImage,
  graphSettings
}: ExportDialogProps) => {
  const [selectedAggregationOption, setSelectedAggregationOption] = useState(
    DEFAULT_DATA_OPTIONS.aggregation
  );
  const [selectedSamplingOption, setSelectedSamplingOption] = useState(
    DEFAULT_DATA_OPTIONS.sampling
  );
  // TODO: Improve casting between enums here
  const [selectedFormat, setSelectedFormat] = useState(
    DEFAULT_DATA_OPTIONS.format as unknown as FileFormatOptions
  );
  const [hasError, setError] = useState<boolean>(null);

  const [imageWidth, setImageWidth] = useState(DEFAULT_IMAGE_WIDTH);
  const [imageHeight, setImageHeight] = useState(DEFAULT_IMAGE_HEIGHT);
  const [imageFileName, setImageFileName] = useState<string>(null);
  const invalidWidth = useMemo(() => !validDimension(imageWidth), [imageWidth]);
  const invalidHeight = useMemo(
    () => !validDimension(imageHeight),
    [imageHeight]
  );
  const invalidName = useMemo(
    () => imageFileName?.length === 0 || imageFileName?.length > 216,
    [imageFileName]
  );

  useEffect(() => {
    if (graphSettings.name) {
      setImageFileName(graphSettings.name);
    }
  }, [graphSettings.name]);

  const resetDimensions = useCallback(() => {
    setImageWidth(DEFAULT_IMAGE_WIDTH);
    setImageHeight(DEFAULT_IMAGE_HEIGHT);
  }, []);

  const _onModalClose = useCallback(() => {
    onModalClose();
  }, [onModalClose]);

  const [isLoading, getExport] = usePromiseCall({
    promise: API.Export.getSignalDataExportCsv,
    onSuccess: (response) => {
      const { dateFrom, dateTo } = zoomRange;
      // Create temporary element, click it, send data and remove it from DOM-tree.
      const fileName = `Ectocloud_Graphs_Download_${new Date(dateFrom).toISOString()}-${new Date(dateTo).toISOString()}-${selectedSamplingOption}-${selectedAggregationOption}.csv`;
      _onModalClose();
      toastStore.addSuccessToast(T.graphs.exportdialog.export.success);
      return downloadBlobFromText(response, fileName);
    },
    onError: setError
  });

  const onConfirmExportClick = useCallback(() => {
    if (selectedFormat !== FileFormatOptions.CSV) {
      const exportPresetOptions = {
        filename: imageFileName ?? graphSettings?.name,
        type: FileFormatTypeStrings[selectedFormat],
        sourceWidth: imageWidth,
        sourceHeight: imageHeight
      };

      const exportOptions = _.includes(
        [FileFormatOptions.JPEG, FileFormatOptions.PNG],
        selectedFormat
      )
        ? {
            ...exportPresetOptions,
            scale: 1,
            width: imageWidth,
            height: imageHeight
          }
        : exportPresetOptions;

      onExportGraphImage(exportOptions, resetDimensions);
    } else {
      const { dateFrom, dateTo } = zoomRange;
      const query = getQuery(
        selectedSignals,
        dateFrom,
        dateTo,
        selectedAggregationOption,
        selectedSamplingOption
      );

      if (query) {
        getExport(query);
      }
    }
  }, [
    selectedFormat,
    imageFileName,
    graphSettings?.name,
    imageWidth,
    imageHeight,
    onExportGraphImage,
    resetDimensions,
    zoomRange,
    selectedSignals,
    selectedAggregationOption,
    selectedSamplingOption,
    getExport
  ]);

  const onChangeWidth: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setImageWidth(_.parseInt(event.target.value));
    },
    []
  );

  const onChangeHeight: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setImageHeight(_.parseInt(event.target.value));
    },
    []
  );

  const onChangeFileName: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setImageFileName(event.target.value);
    },
    []
  );

  // Omit sampling and aggregation from data options since they will be filled by default graphSettings.
  const [_dataOptions, setDataOptions] = useState(
    _.omit(DEFAULT_DATA_OPTIONS, [OptionKey.SAMPLING, OptionKey.AGGREGATION])
  );

  const onDataOptionsChanged = useCallback(
    (dataOptions: OptionType) => {
      setSelectedSamplingOption(dataOptions.sampling);
      setSelectedAggregationOption(dataOptions.aggregation);
      setSelectedFormat(dataOptions.format);
      setDataOptions(dataOptions);
    },
    [setSelectedFormat]
  );

  // Since we do omit the initial default data option value and append them here, we need to ts-ignore the optional types.
  // @ts-ignore-next-line
  const dataOptions: OptionType = useMemo(
    () => ({
      ...(graphSettings?.samplingInterval
        ? { [OptionKey.SAMPLING]: graphSettings.samplingInterval }
        : {}),
      ...(graphSettings?.aggregation
        ? { [OptionKey.AGGREGATION]: graphSettings.aggregation }
        : {}),

      ..._dataOptions
    }),
    [_dataOptions, graphSettings.samplingInterval, graphSettings.aggregation]
  );

  return (
    <ActionModal
      className={styles.exportModal}
      isOpen={isOpen}
      title={T.graphs.exportdialog.title}
      onConfirmClick={onConfirmExportClick}
      actionText={T.graphs.exportdialog.confirm}
      headerIcon={FileIcon}
      isLoading={isLoading}
      onModalClose={_onModalClose}
      cancelText={T.common.cancel}
    >
      <ControlledDataOptions
        onOptionsChanged={onDataOptionsChanged}
        formatOptions={DEFAULT_FORMAT_OPTIONS}
        options={dataOptions}
      />

      {hasError && (
        <div className={classNames(styles.exportRow, styles.error)}>
          {T.graphs.exportdialog.exportcvs.failure}
        </div>
      )}

      {selectedFormat !== FileFormatOptions.CSV && (
        <>
          <KeyValueLine>
            <KeyValueInput
              value={imageFileName}
              keyText={T.graphs.exportdialog.filename}
              onChange={onChangeFileName}
              hasError={invalidName}
            />
          </KeyValueLine>

          <KeyValueLine>
            <KeyValueInput
              value={imageWidth?.toString() ?? ''}
              keyText={T.graphs.exportdialog.imagewidth}
              onChange={onChangeWidth}
              hasError={invalidWidth}
            />

            <KeyValueInput
              value={imageHeight?.toString() ?? ''}
              keyText={T.graphs.exportdialog.imageheight}
              onChange={onChangeHeight}
              hasError={invalidHeight}
            />
          </KeyValueLine>
        </>
      )}
    </ActionModal>
  );
};

export default React.memo(ExportDialog);
