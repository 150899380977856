import { getSubtitle } from 'ecto-common/lib/ToolbarContentPage/ToolbarLocationPicker';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { useEffect } from 'react';
import _ from 'lodash';

const usePageTitleCallback = ({
  mainTitle,
  subTitle,
  onTitleChanged
}: {
  mainTitle: string;
  subTitle: string;
  onTitleChanged: (title: string[]) => void;
}) => {
  const nodeId = useCommonSelector((state) => state.general.nodeId);
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  useEffect(() => {
    const node = getNodeFromMap(nodeMap, nodeId);
    const nodeTitle = getSubtitle(node, nodeMap);
    onTitleChanged?.(
      _.compact([mainTitle, nodeTitle[0], node?.name, subTitle])
    );
  }, [onTitleChanged, mainTitle, subTitle, nodeMap, nodeId]);
};
export default usePageTitleCallback;
