import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import T from 'ecto-common/lib/lang/Language';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import HelpPaths from 'ecto-common/help/tocKeys';

import GraphEditor from 'js/containers/GraphEditor';
import { SeriesInterval } from 'ecto-common/lib/types/EctoCommonTypes';
import { TelemetryZoomRange } from 'js/modules/signalCollections/signalCollections';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';

const GraphsPage = ({
  onTitleChanged
}: {
  onTitleChanged: (title: string[]) => void;
}) => {
  usePageTitleCallback({
    mainTitle: T.location.tabs.graphs,
    subTitle: '',
    onTitleChanged
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const customZoomRange: TelemetryZoomRange = useMemo(() => {
    const customZoomRangeString = searchParams.get('zoom-range');
    if (customZoomRangeString) {
      const [start, end] = customZoomRangeString.split(':');
      return {
        dateFrom: _.parseInt(start),
        dateTo: _.parseInt(end),
        seriesInterval: null
      };
    }

    return null;
  }, [searchParams]);

  const selectedSignalCollectionId = useOperatorSelector(
    (state) => state.signalCollections.selectedSignalCollectionId
  );

  const prevSelectedSignalCollectionId = useRef(selectedSignalCollectionId);

  useEffect(() => {
    if (prevSelectedSignalCollectionId.current !== selectedSignalCollectionId) {
      setSearchParams((prev) => {
        prev.set('graph-id', selectedSignalCollectionId);

        return prev;
      });
      prevSelectedSignalCollectionId.current = selectedSignalCollectionId;
    }
  }, [selectedSignalCollectionId, setSearchParams]);

  const setCustomZoomRange = useCallback(
    (zoomRange: TelemetryZoomRange) => {
      if (zoomRange) {
        setSearchParams((prev) => {
          prev.set('zoom-range', `${zoomRange.dateFrom}:${zoomRange.dateTo}`);
          return prev;
        });
      } else {
        setSearchParams((prev) => {
          prev.delete('zoom-range');
          return prev;
        });
      }
    },
    [setSearchParams]
  );

  const setSeriesIntervalAndClearCustomZoomRange = useCallback(
    (seriesInterval: SeriesInterval) => {
      setSearchParams((prev) => {
        prev.delete('zoom-range');
        prev.set('series-interval', seriesInterval);
        return prev;
      });
    },
    [setSearchParams]
  );

  return (
    <ToolbarContentPage
      title={T.location.tabs.graphs}
      wrapContent={false}
      helpPath={HelpPaths.docs.operator.graphs}
      removeTopMargin
    >
      <GraphEditor
        customZoomRange={customZoomRange}
        setCustomZoomRange={setCustomZoomRange}
        seriesInterval={
          (searchParams.get('series-interval') as SeriesInterval) ??
          SeriesInterval.DAY
        }
        setSeriesIntervalAndClearCustomZoomRange={
          setSeriesIntervalAndClearCustomZoomRange
        }
        initialCollectionId={searchParams.get('graph-id')}
      />
    </ToolbarContentPage>
  );
};
export default GraphsPage;
