/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * NOTE: This file is deprecated in favor for the new generated and type safe definitions (APIGen.ts et al.)
 *
 * It should not be used for new endpoints and we should migrate existing endpoints to the newly generated ones
 * incrementally.
 */
import queryString from 'query-string';
import {
  handleRequestError,
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS
} from 'ecto-common/lib/utils/requestStatus';
import _ from 'lodash';
import { GetJSONArrayFromAPI } from 'ecto-common/lib/utils/FetchUtils';
import {
  AlarmSeverity,
  CancelledErrorName,
  SortOrderType
} from 'ecto-common/lib/constants';

import {
  PagedDashboardCollectionResponseModel,
  SamplingInterval,
  SignalProviderType,
  GetEnumsAndFixedConfigurationsResponseModel,
  AdminAlarmSignalGroupTemplateResponseModel,
  EquipmentTypeResponseModel,
  EquipmentSignalProviderTemplateResponseModel,
  BuildingTemplateResponseModel,
  NodeResponseModel,
  CreateBuildingByTemplateDataResponseModel,
  BuildingListResponseModel,
  CreateComfortHeatingByTemplateResponseModel,
  ComfortHeatingProviderResponseModel,
  EquipmentResponseModel,
  SimplifiedEquipmentResponseModel,
  DeployDeviceConfigsResponseModel,
  DeviceStatusResponseModel,
  ConnectionResponseModel,
  DeviceInfoResponseModel,
  SlaveIdResponseModel,
  SyncDeviceTwinResponseModel,
  MappingResponseModel,
  AdminEquipmentSignalResponseModel,
  SignalProviderByNodeResponseModel,
  FullSignalProviderResponseModel,
  SignalCategoryResponseModel,
  SignalTypeResponseModel,
  SignalTypeFolderResponseModel,
  DirectHeartbeatResponseModel,
  PingDevicesResponseModel,
  RestartModulesResponseModel,
  RebootDeviceResponseModel,
  ModuleResponseModel,
  IoTDeviceViewResponseModel,
  GetAlarmSignalTypeIdsForNodesResponseModel,
  AddOrUpdateAlarmNotificationsResponseModel,
  GetAlarmNotificationsResponseModel,
  TemperatureImpactProviderSignalsResponseModel,
  ProcessMapResponseModel,
  ProcessMapRevisionResponseModel,
  EquipmentTypeProcessMapRelationshipResponseModel,
  NodeProcessMapRelationshipResponseModel,
  DashboardFileResponseModel,
  PagedDashboardsResponseModel,
  DashboardResponseModel,
  DashboardCollectionIdResponseModel,
  DashboardCollectionResponseModel,
  PowerDeltaControlProviderResponseModel,
  LoBaBatteryChargingThresholdProviderResponseModel,
  AdminNodeResponseModel,
  GeographicalPointResponseModel,
  SignalProviderTelemetryResponseModel,
  DownloadSignalExportResponseModel,
  NodeSignalViewResponseModel,
  ScheduleCollectionResponseModel,
  DashboardCollectionNodeRelationsResponseModel,
  DashboardCollectionViewResponseModel,
  LinearOptimisationResponseModel,
  IoTDeviceSettingsResponseModel,
  AddOrUpdateEquipmentTypeRequestModel,
  AddOrUpdateEquipmentSignalProviderTemplateRequestModel,
  AddOrUpdateBuildingTemplateRequestModel,
  AddOrUpdateAlarmSignalGroupTemplateRequestModel,
  AddOrUpdateBuildingsByTemplatesRequestModel,
  GetBuildingsByFiltersRequestModel,
  GetBuildingsExportRequestModel,
  AddOrUpdateComfortHeatingByTemplateRequestModel,
  DeleteEquipmentSignalProviderTemplatesRequestModel,
  AddOrUpdateEnergyManagerByEquipmentTypeRequestModel,
  AddOrUpdateEquipmentByEquipmentTypeRequestModel,
  AddOrUpdateConnectionRequestModel,
  AddOrUpdateMappingRequestModel,
  AddOrUpdateEquipmentSignalsRequestModel,
  AddOrUpdateAlarmSignalsRequestModel,
  AddOrUpdateSignalTypeRequestModel,
  AddOrUpdateSignalTypeFolderRequestModel,
  DeleteSignalTypeFoldersRequestModel,
  AddOrUpdateIoTDeviceRequestModel,
  SetDefaultDashboardCollectionIdRequestModel,
  GetDashboardCollectionsRequestModel,
  AddOrUpdateDashboardCollectionRequestModel,
  AddOrUpdatePowerDeltaControlProviderRequestModel,
  AddOrUpdateLoBaBatteryChargingThresholdProviderRequestModel,
  AddOrUpdateNodeRequestModel,
  AddGeographicalPointRequestModel,
  SetSignalResponseModel,
  AddSignalExportRequestModel,
  SignalValueRequestModel2,
  GetTemperatureImpactPreviewRequestModel,
  TemperatureImpactPreviewResponseModel,
  AddOrUpdatePowerControlScheduleRequestModel,
  DeletePowerControlScheduleRequestModel,
  GetDashboardCollectionViewByNodeIdRequestModel,
  AddOrUpdateDashboardFileRequestModel,
  AddOrUpdateDashboardRequestModel,
  AlarmsListResponseModel,
  BuildingStatus,
  AcknowledgeAlarmRequestModel,
  SetLinearOptimisationSignalsRequestModel,
  SignalsGetSignalValuesByTimeRangeExportToCsvParams,
  AddOrUpdateIoTDeviceSettingsRequestModel,
  AlarmNotificationRequestModel,
  AddOrUpdateTemperatureImpactProviderRequestModel,
  AddOrUpdateProcessMapRequestModel,
  AddOrUpdateProcessMapRevisionRequestModel,
  GetPagedDashboardsRequestModel,
  DeleteDashboardsRequestModel,
  GetProcessMapRevisionsByProcessMapIdRequestModel,
  DeleteSignalTypesRequestModel,
  IoTDevicesSearchResponseModel,
  SetSignalRequestModel,
  AggregationType,
  PowerControlType
} from 'ecto-common/lib/API/APIGen';
import { getBuildingStatusQueryParameter } from 'ecto-common/lib/utils/buildingStatusUtil';
import { Moment } from 'moment';
import { CommonDispatch } from '../reducers/storeCommon';
import { APIFetchType, FetchOptions } from 'ecto-common/lib/utils/APIFetchType';
import { getAPIFetch } from 'ecto-common/lib/utils/APIFetchInstance';
import { ApiContextSettings } from './APIUtils';
import { featureFlagStore } from '../FeatureFlags/FeatureFlags';

export type RequestDate = Moment | Date | null;

const signalsPathPrefix = () => {
  return featureFlagStore.getSnapshot()['eiot-signals']
    ? '/eiot-signals'
    : '/signals';
};

export const cancellableAPIFetch = (
  contextSettings: ApiContextSettings,
  path: string,
  options: RequestInit,
  _apiFetch: APIFetchType,
  fetchOptions: FetchOptions
) => {
  const hasAbortSupport = 'signal' in new Request('');

  if (hasAbortSupport) {
    const abortController = new window.AbortController();

    return {
      promise: _apiFetch(
        contextSettings,
        path,
        {
          ...options,
          signal: abortController.signal
        },
        fetchOptions
      ),
      abortController
    };
  }

  return {
    promise: _apiFetch(contextSettings, path, options, fetchOptions),
    abortController: null
  };
};

export function cancellablePromise<ReturnType = any>(
  promise: Promise<ReturnType>,
  abortController: AbortController
): CancellablePromise<ReturnType> {
  let cancelled = false;

  promise = promise.then((result) => {
    if (cancelled) {
      // TODO: Don't rely on DOMError, use react-query instead
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-next-line
      throw new DOMError(CancelledErrorName, CancelledErrorName);
    }

    return Promise.resolve(result);
  });

  (promise as any).cancel = function () {
    if (abortController) {
      abortController.abort();
    }

    cancelled = true;
  };

  return promise;
}

export interface CancellablePromise<T> extends Promise<T> {
  cancel?: () => void;
}

export function cancellablePromiseList<T extends readonly unknown[]>(
  promises: T
): CancellablePromise<{ -readonly [P in keyof T]: Awaited<T[P]> }> {
  const ret = Promise.all(promises);

  (ret as CancellablePromise<any>).cancel = () => {
    _.forEach(promises, (p) => _.invoke(p, 'cancel'));
  };

  return ret;
}

export type CancellablePromiseCallback<ReturnType = unknown> = (
  newPromise: CancellablePromise<ReturnType>
) => CancellablePromise<ReturnType>;

/**
 * Use this method for situations where you first want to do one request and then use the result of that to do another, and so on.
 *
 * Using usePromiseCall requires you to provide a cancellable promise. The problem is that if you do API.method.then(..) the "then" call will remove the
 * cancel() method. With this helper you can update which cancellable promise is currently running. The method creates a cancel function that is invoked
 * only on the currently running promise.
 *
 * @param createPromiseFunc - Will be invoked with withNextPromise as argument - use this to update the current cancellable promise throughout the chain.
 */
export function cancellablePromiseSequence<ReturnType = any>(
  createPromiseFunc: any
): CancellablePromise<ReturnType> {
  let _curPromise: CancellablePromise<unknown> = null;

  const withNextPromise = (
    newPromise: CancellablePromise<unknown>
  ): CancellablePromise<unknown> => {
    _curPromise = newPromise;
    return newPromise;
  };

  const ret = createPromiseFunc(withNextPromise).catch((e: Error) => {
    withNextPromise(null);
    throw e;
  });

  ret.cancel = () => {
    _curPromise?.cancel();
  };

  return ret;
}

/**
 * This method can be used when you do multiple steps of different promise calls and
 * only use the results at the end of the operation.
 *
 * @param sequenceObject - A dictionary with methods that returns a Promise. Executes methods in insertion order.
 * @return - returns cancellable promise that will cancel the currently executing request on cancel().
 * @example
 return promiseSequenceNamedResult({
      firstMethodToExecute: () => GivenPromise(),
      wait: () => wait(5000),
      secondMethodToExecute: () => GivenPromise()
    })
 .then(result => ({
        firstResult: result.firstMethodToExecute,
        wait: undefined,
        secondResult: result.secondMethodToExecute,
      }));
 */
export const cancellablePromiseSequenceWithNamedResult = (
  sequenceObject: Record<string, () => CancellablePromise<unknown>>
) => {
  return cancellablePromiseSequence(
    (withNextPromise: CancellablePromiseCallback) => {
      const results: unknown[] = [];
      const promises = _.values(sequenceObject);
      const firstPromise = _.head(promises);
      let promiseChain = withNextPromise(firstPromise());

      _.forEach(_.tail(promises), (currentPromise) => {
        promiseChain = promiseChain.then((result: unknown) => {
          results.push(result);
          return withNextPromise(currentPromise());
        });
      });

      promiseChain = promiseChain.then((result: unknown) => {
        results.push(result);

        return _.reduce(
          _.keys(sequenceObject),
          (dict, key, index) => {
            dict[key] = results[index];
            return dict;
          },
          {} as Record<string, any>
        );
      });

      return promiseChain;
    }
  );
};

type OptionsType = {
  method?: string;
  body?: BodyInit;
  headers?: { [key: string]: string };
};

export function TextPut<ValueType = any>(
  contextSettings: ApiContextSettings,
  path: string,
  body: any,
  options: OptionsType = {},
  fetchOptions: FetchOptions = {},
  fetchInstance = getAPIFetch()
) {
  return JSONFetch<ValueType>(
    contextSettings,
    path,
    {
      method: 'PUT',
      body,
      ...options,
      headers: {
        ...options.headers,
        'Content-Type': 'text'
      }
    },
    fetchOptions,
    fetchInstance
  );
}

export function JSONPut<ValueType = any>(
  contextSettings: ApiContextSettings,
  path: string,
  body: any,
  options: OptionsType = {},
  fetchOptions: FetchOptions = {},
  fetchInstance = getAPIFetch()
) {
  return JSONFetch<ValueType>(
    contextSettings,
    path,
    {
      method: 'PUT',
      body: JSON.stringify(body),
      ...options,
      headers: {
        ...options.headers,
        'Content-Type': 'application/json'
      }
    },
    fetchOptions,
    fetchInstance
  );
}

export function JSONPost<ValueType = any>(
  contextSettings: ApiContextSettings,
  path: string,
  body = {},
  options: OptionsType = {},
  fetchOptions: FetchOptions = {},
  fetchInstance = getAPIFetch()
) {
  return JSONFetch<ValueType>(
    contextSettings,
    path,
    {
      method: 'POST',
      body: JSON.stringify(body),
      ...options,
      headers: {
        ...options.headers,
        'Content-Type': 'application/json'
      }
    },
    fetchOptions,
    fetchInstance
  );
}

export const JSONPostUnparsedReturn = (
  contextSettings: ApiContextSettings,
  path: string,
  body: any,
  options: OptionsType = {},
  fetchOptions: FetchOptions = {},
  fetchInstance = getAPIFetch()
) => {
  const _options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(body),
    ...options,
    headers: {
      ...options.headers,
      'Content-Type': 'application/json'
    }
  };
  const { promise, abortController } = cancellableAPIFetch(
    contextSettings,
    path,
    _options,
    fetchInstance,
    { ...fetchOptions }
  );

  return cancellablePromise(
    promise.then((response: any) => response.text()).then((data: any) => data),
    abortController
  );
};

export function JSONDeleteBody<ValueType = any>(
  contextSettings: ApiContextSettings,
  path: string,
  body: any,
  options: OptionsType = {},
  fetchOptions = {},
  fetchInstance = getAPIFetch()
) {
  return JSONFetch<ValueType>(
    contextSettings,
    path,
    {
      method: 'DELETE',
      body: JSON.stringify(body),
      ...options,
      headers: {
        ...options.headers,
        'Content-Type': 'application/json'
      }
    },
    fetchOptions,
    fetchInstance
  );
}

export function JSONDelete<ValueType = any>(
  contextSettings: ApiContextSettings,
  path: string,
  query: any,
  options: OptionsType = {},
  fetchOptions = {},
  fetchInstance = getAPIFetch()
) {
  let suffix = '';

  if (query) {
    suffix = '?' + queryString.stringify(query);
  }

  return JSONFetch<ValueType>(
    contextSettings,
    path + suffix,
    {
      method: 'DELETE',
      ...options,
      headers: {
        ...options.headers,
        'Content-Type': 'application/json'
      }
    },
    fetchOptions,
    fetchInstance
  );
}

const JSON_FETCH_DEFAULT_OPTIONS = {};

export function JSONQuery<ValueType = any>(
  contextSettings: ApiContextSettings,
  path: string,
  query: any,
  options: OptionsType = JSON_FETCH_DEFAULT_OPTIONS,
  fetchOptions = {},
  fetchInstance = getAPIFetch()
) {
  return JSONFetch<ValueType>(
    contextSettings,
    path + '?' + queryString.stringify(query),
    options,
    fetchOptions,
    fetchInstance
  );
}

export const JSONQueryUnparsedReturn = (
  contextSettings: ApiContextSettings,
  path: string,
  query: any,
  options: OptionsType = JSON_FETCH_DEFAULT_OPTIONS,
  fetchOptions = {},
  fetchInstance = getAPIFetch()
) => {
  let _path = path;

  if (query) {
    _path = path + '?' + queryString.stringify(query);
  }

  const { promise, abortController } = cancellableAPIFetch(
    contextSettings,
    _path,
    options,
    fetchInstance,
    fetchOptions
  );

  return cancellablePromise(
    promise.then((response: any) => response.text()).then((data: any) => data),
    abortController
  );
};

export const BlobFetch = (
  contextSettings: ApiContextSettings,
  path: string,
  options: OptionsType = JSON_FETCH_DEFAULT_OPTIONS,
  fetchOptions = {},
  fetchInstance = getAPIFetch()
) => {
  const { promise, abortController } = cancellableAPIFetch(
    contextSettings,
    path,
    options,
    fetchInstance,
    fetchOptions
  );
  return cancellablePromise(
    promise.then((response: any) => {
      return response?.blob();
    }),
    abortController
  );
};

export function JSONFetch<ValueType = any>(
  contextSettings: ApiContextSettings,
  path: string,
  options: OptionsType = JSON_FETCH_DEFAULT_OPTIONS,
  fetchOptions = {},
  fetchInstance = getAPIFetch()
) {
  const { promise, abortController } = cancellableAPIFetch(
    contextSettings,
    path,
    options,
    fetchInstance,
    fetchOptions
  );
  return cancellablePromise<ValueType>(
    promise
      .then((response: any) => response?.text())
      .then((data: any) => {
        return (data ? JSON.parse(data) : data) as ValueType;
      }),
    abortController
  );
}

const emptyResponseUpdateFetch = (
  contextSettings: ApiContextSettings,
  path: string,
  body: any,
  method = 'PUT',
  fetchOptions = {},
  fetchInstance = getAPIFetch()
): CancellablePromise<void> => {
  const { promise, abortController } = cancellableAPIFetch(
    contextSettings,
    path,
    {
      method: method,
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    },
    fetchInstance,
    fetchOptions
  );

  return cancellablePromise(promise, abortController);
};

export const performAsyncRequestWithStateChanges = async (
  dispatch: CommonDispatch,
  action: any,
  reqStateConstant: string,
  id: string = undefined,
  params = {},
  blocking = false
) => {
  dispatch({
    type: reqStateConstant,
    payload: { state: REQ_STATE_PENDING, payload: null, params, id, blocking }
  });

  try {
    let result;

    if (_.isFunction(action)) {
      result = await action();
    } else {
      result = await action;
    }

    dispatch({
      type: reqStateConstant,
      payload: {
        state: REQ_STATE_SUCCESS,
        id,
        payload: result,
        params,
        blocking
      }
    });

    return result;
  } catch (e) {
    await handleRequestError(reqStateConstant, e, id, dispatch, null, params);
  }
};

const _addWeather = async (
  contextSettings: ApiContextSettings,
  nodeId: string,
  weatherPoint: AddGeographicalPointRequestModel
) => {
  if (!weatherPoint) {
    return;
  }

  let nodeIds = weatherPoint.nodeIds ?? [];

  if (!nodeIds.includes(nodeId)) {
    nodeIds = [...nodeIds, nodeId];
  }

  const newPoint = {
    ...weatherPoint,
    nodeIds
  };

  await API.Admin.setMeteorologyPoint(contextSettings, newPoint);
};

const _removeWeather = async (
  contextSettings: ApiContextSettings,
  nodeId: string,
  weatherPoints: any
) => {
  for (const weatherPoint of weatherPoints) {
    if (
      weatherPoint &&
      weatherPoint.nodeIds != null &&
      weatherPoint.nodeIds.includes(nodeId)
    ) {
      const nodeIds = weatherPoint.nodeIds.filter(
        (id: string) => id !== nodeId
      );
      const newPoint = {
        ...weatherPoint,
        nodeIds
      };
      await API.Admin.setMeteorologyPoint(contextSettings, newPoint);
    }
  }
};

const _updateWeatherPoint = async (
  contextSettings: ApiContextSettings,
  nodeId: string,
  previousWeatherPoints: any,
  newWeatherPoint: any
) => {
  try {
    await _removeWeather(contextSettings, nodeId, previousWeatherPoints);
    await _addWeather(contextSettings, nodeId, newWeatherPoint);
    return true;
  } catch (e) {
    return false;
  }
};

const API = {
  Enums: {
    getEnums: (contextSettings: ApiContextSettings) =>
      JSONFetch<GetEnumsAndFixedConfigurationsResponseModel>(
        contextSettings,
        '/enums'
      )
  },
  Admin: {
    Alarms: {
      getAlarmSignalTemplates: (contextSettings: ApiContextSettings) =>
        JSONFetch<AdminAlarmSignalGroupTemplateResponseModel[]>(
          contextSettings,
          '/admin/alarmSignalTemplates'
        )
    },
    Templates: {
      updateEquipmentType: (
        contextSettings: ApiContextSettings,
        equipmentType: AddOrUpdateEquipmentTypeRequestModel
      ) =>
        JSONPut<EquipmentTypeResponseModel[]>(
          contextSettings,
          '/admin/equipmentTypes',
          [equipmentType]
        ),
      updateEquipment: (
        contextSettings: ApiContextSettings,
        template: AddOrUpdateEquipmentSignalProviderTemplateRequestModel
      ) =>
        JSONPut<EquipmentSignalProviderTemplateResponseModel[]>(
          contextSettings,
          '/admin/equipmentTemplates',
          [template]
        ),
      deleteEquipment: (
        contextSettings: ApiContextSettings,
        equipmentTypeIds: DeleteEquipmentSignalProviderTemplatesRequestModel
      ) =>
        JSONDeleteBody<void>(
          contextSettings,
          '/admin/equipmentTemplates',
          equipmentTypeIds
        ),
      updateBuilding: (
        contextSettings: ApiContextSettings,
        template: AddOrUpdateBuildingTemplateRequestModel
      ) =>
        JSONPut<BuildingTemplateResponseModel[]>(
          contextSettings,
          '/admin/buildingTemplates',
          [template]
        ),
      getBuildings: (contextSettings: ApiContextSettings) =>
        JSONFetch<BuildingTemplateResponseModel[]>(
          contextSettings,
          '/admin/buildingTemplates'
        ),
      deleteBuilding: (
        contextSettings: ApiContextSettings,
        equipmentTemplateGroupIds: string[]
      ) =>
        JSONDelete<void>(contextSettings, '/admin/buildingTemplates', {
          equipmentTemplateGroupIds
        }),
      deleteAlarm: (
        contextSettings: ApiContextSettings,
        alarmSignalGroupTemplateIds: string[]
      ) =>
        JSONDeleteBody<void>(contextSettings, '/admin/alarmSignalTemplates', {
          alarmSignalGroupTemplateIds
        }),
      updateAlarmTemplate: (
        contextSettings: ApiContextSettings,
        template: AddOrUpdateAlarmSignalGroupTemplateRequestModel
      ) =>
        JSONPut<AdminAlarmSignalGroupTemplateResponseModel>(
          contextSettings,
          '/admin/alarmSignalTemplates',
          template
        ),
      getAlarmTemplates: (contextSettings: ApiContextSettings) =>
        JSONFetch<AdminAlarmSignalGroupTemplateResponseModel[]>(
          contextSettings,
          '/admin/alarmSignalTemplates'
        ),
      getAlarmTemplateByAlarmSignalGroupTemplateId: (
        contextSettings: ApiContextSettings,
        alarmSignalGroupTemplateId: string
      ) =>
        JSONFetch<AdminAlarmSignalGroupTemplateResponseModel>(
          contextSettings,
          '/admin/alarmSignalTemplates/' + alarmSignalGroupTemplateId
        ),
      getEquipmentByEquipmentTypeId: (
        contextSettings: ApiContextSettings,
        equipmentTypeId: string
      ) =>
        JSONFetch<EquipmentSignalProviderTemplateResponseModel[]>(
          contextSettings,
          '/admin/equipmentTemplates?' +
            queryString.stringify({ equipmentTypeId })
        )
    },
    Nodes: {
      getAllNodes: (contextSettings: ApiContextSettings) =>
        JSONFetch<NodeResponseModel[]>(contextSettings, '/admin/nodes'),
      initNodeWithTemplateInstructions: (
        contextSettings: ApiContextSettings,
        instructions: CreateBuildingByTemplateDataResponseModel[]
      ) =>
        JSONPut<CreateBuildingByTemplateDataResponseModel[]>(
          contextSettings,
          '/admin/buildings',
          instructions
        ),
      /**
       * Requests a building/site template to be used for creating a new building/site
       * @param template
       * @returns {*} a template that can be updated with new values and then be used with createBuilding(*)
       */
      getInitNodeWithTemplateInstructions: (
        contextSettings: ApiContextSettings,
        template: AddOrUpdateBuildingsByTemplatesRequestModel
      ) =>
        JSONPost<CreateBuildingByTemplateDataResponseModel[]>(
          contextSettings,
          '/admin/buildings',
          [template]
        ),
      createBuilding: (
        contextSettings: ApiContextSettings,
        templates: CreateBuildingByTemplateDataResponseModel[]
      ) =>
        JSONPut<CreateBuildingByTemplateDataResponseModel[]>(
          contextSettings,
          '/admin/buildings',
          templates
        ),
      getNodeTags: (contextSettings: ApiContextSettings) =>
        JSONFetch<string[]>(contextSettings, '/admin/nodes/tags'),
      deleteNode: (contextSettings: ApiContextSettings, nodeId: string) =>
        JSONDeleteBody<void>(contextSettings, '/admin/nodes', { nodeId }),
      getNodes: (contextSettings: ApiContextSettings, nodeId: string) =>
        JSONFetch<NodeResponseModel[]>(
          contextSettings,
          '/admin/nodes?' + queryString.stringify({ nodeId })
        ),
      getBuildings: (
        contextSettings: ApiContextSettings,
        {
          buildingStatuses = [],
          page = 0,
          pageSize = 100,
          searchPhrase = '',
          sortColumn,
          sortOrder = SortOrderType.DESC,
          tags = [],
          buildingStatusTimestamp = null,
          buildingStatusTimestampComparisonType = null
        }: GetBuildingsByFiltersRequestModel
      ) =>
        JSONPost<BuildingListResponseModel>(
          contextSettings,
          '/admin/buildings/search',
          {
            buildingStatuses,
            page,
            pageSize,
            searchPhrase,
            sortColumn,
            sortOrder,
            tags,
            buildingStatusTimestamp,
            buildingStatusTimestampComparisonType
          }
        ),
      exportBuildings: (
        contextSettings: ApiContextSettings,
        {
          parentNodeId = '',
          buildingStatuses = [],
          tags = [],
          buildingStatusTimestamp = null,
          buildingStatusTimestampComparisonType = null,
          searchPhrase = '',
          sortColumn = '',
          sortOrder = ''
        }: GetBuildingsExportRequestModel
      ) =>
        JSONPostUnparsedReturn(contextSettings, '/admin/buildings/export', {
          parentNodeId,
          buildingStatuses,
          tags,
          buildingStatusTimestamp,
          buildingStatusTimestampComparisonType,
          searchPhrase,
          sortColumn,
          sortOrder
        })
    },
    Comfort: {
      getParameters: (
        contextSettings: ApiContextSettings,
        tools: AddOrUpdateComfortHeatingByTemplateRequestModel[]
      ) =>
        JSONPost<CreateComfortHeatingByTemplateResponseModel[]>(
          contextSettings,
          '/admin/comfort/providers',
          tools
        ),
      saveTool: (
        contextSettings: ApiContextSettings,
        parameters: CreateComfortHeatingByTemplateResponseModel[]
      ) =>
        JSONPut<CreateComfortHeatingByTemplateResponseModel[]>(
          contextSettings,
          '/admin/comfort/providers',
          parameters
        ),
      getTool: (contextSettings: ApiContextSettings, nodeId: string) =>
        JSONPost<ComfortHeatingProviderResponseModel[]>(
          contextSettings,
          '/admin/comfort/providers/byNodeId',
          { nodeIds: [nodeId] }
        ),
      getToolsByProviderIds: (
        contextSettings: ApiContextSettings,
        providerIds: string[]
      ) =>
        JSONPost<ComfortHeatingProviderResponseModel[]>(
          contextSettings,
          '/admin/comfort/providers/byProviderId',
          { providerIds }
        ),
      deleteTool: (contextSettings: ApiContextSettings, id: string) =>
        JSONDeleteBody<void>(contextSettings, '/admin/comfort/providers', {
          providerIds: [id]
        })
    },
    Equipments: {
      getEquipmentTypes: (contextSettings: ApiContextSettings) =>
        JSONFetch<EquipmentTypeResponseModel[]>(
          contextSettings,
          '/admin/equipmentTypes'
        ),
      getEquipmentTemplates: (contextSettings: ApiContextSettings) =>
        JSONFetch<EquipmentSignalProviderTemplateResponseModel[]>(
          contextSettings,
          '/admin/equipmentTemplates'
        ),
      createEnergyManager: (
        contextSettings: ApiContextSettings,
        body: AddOrUpdateEnergyManagerByEquipmentTypeRequestModel
      ) =>
        JSONPut<EquipmentResponseModel>(
          contextSettings,
          '/admin/equipments/energyManagers',
          body
        ),
      createEquipments: (
        contextSettings: ApiContextSettings,
        equipments: AddOrUpdateEquipmentByEquipmentTypeRequestModel[]
      ) =>
        JSONPost<EquipmentResponseModel[]>(
          contextSettings,
          '/admin/equipments/byEquipmentType',
          equipments
        ),
      updateEquipment: (
        contextSettings: ApiContextSettings,
        equipmentId: string,
        name: string,
        description: string,
        equipmentTypeId: string
      ) =>
        JSONPut<void>(contextSettings, '/admin/equipments', [
          { name, description, equipmentId, equipmentTypeId }
        ])
    },
    DeviceFiles: {
      getDeviceConfigFile: (
        contextSettings: ApiContextSettings,
        deviceId: string
      ) =>
        JSONQuery<object>(
          contextSettings,
          '/admin/deviceFiles/deviceConfigFile',
          { deviceId }
        ),
      getToolsConfigFile: (
        contextSettings: ApiContextSettings,
        deviceId: string
      ) =>
        JSONQuery<object>(
          contextSettings,
          '/admin/deviceFiles/toolsConfigFile',
          { deviceId }
        ),
      getEquipmentConfigFile: (
        contextSettings: ApiContextSettings,
        deviceId: string
      ) =>
        JSONQuery<SimplifiedEquipmentResponseModel[]>(
          contextSettings,
          '/admin/deviceFiles/equipmentsConfigFile',
          { deviceId }
        ),
      getSignalsConfigFile: (
        contextSettings: ApiContextSettings,
        deviceId: string,
        verbose = false
      ) =>
        JSONQuery<object[]>(
          contextSettings,
          '/admin/deviceFiles/signalsConfigFile',
          { deviceId, verbose }
        )
    },
    Devices: {
      deployDeviceConfigs: (
        contextSettings: ApiContextSettings,
        deviceIds: string[]
      ) =>
        JSONPut<DeployDeviceConfigsResponseModel>(
          contextSettings,
          '/admin/deployDeviceConfigs',
          { deviceIds }
        ),
      getDeviceStatusByDeviceIds: (
        contextSettings: ApiContextSettings,
        deviceIds: string[]
      ) =>
        JSONPost<DeviceStatusResponseModel[]>(
          contextSettings,
          '/admin/deviceStatus/byDeviceIds',
          { deviceIds }
        ),
      getConnectionsByDeviceIds: (
        contextSettings: ApiContextSettings,
        deviceIds: string[]
      ) =>
        JSONPost<ConnectionResponseModel[]>(
          contextSettings,
          '/admin/devices/connections/byDeviceIds',
          { deviceIds }
        ),
      getDeviceInfo: (
        contextSettings: ApiContextSettings,
        equipmentId: string
      ) =>
        JSONFetch<DeviceInfoResponseModel>(
          contextSettings,
          '/admin/deviceInfo?' + queryString.stringify({ equipmentId })
        ),
      getDeviceInfoByEquipmentIds: (
        contextSettings: ApiContextSettings,
        equipmentIds: string[]
      ) =>
        JSONPost<DeviceInfoResponseModel[]>(
          contextSettings,
          '/admin/deviceInfo/byEquipmentIds',
          { equipmentIds }
        ),
      getSuggestedSlaveId: (
        contextSettings: ApiContextSettings,
        equipmentId: string
      ) =>
        JSONFetch<SlaveIdResponseModel>(
          contextSettings,
          '/admin/devices/suggestedSlaveId?' +
            queryString.stringify({ equipmentId })
        ),
      /**
       * Update connection data for a specific connection id
       * @param connection  {id, name, connectionModbusConfig}
       * @returns {id, name}
       */
      setConnection: (
        contextSettings: ApiContextSettings,
        connection: AddOrUpdateConnectionRequestModel
      ) =>
        JSONPut<ConnectionResponseModel[]>(
          contextSettings,
          '/admin/devices/connections',
          [connection]
        ),
      /**
       * Get connection data for a set of connection ids
       * @param ConnectionIds Array of connection ids (string)
       * @returns Array({id, connectionModbusConfig, name})
       */
      getConnections: (
        contextSettings: ApiContextSettings,
        ConnectionIds: string[]
      ) =>
        JSONQuery<ConnectionResponseModel[]>(
          contextSettings,
          '/admin/devices/connections',
          { ConnectionIds }
        ),
      syncDeviceTwin: (contextSettings: ApiContextSettings, id: string) =>
        JSONPost<SyncDeviceTwinResponseModel[]>(
          contextSettings,
          '/admin/iotdevices/syncDeviceTwin',
          { ids: [id] }
        )
    },
    Maps: {
      addMaps: (
        contextSettings: ApiContextSettings,
        mapping: AddOrUpdateMappingRequestModel
      ) =>
        JSONPut<MappingResponseModel[]>(contextSettings, '/admin/maps', [
          mapping
        ]),
      getMapsForNodeId: (contextSettings: ApiContextSettings, nodeId: string) =>
        JSONPost<MappingResponseModel[]>(contextSettings, '/admin/maps', {
          nodeIds: [nodeId],
          signalIds: [],
          ids: [],
          signalProviderIds: [],
          signalProviderTypes: [],
          onlyInternalMappings: false
        }),
      getMaps: (contextSettings: ApiContextSettings) =>
        JSONFetch<MappingResponseModel[]>(contextSettings, '/admin/maps/all'),
      deleteMaps: (
        contextSettings: ApiContextSettings,
        mapping: { id: string }
      ) =>
        JSONDeleteBody<void>(contextSettings, '/admin/maps', [
          { id: mapping.id }
        ])
    },
    Signals: {
      getEquipmentSignals: (
        contextSettings: ApiContextSettings,
        equipmentIds: string[]
      ) =>
        GetJSONArrayFromAPI(
          contextSettings,
          JSONFetch,
          '/admin/equipmentSignals',
          equipmentIds,
          'EquipmentIds'
        ),
      getAlarmSignals: (
        contextSettings: ApiContextSettings,
        equipmentIds: string[]
      ) =>
        GetJSONArrayFromAPI(
          contextSettings,
          JSONFetch,
          '/admin/alarmSignals',
          equipmentIds,
          'EquipmentIds'
        ),
      addOrUpdateEquipmentSignals: (
        contextSettings: ApiContextSettings,
        signals: AddOrUpdateEquipmentSignalsRequestModel[]
      ) =>
        JSONPut<AdminEquipmentSignalResponseModel[]>(
          contextSettings,
          '/admin/equipmentSignals',
          signals
        ),
      addOrUpdateAlarmSignals: (
        contextSettings: ApiContextSettings,
        signals: AddOrUpdateAlarmSignalsRequestModel[]
      ) =>
        JSONPut<AdminEquipmentSignalResponseModel[]>(
          contextSettings,
          '/admin/alarmSignals',
          signals
        ),
      getSignalsForNodeId: (
        contextSettings: ApiContextSettings,
        nodeId: string
      ) =>
        JSONPost<SignalProviderByNodeResponseModel[]>(
          contextSettings,
          '/admin/signals/nodes',
          { nodesIds: [nodeId] }
        ),
      getSignalsForNodeIds: (
        contextSettings: ApiContextSettings,
        nodesIds: string[]
      ) =>
        JSONPost<SignalProviderByNodeResponseModel[]>(
          contextSettings,
          '/admin/signals/nodes',
          { nodesIds }
        ),
      getSignalsForMapId: (
        contextSettings: ApiContextSettings,
        mapId: string
      ) =>
        JSONPost<SignalProviderByNodeResponseModel[]>(
          contextSettings,
          '/admin/signals/maps',
          { mapIds: [mapId] }
        ),
      getProvidersForNodeIds: (
        contextSettings: ApiContextSettings,
        nodesIds: string[],
        signalProviderTypes: SignalProviderType[] = [
          SignalProviderType.Equipment
        ]
      ) =>
        JSONPost<SignalProviderByNodeResponseModel[]>(
          contextSettings,
          '/admin/signals/nodes',
          { nodesIds, signalProviderTypes }
        ),
      getProvidersBySignalIds: (
        contextSettings: ApiContextSettings,
        signalIds: string[]
      ) =>
        JSONPost<FullSignalProviderResponseModel[]>(
          contextSettings,
          '/signals/providers/bySignalIds',
          { signalIds }
        )
    },
    SignalCategories: {
      getSignalCategories: (contextSettings: ApiContextSettings) =>
        JSONPost<SignalCategoryResponseModel[]>(
          contextSettings,
          '/admin/signalCategories/all',
          {}
        )
    },
    SignalTypes: {
      getAllSignalTypes: (contextSettings: ApiContextSettings) =>
        JSONPost<SignalTypeResponseModel[]>(
          contextSettings,
          '/admin/signalTypes/all'
        ),
      saveSignalTypes: (
        contextSettings: ApiContextSettings,
        types: AddOrUpdateSignalTypeRequestModel[]
      ) => JSONPut<void>(contextSettings, '/admin/signalTypes', types),
      deleteSignalTypes: (
        contextSettings: ApiContextSettings,
        req: DeleteSignalTypesRequestModel
      ) => JSONDeleteBody<void>(contextSettings, '/admin/signalTypes', req)
    },
    SignalTypeFolders: {
      getAllSignalTypeFolders: (contextSettings: ApiContextSettings) =>
        JSONPost<SignalTypeFolderResponseModel[]>(
          contextSettings,
          '/admin/signalTypeFolders/all'
        ),
      saveSignalTypeFolders: (
        contextSettings: ApiContextSettings,
        folders: AddOrUpdateSignalTypeFolderRequestModel[]
      ) => JSONPut<void>(contextSettings, '/admin/signalTypeFolders', folders),
      deleteSignalTypeFolders: (
        contextSettings: ApiContextSettings,
        folderIds: DeleteSignalTypeFoldersRequestModel
      ) =>
        JSONDeleteBody<void>(
          contextSettings,
          '/admin/signalTypeFolders',
          folderIds
        )
    },
    IoTDevices: {
      requestHeartbeat: (contextSettings: ApiContextSettings, id: string) =>
        JSONPost<DirectHeartbeatResponseModel[]>(
          contextSettings,
          '/admin/iotdevices/heartbeat',
          { ids: [id] }
        ),
      pingDevice: (
        contextSettings: ApiContextSettings,
        id: string,
        ipAddressOrHostName: string
      ) =>
        JSONPost<PingDevicesResponseModel>(
          contextSettings,
          '/admin/iotdevices/ping',
          { pingRequests: [{ id, ipAddressOrHostName }] }
        ),
      restartService: (
        contextSettings: ApiContextSettings,
        id: string,
        service: string
      ) =>
        JSONPost<RestartModulesResponseModel>(
          contextSettings,
          '/admin/iotdevices/restartModules',
          { restartModuleRequests: [{ id, moduleName: service }] }
        ),
      rebootDevice: (contextSettings: ApiContextSettings, id: string) =>
        JSONPost<RebootDeviceResponseModel>(
          contextSettings,
          '/admin/iotdevices/reboot',
          { id }
        ),
      updateDevice: (
        contextSettings: ApiContextSettings,
        device: AddOrUpdateIoTDeviceRequestModel
      ) =>
        emptyResponseUpdateFetch(
          contextSettings,
          '/admin/iotdevices',
          [device],
          'PUT'
        ),
      switchDevices: (
        contextSettings: ApiContextSettings,
        oldIoTDeviceId: string,
        newIoTDeviceId: string
      ) =>
        emptyResponseUpdateFetch(
          contextSettings,
          '/admin/iotdevices/switch',
          [{ oldIoTDeviceId, newIoTDeviceId }],
          'PUT'
        ),
      getCurrentModuleInfo: (contextSettings: ApiContextSettings) =>
        JSONFetch<ModuleResponseModel[]>(
          contextSettings,
          '/admin/iotdevices/modules'
        ),
      getIoTDeviceByDeviceIds: (
        contextSettings: ApiContextSettings,
        deviceIds: string[]
      ) =>
        JSONFetch<IoTDeviceViewResponseModel[]>(
          contextSettings,
          '/admin/iotdevices/view/byDeviceId?' +
            queryString.stringify({ deviceIds })
        ),
      getIoTDeviceByIoTDeviceId: (
        contextSettings: ApiContextSettings,
        deviceId: string
      ) =>
        JSONFetch<IoTDeviceViewResponseModel[]>(
          contextSettings,
          '/admin/iotdevices/view/byId?' +
            queryString.stringify({ ids: [deviceId] })
        ),
      getDeviceTags: (contextSettings: ApiContextSettings) => {
        return JSONFetch<string[]>(
          contextSettings,
          '/admin/iotdevices/deviceTags'
        );
      },
      search: (
        contextSettings: ApiContextSettings,
        searchPhrase: string,
        queryType: string,
        statusQueryType: string,
        filteredTags: string[],
        pageSize: number,
        page: number
      ) => {
        const search = {
          page,
          searchPhrase: searchPhrase === '' ? undefined : searchPhrase,
          sortColumn: 'deviceName',
          queryType,
          statusQueryType,
          filteredTags,
          pageSize
        };

        return JSONFetch<IoTDevicesSearchResponseModel>(
          contextSettings,
          '/admin/iotdevices/view/search?' + queryString.stringify(search)
        );
      },
      /**
       * Returns hardware settings for ioTDeviceId
       * @param ioTDeviceId
       * @returns {Promise<HardwareSettingsPropType>}
       */
      getSettings: (contextSettings: ApiContextSettings, ioTDeviceId: string) =>
        JSONQuery<IoTDeviceSettingsResponseModel[]>(
          contextSettings,
          '/admin/iotdevices/settings',
          { IoTDeviceIds: [ioTDeviceId] }
        ),
      /**
       * Update setting on server.
       * @param setting
       * @returns {Promise<HardwareSettingsPropType>}
       */
      updateSettings: (
        contextSettings: ApiContextSettings,
        setting: AddOrUpdateIoTDeviceSettingsRequestModel
      ) =>
        JSONPut<IoTDeviceSettingsResponseModel[]>(
          contextSettings,
          '/admin/iotdevices/settings',
          [setting]
        )
    },
    Notifications: {
      getSignalTypeIds: (contextSettings: ApiContextSettings, nodeId: string) =>
        JSONQuery<GetAlarmSignalTypeIdsForNodesResponseModel>(
          contextSettings,
          '/admin/alarmNotifications/signalTypeIds',
          { nodeIds: [nodeId] }
        ),
      addNotification: (
        contextSettings: ApiContextSettings,
        notification: AlarmNotificationRequestModel
      ) =>
        JSONPut<AddOrUpdateAlarmNotificationsResponseModel>(
          contextSettings,
          '/admin/alarmNotifications',
          { alarmNotifications: [notification] }
        ),
      deleteNotifications: (
        contextSettings: ApiContextSettings,
        ids: string[]
      ) =>
        JSONDeleteBody<void>(contextSettings, '/admin/alarmNotifications', {
          ids
        }),
      allNotifications: (contextSettings: ApiContextSettings) =>
        JSONFetch<GetAlarmNotificationsResponseModel>(
          contextSettings,
          '/admin/alarmNotifications/all'
        ),
      notificationForNodeIds: (
        contextSettings: ApiContextSettings,
        nodeIds: string[]
      ) =>
        JSONQuery<GetAlarmNotificationsResponseModel>(
          contextSettings,
          '/admin/alarmNotifications/byNodeId',
          { nodeIds }
        )
    },
    TemperatureImpact: {
      getProviderSignals: (
        contextSettings: ApiContextSettings,
        nodeId: string
      ) =>
        JSONPost<TemperatureImpactProviderSignalsResponseModel[]>(
          contextSettings,
          '/admin/temperatureImpact/providerSignals',
          { nodeIds: [nodeId] }
        ),
      addTemperatureImpact: (
        contextSettings: ApiContextSettings,
        params: AddOrUpdateTemperatureImpactProviderRequestModel
      ) =>
        JSONPut<void>(contextSettings, '/admin/temperatureImpact/providers', [
          params
        ])
    },
    ProcessMaps: {
      getAllProcessMaps: (contextSettings: ApiContextSettings) =>
        JSONPost<ProcessMapResponseModel[]>(
          contextSettings,
          '/admin/processMaps/all'
        ),
      getProcessMapById: (contextSettings: ApiContextSettings, id: string) =>
        JSONPost<ProcessMapResponseModel[]>(
          contextSettings,
          '/admin/processMaps/byId',
          { ids: [id] }
        ),
      createProcessMap: (
        contextSettings: ApiContextSettings,
        args: AddOrUpdateProcessMapRequestModel
      ) =>
        JSONPut<ProcessMapResponseModel[]>(
          contextSettings,
          '/admin/processMaps',
          [args]
        ),
      updateProcessMap: (
        contextSettings: ApiContextSettings,
        args: AddOrUpdateProcessMapRequestModel
      ) =>
        JSONPut<ProcessMapResponseModel[]>(
          contextSettings,
          '/admin/processMaps',
          [args]
        ),
      getProcessMapRevisionsByProcessMapIds: (
        contextSettings: ApiContextSettings,
        args: GetProcessMapRevisionsByProcessMapIdRequestModel
      ) =>
        JSONPost<ProcessMapRevisionResponseModel[]>(
          contextSettings,
          '/admin/processMaps/revisions/byProcessMapId',
          args
        ),
      createProcessMapRevision: (
        contextSettings: ApiContextSettings,
        args: AddOrUpdateProcessMapRevisionRequestModel
      ) =>
        JSONPut<ProcessMapRevisionResponseModel[]>(
          contextSettings,
          '/admin/processMaps/revisions',
          [args]
        ),
      deleteProcessMaps: (contextSettings: ApiContextSettings, ids: string[]) =>
        JSONDeleteBody<void>(contextSettings, '/admin/processMaps', { ids }),
      getProcessMapByEquipmentTypeId: (
        contextSettings: ApiContextSettings,
        equipmentTypeId: string
      ) =>
        JSONPost<EquipmentTypeProcessMapRelationshipResponseModel[]>(
          contextSettings,
          '/admin/processMaps/equipmentTypeRelationships',
          { equipmentTypeIds: [equipmentTypeId] }
        ),
      updateEquipmentTypeProcessMapRelation: (
        contextSettings: ApiContextSettings,
        equipmentTypeId: string,
        processMapId: string
      ) =>
        JSONPut<EquipmentTypeProcessMapRelationshipResponseModel[]>(
          contextSettings,
          '/admin/processMaps/equipmentTypeRelationships',
          [{ equipmentTypeId, processMapId }]
        ),
      deleteEquipmentTypeProcessMapRelation: (
        contextSettings: ApiContextSettings,
        equipmentTypeId: string
      ) =>
        JSONDeleteBody<void>(
          contextSettings,
          '/admin/processMaps/equipmentTypeRelationships',
          { equipmentTypeIds: [equipmentTypeId] }
        ),
      getProcessMapByNodeId: (
        contextSettings: ApiContextSettings,
        nodeId: string
      ) =>
        JSONPost<NodeProcessMapRelationshipResponseModel[]>(
          contextSettings,
          '/admin/processMaps/nodeRelationships',
          { nodeIds: [nodeId] }
        ),
      updateNodeProcessMapRelation: (
        contextSettings: ApiContextSettings,
        nodeId: string,
        processMapId: string
      ) =>
        JSONPut<NodeProcessMapRelationshipResponseModel[]>(
          contextSettings,
          '/admin/processMaps/nodeRelationships',
          [{ nodeId, processMapId }]
        ),
      deleteNodeProcessMapRelation: (
        contextSettings: ApiContextSettings,
        nodeId: string
      ) =>
        JSONDeleteBody<void>(
          contextSettings,
          '/admin/processMaps/nodeRelationships',
          { nodeIds: [nodeId] }
        )
    },
    Dashboard: {
      saveDashboardFile: (
        contextSettings: ApiContextSettings,
        body: AddOrUpdateDashboardFileRequestModel
      ) => JSONPut<void>(contextSettings, '/admin/dashboards/files', [body]),
      getDashboardFile: (
        contextSettings: ApiContextSettings,
        dashboardId: string
      ) =>
        JSONPost<DashboardFileResponseModel[]>(
          contextSettings,
          '/admin/dashboards/files',
          [{ dashboardId }]
        ),
      getDashboards: (
        contextSettings: ApiContextSettings,
        args: GetPagedDashboardsRequestModel
      ) =>
        JSONPost<PagedDashboardsResponseModel>(
          contextSettings,
          '/admin/dashboards',
          args
        ),
      getDashboardByIds: (
        contextSettings: ApiContextSettings,
        dashboardIds: string[]
      ) =>
        JSONPost<DashboardResponseModel[]>(
          contextSettings,
          '/admin/dashboards/byIds',
          { dashboardIds }
        ),
      addsOrUpdatesDashboard: (
        contextSettings: ApiContextSettings,
        { dashboardId, name, description }: AddOrUpdateDashboardRequestModel
      ) =>
        JSONPut<void>(contextSettings, '/admin/dashboards', [
          { dashboardId, name, description }
        ]),
      addsOrUpdatesDashboards: (
        contextSettings: ApiContextSettings,
        dashboards: AddOrUpdateDashboardRequestModel[]
      ) => JSONPut<void>(contextSettings, '/admin/dashboards', dashboards),
      removeDashboards: (
        contextSettings: ApiContextSettings,
        args: DeleteDashboardsRequestModel
      ) => JSONDeleteBody<void>(contextSettings, '/admin/dashboards', args),
      getCollections: (
        contextSettings: ApiContextSettings,
        { page, pageSize }: { page: number; pageSize: number }
      ) =>
        JSONPost<PagedDashboardCollectionResponseModel>(
          contextSettings,
          '/admin/dashboards/collections',
          { page, pageSize }
        ),
      getDefaultId: (contextSettings: ApiContextSettings) =>
        JSONPost<DashboardCollectionIdResponseModel>(
          contextSettings,
          '/admin/dashboards/collections/defaultId',
          {}
        ),
      setDefaultId: (
        contextSettings: ApiContextSettings,
        args: SetDefaultDashboardCollectionIdRequestModel
      ) =>
        JSONPut<void>(
          contextSettings,
          '/admin/dashboards/collections/defaultId',
          args
        ),
      updateNodeDashboardCollectionRelation: (
        contextSettings: ApiContextSettings,
        {
          nodeId,
          dashboardCollectionId
        }: { nodeId: string; dashboardCollectionId: string }
      ) =>
        dashboardCollectionId != null
          ? JSONPut(
              contextSettings,
              '/admin/dashboards/collections/relations/nodes',
              [{ nodeId, dashboardCollectionId }]
            )
          : JSONDeleteBody(
              contextSettings,
              '/admin/dashboards/collections/relations/nodes',
              { nodeIds: [nodeId] }
            ),
      updateBuildingTemplateDashboardCollectionRelation: (
        contextSettings: ApiContextSettings,
        {
          buildingTemplateId,
          dashboardCollectionId
        }: { buildingTemplateId: string; dashboardCollectionId: string }
      ) =>
        dashboardCollectionId != null
          ? JSONPut(
              contextSettings,
              '/admin/dashboards/collections/relations/buildingTemplates',
              [{ buildingTemplateId, dashboardCollectionId }]
            )
          : JSONDeleteBody(
              contextSettings,
              '/admin/dashboards/collections/relations/buildingTemplates',
              { buildingTemplateIds: [buildingTemplateId] }
            ),
      getCollectionById: (
        contextSettings: ApiContextSettings,
        {
          dashboardCollectionIds = [],
          nodeIds = [],
          buildingTemplateIds = [],
          dashboardIds = []
        }: GetDashboardCollectionsRequestModel
      ) =>
        JSONPost<DashboardCollectionResponseModel[]>(
          contextSettings,
          '/admin/dashboards/collections/byIds',
          { dashboardCollectionIds, nodeIds, buildingTemplateIds, dashboardIds }
        ),
      addsOrUpdatesDashboardCollection: (
        contextSettings: ApiContextSettings,
        {
          dashboardCollectionId,
          name,
          description,
          defaultDashboardId,
          dashboardIds
        }: AddOrUpdateDashboardCollectionRequestModel
      ) =>
        JSONPut<void>(contextSettings, '/admin/dashboards/collections', [
          {
            dashboardCollectionId,
            name,
            description,
            defaultDashboardId,
            dashboardIds
          }
        ]),
      removeDashboardCollections: (
        contextSettings: ApiContextSettings,
        dashboardCollectionIds: string[]
      ) =>
        JSONDeleteBody<void>(contextSettings, '/admin/dashboards/collections', {
          dashboardCollectionIds
        })
    },
    PowerDeltaControl: {
      getProvidersByNodeIds: (
        contextSettings: ApiContextSettings,
        nodeIds: string[]
      ) =>
        JSONPost<PowerDeltaControlProviderResponseModel[]>(
          contextSettings,
          '/admin/powerdeltacontrol/providers/byNodeId',
          { nodeIds }
        ),
      addOrUpdateProviders: (
        contextSettings: ApiContextSettings,
        providers: AddOrUpdatePowerDeltaControlProviderRequestModel[]
      ) =>
        JSONPut<void>(
          contextSettings,
          '/admin/powerdeltacontrol/providers',
          providers
        ),
      removeProviders: (
        contextSettings: ApiContextSettings,
        providerIds: string[]
      ) =>
        JSONDeleteBody<void>(
          contextSettings,
          '/admin/powerdeltacontrol/providers',
          { providerIds }
        )
    },
    ElectricityPeakShaving: {
      getProvidersByNodeIds: (
        contextSettings: ApiContextSettings,
        nodeIds: string[]
      ) =>
        JSONPost<LoBaBatteryChargingThresholdProviderResponseModel[]>(
          contextSettings,
          '/admin/loBaBatteryChargingThreshold/providers/byNodeId',
          { nodeIds }
        ),
      addOrUpdateProviders: (
        contextSettings: ApiContextSettings,
        providers: AddOrUpdateLoBaBatteryChargingThresholdProviderRequestModel[]
      ) =>
        JSONPut<void>(
          contextSettings,
          '/admin/loBaBatteryChargingThreshold/providers',
          providers
        ),
      removeProviders: (
        contextSettings: ApiContextSettings,
        providerIds: string[]
      ) =>
        JSONDeleteBody<void>(
          contextSettings,
          '/admin/loBaBatteryChargingThreshold/providers',
          { providerIds }
        )
    },
    createLocation: (
      contextSettings: ApiContextSettings,
      location: AddOrUpdateNodeRequestModel
    ) =>
      JSONPut<AdminNodeResponseModel>(
        contextSettings,
        '/admin/nodes',
        location
      ),
    updateLocation: (
      contextSettings: ApiContextSettings,
      location: AddOrUpdateNodeRequestModel
    ) =>
      JSONPut<AdminNodeResponseModel>(
        contextSettings,
        '/admin/nodes',
        location
      ),
    setMeteorologyPoint: (
      contextSettings: ApiContextSettings,
      point: AddGeographicalPointRequestModel
    ) =>
      JSONPut<GeographicalPointResponseModel>(
        contextSettings,
        '/meteorology/point',
        point
      ),
    addNodeToMeteorologyPoint: _addWeather,
    removeNodeFromMeteorologyPoint: _removeWeather,
    moveNodeFromMeteorologyPoint: _updateWeatherPoint
  },
  Devices: {
    setLiveTelemetry: (
      contextSettings: ApiContextSettings,
      equipmentId: string
    ) =>
      emptyResponseUpdateFetch(
        contextSettings,
        '/devices/device/message/livetelemetry',
        { equipmentId },
        'POST'
      ),
    setSignal: (
      contextSettings: ApiContextSettings,
      equipmentSignalId: string,
      value: number
    ) =>
      JSONPost<SetSignalResponseModel>(
        contextSettings,
        '/devices/device/message/setsignal',
        { equipmentSignalId, value }
      ),
    setSignals: (
      contextSettings: ApiContextSettings,
      signalValues: SetSignalRequestModel[]
    ) =>
      JSONPost<SetSignalResponseModel[]>(
        contextSettings,
        '/devices/device/message/setsignals',
        { signalValues }
      )
  },
  Signals: {
    getProvidersForNodeIds: (
      contextSettings: ApiContextSettings,
      nodesIds: string[],
      signalProviderTypes: SignalProviderType[] = [SignalProviderType.Equipment]
    ) =>
      JSONPost<SignalProviderByNodeResponseModel[]>(
        contextSettings,
        '/signals/nodes',
        { nodesIds, signalProviderTypes }
      ),
    getSignalValues: (
      contextSettings: ApiContextSettings,
      signalIds: string[],
      startDate: Date | Moment,
      endDate: Date | Moment,
      points = 500
    ) =>
      GetJSONArrayFromAPI<SignalProviderTelemetryResponseModel[]>(
        contextSettings,
        JSONFetch,
        signalsPathPrefix() + '/values',
        signalIds,
        'signalIds',
        20,
        {
          startDate: startDate && startDate.toISOString(),
          endDate: endDate && endDate.toISOString(),
          points: Math.ceil(points)
        }
      ),
    getLastValue: (
      contextSettings: ApiContextSettings,
      signalIds: string[],
      endDate: RequestDate = null
    ) =>
      JSONPost<SignalProviderTelemetryResponseModel[]>(
        contextSettings,
        signalsPathPrefix() + '/values/last',
        { signalIds, endDate: endDate && endDate.toISOString() }
      ),
    deleteSignals: (contextSettings: ApiContextSettings, signalIds: string[]) =>
      JSONDeleteBody<void>(contextSettings, '/signals', { signalIds }),
    exportData: (
      contextSettings: ApiContextSettings,
      data: AddSignalExportRequestModel
    ) => JSONPut<void>(contextSettings, '/signals/exports', [data]),
    getExportDownload: (
      contextSettings: ApiContextSettings,
      signalExportId: string
    ) =>
      JSONQuery<DownloadSignalExportResponseModel>(
        contextSettings,
        '/signals/exports/download',
        { signalExportId }
      ),
    getSignalsForNodeId: (
      contextSettings: ApiContextSettings,
      nodeId: string
    ) =>
      JSONPost<SignalProviderByNodeResponseModel[]>(
        contextSettings,
        '/signals/nodes',
        { nodesIds: [nodeId] }
      ),
    getSignalsForNodeIds: (
      contextSettings: ApiContextSettings,
      nodesIds: string[]
    ) =>
      JSONPost<SignalProviderByNodeResponseModel[]>(
        contextSettings,
        '/signals/nodes',
        { nodesIds }
      ),
    getSignalValuesTimeRange: (
      contextSettings: ApiContextSettings,
      signalIds: string[],
      startDate: RequestDate,
      endDate: RequestDate,
      samplingInterval = SamplingInterval.Day,
      aggregation: AggregationType = AggregationType.Median,
      isInclusive = false
    ) => {
      return JSONQuery<SignalProviderTelemetryResponseModel[]>(
        contextSettings,
        signalsPathPrefix() + '/values/timerange',
        {
          signalIds,
          startDate: startDate && startDate.toISOString(),
          endDate: endDate && endDate.toISOString(),
          samplingInterval,
          aggregation,
          isInclusive
        }
      );
    },
    setSignalValueForProviderId: (
      contextSettings: ApiContextSettings,
      providerId: string,
      signalId: string,
      values: SignalValueRequestModel2[]
    ) =>
      JSONPost<void>(contextSettings, '/signals/values', {
        providerId,
        signalId,
        values
      }),
    getProvidersBySignalIds: (
      contextSettings: ApiContextSettings,
      signalIds: string[]
    ) =>
      JSONPost<FullSignalProviderResponseModel[]>(
        contextSettings,
        '/signals/providers/bySignalIds',
        { signalIds }
      )
  },
  SignalTypes: {
    getAllSignalTypes: (contextSettings: ApiContextSettings) =>
      JSONPost<SignalTypeResponseModel[]>(contextSettings, '/signalTypes/all')
  },
  SignalTypeFolders: {
    getAllSignalTypeFolders: (contextSettings: ApiContextSettings) =>
      JSONPost<SignalTypeFolderResponseModel[]>(
        contextSettings,
        '/signalTypeFolders/all'
      )
  },
  SignalViews: {
    getNodeSignalViews: (
      contextSettings: ApiContextSettings,
      nodeIds: string[]
    ) =>
      JSONPost<NodeSignalViewResponseModel[]>(
        contextSettings,
        '/nodeSignalViews?',
        { nodeIds }
      )
  },
  Nodes: {
    getNodes: (contextSettings: ApiContextSettings, nodeIds: string[]) =>
      JSONFetch<NodeResponseModel[]>(
        contextSettings,
        '/nodes/nodes?' + queryString.stringify({ nodeIds })
      ),
    getGrids: (contextSettings: ApiContextSettings) =>
      JSONFetch<string[]>(contextSettings, '/nodes/grids'),
    getAllNodes: (contextSettings: ApiContextSettings) =>
      JSONFetch<NodeResponseModel[]>(contextSettings, '/nodes/nodes'),
    getNodeTags: (contextSettings: ApiContextSettings) =>
      JSONFetch<string[]>(contextSettings, '/nodes/tags')
  },
  TemperatureImpact: {
    preview: (
      contextSettings: ApiContextSettings,
      args: GetTemperatureImpactPreviewRequestModel
    ) =>
      JSONPost<TemperatureImpactPreviewResponseModel[]>(
        contextSettings,
        '/temperatureImpact/preview',
        [args]
      )
  },
  PowerControls: {
    getSchedules: (
      contextSettings: ApiContextSettings,
      { nodeIds, count }: { nodeIds: string[]; count: number }
    ) =>
      JSONQuery<ScheduleCollectionResponseModel>(
        contextSettings,
        '/powercontrols/schedules',
        {
          PowerControlType: PowerControlType.Heating,
          nodeIds,
          NumberOfHistoricalSchedules: count
        }
      ),
    addSchedules: (
      contextSettings: ApiContextSettings,
      schedules: AddOrUpdatePowerControlScheduleRequestModel[]
    ) => JSONPut<void>(contextSettings, '/powercontrols/schedules', schedules),
    deleteSchedules: (
      contextSettings: ApiContextSettings,
      schedules: DeletePowerControlScheduleRequestModel[]
    ) =>
      JSONDeleteBody<void>(
        contextSettings,
        '/powercontrols/schedules',
        schedules
      ),
    deleteSchedule: (contextSettings: ApiContextSettings, scheduleId: string) =>
      JSONDeleteBody<void>(contextSettings, '/powercontrols/schedules', [
        { scheduleId }
      ]),
    abortSchedule: (contextSettings: ApiContextSettings, scheduleId: string) =>
      JSONPut<void>(contextSettings, '/powercontrols/schedules/abort', [
        { scheduleId }
      ]),
    updateManualSettings: (
      contextSettings: ApiContextSettings,
      signalProviderId: string,
      limitValue: number,
      amplitudeValue: number
    ) =>
      JSONPut<void>(contextSettings, '/powercontrols/signalvalues', [
        {
          signalProviderId,
          limitValue,
          amplitudeValue
        }
      ])
  },
  Dashboard: {
    getAllRelations: (contextSettings: ApiContextSettings) =>
      JSONPost<DashboardCollectionNodeRelationsResponseModel>(
        contextSettings,
        '/dashboards/collections/view/relations'
      ),
    getDefaultDashboardCollection: (contextSettings: ApiContextSettings) =>
      JSONPost<DashboardCollectionViewResponseModel>(
        contextSettings,
        '/dashboards/collections/view/default'
      ),
    getCollectionByNodeId: (
      contextSettings: ApiContextSettings,
      args: GetDashboardCollectionViewByNodeIdRequestModel
    ) =>
      JSONPost<DashboardCollectionViewResponseModel>(
        contextSettings,
        '/dashboards/collections/view/byNodeId',
        args
      )
  },
  Alarms: {
    getAlarms: (
      contextSettings: ApiContextSettings,
      {
        nodeId,
        grid,
        page = 1,
        orderBy,
        sortDirection,
        searchString,
        pageSize = 50,
        buildingStatuses
      }: {
        nodeId: string;
        grid: string;
        page: number;
        orderBy: string;
        sortDirection: string;
        searchString: string;
        pageSize: number;
        buildingStatuses: BuildingStatus[];
      }
    ) => {
      const _buildingStatuses =
        getBuildingStatusQueryParameter(buildingStatuses);

      const search = queryString.stringify({
        nodeId,
        grid,
        page,
        sortColumn: orderBy,
        sortOrder: sortDirection,
        searchPhrase: searchString
          ? encodeURIComponent(searchString)
          : undefined,
        pageSize,
        buildingStatuses: _buildingStatuses
      });

      return JSONFetch<AlarmsListResponseModel>(
        contextSettings,
        '/alarms?' + search
      );
    },
    getHistory: (
      contextSettings: ApiContextSettings,
      {
        nodeId,
        grid,
        signalId,
        page = 1,
        orderBy = undefined,
        sortDirection = undefined,
        searchString = undefined,
        severities = undefined,
        isActive = undefined,
        isAcknowledged = undefined,
        buildingStatuses = undefined,
        fromDate = undefined,
        toDate = undefined,
        pageSize = 50
      }: {
        signalId: string;
        nodeId: string;
        grid: string;
        page: number;
        orderBy?: string;
        sortDirection?: string;
        searchString?: string;
        pageSize?: number;
        severities?: AlarmSeverity[];
        isActive?: boolean;
        isAcknowledged?: boolean;
        fromDate?: string;
        toDate?: string;
        buildingStatuses?: BuildingStatus[];
      }
    ) => {
      const search = queryString.stringify({
        nodeId,
        grid,
        signalId,
        page,
        sortColumn: orderBy,
        sortOrder: sortDirection,
        searchPhrase: searchString
          ? encodeURIComponent(searchString)
          : undefined,
        pageSize,
        severities,
        isActive,
        isAcknowledged,
        buildingStatuses,
        fromDate,
        toDate
      });

      return JSONFetch<AlarmsListResponseModel>(
        contextSettings,
        '/alarms/history?' + search
      );
    },
    acknowledgeAlarm: (
      contextSettings: ApiContextSettings,
      alarmDate: string,
      signalId: string,
      comment: string = null
    ) => {
      return JSONPut<AcknowledgeAlarmRequestModel>(
        contextSettings,
        '/alarms/acknowledge',
        { alarmDate, signalId, comment }
      );
    },
    acknowledgeAlarmsAll: (
      contextSettings: ApiContextSettings,
      nodeId: string,
      grid: string,
      comment: string,
      buildingStatuses: BuildingStatus[],
      searchPhrase: string
    ) => {
      const _buildingStatuses =
        getBuildingStatusQueryParameter(buildingStatuses);

      return JSONPut<void>(contextSettings, '/alarms/acknowledgeAll', {
        nodeId,
        grid,
        comment,
        buildingStatuses: _buildingStatuses,
        searchPhrase
      });
    }
  },
  RemoteOptimisation: {
    setOptimisation: (
      contextSettings: ApiContextSettings,
      signalProvidersWithSignalValue: SetLinearOptimisationSignalsRequestModel[]
    ) =>
      JSONPost<void>(
        contextSettings,
        '/remoteoptimisations/signalvalues',
        signalProvidersWithSignalValue
      ),
    getOptimisationEquipment: (
      contextSettings: ApiContextSettings,
      providerIds: string[]
    ) =>
      JSONQuery<LinearOptimisationResponseModel[]>(
        contextSettings,
        '/remoteoptimisations/linearoptimisations/providers',
        { providerIds }
      )
  },
  Equipments: {
    getEquipmentTypes: (contextSettings: ApiContextSettings) =>
      JSONFetch<EquipmentTypeResponseModel[]>(
        contextSettings,
        '/equipments/equipmentTypes'
      )
  },
  Export: {
    getSignalDataExportCsv: (
      contextSettings: ApiContextSettings,
      args: SignalsGetSignalValuesByTimeRangeExportToCsvParams
    ) => {
      return JSONQueryUnparsedReturn(
        contextSettings,
        '/signals/values/csv',
        args
      );
    }
  },
  Meteorology: {
    getMeteorologyPoints: (contextSettings: ApiContextSettings) =>
      JSONFetch<GeographicalPointResponseModel[]>(
        contextSettings,
        '/meteorology/points'
      )
  }
};

export default API;
