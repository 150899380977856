import React, { useState, useEffect, useCallback, useContext } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { AddIcon } from 'ecto-common/lib/Icon';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import styles from './AddLogButton.module.css';
import T from 'ecto-common/lib/lang/Language';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import SelectNodeDialog from 'ecto-common/lib/SelectNodeDialog/SelectNodeDialog';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import AddButton from 'ecto-common/lib/Button/AddButton';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import APIGen, { NodeResponseModel } from 'ecto-common/lib/API/APIGen';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';
import { useQueryClient } from '@tanstack/react-query';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

interface AddLogButtonProps {
  currentNodeId: string;
  disabled?: boolean;
}

const AddLogButton = ({
  currentNodeId,
  disabled = false
}: AddLogButtonProps) => {
  const nodeMap = useOperatorSelector((state) => state.general.nodeMap);
  const queryClient = useQueryClient();

  const [addDialogIsOpen, showAddDialog, hideAddDialog] =
    useDialogState('show-add-log');
  const [logEntryText, setLogEntryText] = useState<string>(null);
  const [selectedNodeId, setSelectedNodeId] = useState<string>(null);
  const [nodeDialogIsOpen, showNodeDialog, hideNodeDialog] =
    useDialogState('show-select-node');
  const { contextSettings } = useContext(TenantContext);

  const setSelectedNode = useCallback(([nodeId]: string[]) => {
    setSelectedNodeId(nodeId);
  }, []);

  useEffect(() => {
    setSelectedNodeId(null);
  }, [addDialogIsOpen]);

  const _selectedNode: NodeResponseModel = getNodeFromMap(
    nodeMap,
    selectedNodeId ?? currentNodeId
  );

  const onModalClose = useCallback(() => {
    hideAddDialog();
    setLogEntryText(null);
  }, [hideAddDialog]);

  const { isLoading, mutate: addLog } = APIGen.ActivityLog.create.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(
        APIGen.ActivityLog.getRecursive.path(contextSettings)
      );
      queryClient.invalidateQueries(
        APIGen.ActivityLog.get.path(contextSettings)
      );
      onModalClose();
    },
    onError: (error) => {
      toastStore.addErrorToast(T.logview.modal.add.action.failure);
      console.error(error);
    }
  });

  const onAddClick = useCallback(() => {
    addLog({
      locationId: selectedNodeId ?? currentNodeId,
      message: logEntryText
    });
  }, [addLog, selectedNodeId, currentNodeId, logEntryText]);

  const onLogEntryTextChange = useCallback(
    (_event: unknown, value: string) => setLogEntryText(value),
    []
  );

  return (
    <>
      <AddButton onClick={showAddDialog} disabled={disabled}>
        {T.logview.addbutton.title}
      </AddButton>

      <ActionModal
        title={T.logview.modal.add.title}
        isOpen={addDialogIsOpen}
        isLoading={isLoading}
        actionText={T.common.add}
        onConfirmClick={onAddClick}
        onModalClose={onModalClose}
        headerIcon={AddIcon}
        disableActionButton={!logEntryText}
      >
        <p className={styles.text}>{T.logview.modal.add.info1}</p>

        <div className={styles.input}>
          <KeyValueFixedSelectableInput
            onClick={showNodeDialog}
            keyText={T.logview.modal.select.location}
            value={_selectedNode?.name}
            placeholder={T.logview.modal.select.location}
          />

          <SelectNodeDialog
            isOpen={nodeDialogIsOpen}
            onModalClose={hideNodeDialog}
            onNodesSelected={setSelectedNode}
            nodeIds={[currentNodeId]}
            multiSelect={false}
          />
        </div>

        <div className={styles.input}>
          <TextInput
            onChange={onLogEntryTextChange}
            placeholder={T.logview.modal.add.text.placeholder}
            maxLength={500}
            autoFocus
          />
        </div>

        <p className={styles.text}>{T.logview.modal.add.info2}</p>
      </ActionModal>
    </>
  );
};

export default typedMemo(AddLogButton);
