import React, { useLayoutEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import styles from './ProcessMapObjectViews.module.css';
import _ from 'lodash';
import {
  ProcessMapSignalTextObject,
  ProcessMapTextObject,
  ProcessMapTextSettings,
  defaultTextSettings,
  ProcessMapTextAlignments
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { darkModeStore } from 'ecto-common/lib/DarkMode/DarkMode';
import colors from 'ecto-common/lib/styles/variables/colors';

type ProcessMapTextViewProps = {
  node: ProcessMapTextObject | ProcessMapSignalTextObject;
  isDragging: boolean;
  updateTextSize: (
    objectIndex: number,
    rectIndex: number,
    width: number,
    height: number
  ) => void;
  title: string;
  titleTextSettings: ProcessMapTextSettings;
  subtitle?: string;
  subtitleTextSettings?: ProcessMapTextSettings;
  objectIndex: number;
  isHovering: boolean;
  onClick?: (event: MouseEvent) => void;
  onMouseOver?: (event: MouseEvent) => void;
  onMouseOut?: (event: MouseEvent) => void;
  shouldHide: boolean;
};

const updateSizeForElement = (
  objectIndex: number,
  textElement: SVGTextElement,
  updateTextSize: (
    objectIndex: number,
    rectIndex: number,
    width: number,
    height: number
  ) => void
) => {
  const bbox = textElement.getBBox();
  updateTextSize(objectIndex, 0, bbox.width, bbox.height);
};

export const ProcessMapTextView = React.memo(
  ({
    node,
    isDragging,
    updateTextSize,
    title,
    titleTextSettings,
    subtitle,
    subtitleTextSettings,
    objectIndex,
    isHovering,
    onMouseOut,
    onMouseOver,
    onClick,
    shouldHide
  }: ProcessMapTextViewProps) => {
    const textRef = useRef<SVGTextElement>(null);
    const darkModeEnabled = useSyncExternalStore(
      darkModeStore.subscribe,
      darkModeStore.getSnapshot
    );

    const titleFontSize =
      titleTextSettings?.fontSize || defaultTextSettings.fontSize;
    const subtitleFontSize =
      subtitleTextSettings?.fontSize || defaultTextSettings.fontSize;
    const lineHeight = 1.2;
    const textElement = textRef.current;

    // Monitor the size of the text element.
    useLayoutEffect(() => {
      // Define a function to update size state
      const updateSize = () => {
        updateSizeForElement(objectIndex, textElement, updateTextSize);
      };

      if (textElement == null) {
        return;
      }

      // Instantiate and observe size changes
      const resizeObserver = new ResizeObserver(updateSize);
      resizeObserver.observe(textElement);

      // Update the size initially
      updateSize();
      const boundElement = textElement;
      // Cleanup: unobserve the element on component unmount
      return () => resizeObserver.unobserve(boundElement);
    }, [objectIndex, textElement, updateTextSize]);

    const lines = useMemo(() => {
      return (title ?? '').split('\n');
    }, [title]);

    const subtitleLines = useMemo(() => {
      return (subtitle ?? '').split('\n');
    }, [subtitle]);

    const rect = node.rects[0];

    // For some reason the text element is offset by half a line when using multiple tspans.
    const offsetPixels = titleFontSize * lineHeight * 0.5;
    const renderTitle =
      lines.length > 1 || (lines.length === 1 && lines[0] !== '');
    const renderSubtitle =
      subtitleLines.length > 1 ||
      (subtitleLines.length === 1 && subtitleLines[0] !== '');

    let textAnchor: string;
    let xAnchor = '0%';

    if ((renderTitle && renderSubtitle) || (renderTitle && lines.length > 1)) {
      if (node.textAlignment === ProcessMapTextAlignments.Center) {
        textAnchor = 'middle';
        xAnchor = '50%';
      } else if (node.textAlignment === ProcessMapTextAlignments.Right) {
        textAnchor = 'end';
        xAnchor = '100%';
      }
    }

    return (
      <g>
        <rect
          className={classNames(
            styles.textBackground,
            isDragging && styles.dragging,
            isHovering && styles.hovering
          )}
          width={rect.width}
          height={rect.height}
          x={rect.centerX - rect.width / 2.0}
          y={rect.centerY - rect.height / 2.0}
          shapeRendering="auto"
          fill="transparent"
        />
        <svg
          width={rect.width}
          height={rect.height}
          x={rect.centerX - rect.width / 2.0}
          y={rect.centerY - rect.height / 2.0}
          onMouseOver={(event) => onMouseOver?.(event.nativeEvent)}
          onMouseOut={(event) => onMouseOut?.(event.nativeEvent)}
          onClick={(event) => onClick?.(event.nativeEvent)}
          style={{ cursor: onClick ? 'pointer' : 'default' }}
          className={classNames(shouldHide && styles.faded)}
        >
          <text
            ref={(newRef) => {
              if (newRef != null) {
                updateSizeForElement(objectIndex, newRef, updateTextSize);
              }

              textRef.current = newRef;
            }}
            dominantBaseline="middle"
            textAnchor="left"
            fill={darkModeEnabled ? colors.darkTextColor : colors.surface2Color}
            x="0%"
            y="0"
            transform={'translate(0, ' + offsetPixels + ')'}
            className={styles.text}
          >
            {renderTitle &&
              lines.map((line, idx) => (
                <tspan
                  fontSize={titleFontSize + 'px'}
                  style={{
                    fontWeight: titleTextSettings?.bold ? '600' : 'normal',
                    fontStyle: titleTextSettings?.italic ? 'italic' : 'normal',
                    textDecoration: titleTextSettings?.underline
                      ? 'underline'
                      : 'none'
                  }}
                  textAnchor={textAnchor}
                  x={xAnchor}
                  key={line + idx}
                  dx={0}
                  dy={idx === 0 ? undefined : lineHeight + 'em'}
                >
                  {line === '' ? ' ' : line}
                </tspan>
              ))}
            {renderSubtitle &&
              subtitleLines.map((line, idx) => (
                <tspan
                  fontSize={subtitleFontSize + 'px'}
                  style={{
                    fontWeight: subtitleTextSettings?.bold ? '600' : 'normal',
                    fontStyle: subtitleTextSettings?.italic
                      ? 'italic'
                      : 'normal',
                    textDecoration: subtitleTextSettings?.underline
                      ? 'underline'
                      : 'none'
                  }}
                  textAnchor={textAnchor}
                  x={xAnchor}
                  key={line + idx}
                  dx={0}
                  dy={idx === 0 && !renderTitle ? undefined : lineHeight + 'em'}
                >
                  {line === '' ? ' ' : line}
                </tspan>
              ))}
          </text>
        </svg>
      </g>
    );
  }
);
