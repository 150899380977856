import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import API from 'ecto-common/lib/API/API';
import T from 'ecto-common/lib/lang/Language';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import useLatestSignalValues from 'ecto-common/lib/hooks/useLatestSignalValues';
import APIGen, { SignalInfoResponseModel } from 'ecto-common/lib/API/APIGen';
import { FullSignalProviderResponseModel } from '../API/APIGen';
import { Moment } from 'moment';
import { LastSignalValuesResultWithMetadata } from '../Dashboard/panels/SignalListPanel';
import { Base64 } from 'js-base64';
import { getExternalSignalIds, getSignalTypeIds } from './ProcessMapViewUtils';
import {
  ProcessMapDocument,
  emptyProcessMapDocument
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';

export const extractSignalStateNames = (svg: string) => {
  const regex = /symbol-state="(.*?)"/gm;
  let m;

  const signalStateNames = [];
  while ((m = regex.exec(svg)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    if (m.length === 2) {
      signalStateNames.push(m[1]);
    }
  }

  return _.uniq(signalStateNames);
};

export const useProcessMapSignals = (
  nodeId: string,
  signalProviders: FullSignalProviderResponseModel[],
  onlyMappedSignals: boolean,
  fromDate: Moment = null,
  externalProcessMapImageBase64: string = null,
  externalDocument: ProcessMapDocument = null
) => {
  const [specificSignalIds, setSpecificSignalIds] = useState<string[]>([]);
  const [mappedSignalTypeIds, setMappedSignalTypeIds] = useState<string[]>([]);

  const [image, setImage] = useState<string>(null);
  const [signals, setSignals] = useState<SignalInfoResponseModel[]>([]);
  const [error, setError] = useState<string>(null);

  const specificSignalIdProvidersQuery =
    APIGen.Signals.getProvidersBySignalIds.useQuery(
      {
        signalIds: specificSignalIds
      },
      {
        enabled: specificSignalIds.length > 0
      }
    );

  const [isLoading, getProcessMapSignals] = usePromiseCall({
    promise: API.SignalViews.getNodeSignalViews,
    initiallyLoading: true,
    onSuccess: (res) => {
      const view = res[0];

      if (externalProcessMapImageBase64 && view) {
        setSignals(view.equipmentSignals);
        const decoded = Base64.decode(externalProcessMapImageBase64);
        let loadedDocument: ProcessMapDocument = null;
        try {
          loadedDocument = JSON.parse(decoded);
        } catch (e) {
          console.error(e);
          loadedDocument = _.cloneDeep(emptyProcessMapDocument);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (loadedDocument as any)?.oldSvgData;

        const document = externalDocument ?? loadedDocument;
        setSpecificSignalIds(getExternalSignalIds(document));
        setMappedSignalTypeIds(getSignalTypeIds(document));

        setImage(decoded);
      } else if (view) {
        setSignals(view.equipmentSignals);
        if (view.map) {
          const decoded = Base64.decode(view.map);
          let loadedDocument: ProcessMapDocument = null;
          if (decoded.startsWith('{')) {
            try {
              loadedDocument = JSON.parse(decoded);
            } catch (e) {
              console.error(e);
              loadedDocument = _.cloneDeep(emptyProcessMapDocument);
            }
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          delete (loadedDocument as any)?.oldSvgData;

          if (loadedDocument != null) {
            const document = externalDocument ?? loadedDocument;
            setSpecificSignalIds(getExternalSignalIds(document));
            setMappedSignalTypeIds(getSignalTypeIds(document));

            setImage(decoded);
          }
        }
      }
    },
    onError: () => {
      setError(T.equipment.errorfetchingprocessmap);
    }
  });

  const { signalIds, signalProvidersIds, allSignalIds } = useMemo(() => {
    const allProviders = _.concat(
      signalProviders,
      specificSignalIdProvidersQuery.data ?? []
    );

    if (onlyMappedSignals) {
      const mappedSignalIds = _(allProviders)
        .flatMap('signals')
        .filter(
          (signal) =>
            mappedSignalTypeIds.includes(signal.signalTypeId) ||
            specificSignalIds.includes(signal.signalId)
        )
        .map('signalId')
        .value();

      return {
        signalIds: mappedSignalIds,
        signalProvidersIds: null,
        allSignalIds: mappedSignalIds
      };
    }

    return {
      signalIds: null,
      signalProvidersIds: _.uniq(_.map(allProviders, 'signalProviderId')),
      allSignalIds: _.uniq(
        _.flatMap(allProviders, (provider) =>
          _.map(provider.signals, 'signalId')
        )
      )
    };
  }, [
    onlyMappedSignals,
    signalProviders,
    specificSignalIdProvidersQuery.data,
    mappedSignalTypeIds,
    specificSignalIds
  ]);

  useEffect(() => {
    // Don't trigger refetches if the external document changes but the signal ids stay the same
    if (externalDocument != null) {
      setSpecificSignalIds((oldSignalIds) => {
        const newSignalIds = getExternalSignalIds(externalDocument);
        if (!_.isEqual(oldSignalIds, newSignalIds)) {
          return newSignalIds;
        }
        return oldSignalIds;
      });

      setMappedSignalTypeIds((oldSignalIds) => {
        const newSignalIds = getSignalTypeIds(externalDocument);
        if (!_.isEqual(oldSignalIds, newSignalIds)) {
          return newSignalIds;
        }
        return oldSignalIds;
      });
    } else {
      setSpecificSignalIds([]);
      setMappedSignalTypeIds([]);
    }
  }, [externalDocument]);

  useEffect(() => {
    setError(null);
    setImage(null);
    setSignals([]);

    if (nodeId != null) {
      getProcessMapSignals(_.compact([nodeId]));
    }
  }, [nodeId, getProcessMapSignals]);

  const _signalData = useLatestSignalValues(
    signalProvidersIds,
    signalIds,
    allSignalIds,
    fromDate
  );

  const signalData: LastSignalValuesResultWithMetadata = useMemo(() => {
    return _.merge({}, _signalData, _.keyBy(signals, 'signalId'));
  }, [signals, _signalData]);

  return {
    isLoading,
    error,
    image,
    signalData,
    mappedSignalTypeIds
  };
};
