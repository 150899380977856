import React, { useCallback } from 'react';
import _ from 'lodash';
import {
  ProcessMapSymbolView,
  ProcessMapSymbolViewOverlay
} from 'ecto-common/lib/ProcessMap/View/ProcessMapSymbolView';

import {
  ProcessMapObjectsProps,
  ProcessMapOverlayObjectProps
} from '../ProcessMapObjectProps';
import {
  ProcessMapViewSignal,
  ProcessMapSymbolObject
} from '../ProcessMapViewConstants';
import { CustomNodeTreeSet } from 'ecto-common/lib/SelectNodeDialog/SelectNode';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ProcessMapSymbolRuleEditor } from 'ecto-common/lib/ProcessMaps/ProcessMapSymbolRuleEditor';
import { ProcessMapSymbolEditor } from 'ecto-common/lib/ProcessMaps/ProcessMapSymbolEditor';
import { SymbolModel } from 'ecto-common/lib/API/PresentationAPIGen';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { LastSignalValuesDataSetWithMetadata } from 'ecto-common/lib/Dashboard/panels/SignalListPanel';
import { getPathFromModelKeyFunc } from 'ecto-common/lib/ModelForm/formUtils';

export const SymbolView = ({
  selectedRectHandles,
  allSignalsBySignalTypeOrSignalId,
  signalData,
  svgImages,
  node: nodeIn,
  isHovering,
  editMode,
  onMouseOver,
  onMouseOut,
  onClick
}: ProcessMapObjectsProps) => {
  const node = nodeIn as ProcessMapSymbolObject;
  const amongSelected = selectedRectHandles.some(
    (handle) => handle.objectId === node.id
  );
  let signal: ProcessMapViewSignal = null;
  const matchingRule = _.find(
    node.symbolRules,
    (rule) =>
      allSignalsBySignalTypeOrSignalId[rule.condition.signalTypeId] != null ||
      allSignalsBySignalTypeOrSignalId[rule.condition.signalId] != null
  );
  const signalIdOrTypeId =
    matchingRule?.condition?.signalTypeId ?? matchingRule?.condition?.signalId;
  let value: LastSignalValuesDataSetWithMetadata = null;

  if (signalIdOrTypeId != null) {
    signal = allSignalsBySignalTypeOrSignalId[signalIdOrTypeId];
    value = signalData[signal?.signalId];
  }

  let shouldHide = false;

  if (node.symbolRules?.length > 0 && node.hideWhenNoSignalsForRules) {
    shouldHide = !_.some(
      node.symbolRules,
      (rule) =>
        allSignalsBySignalTypeOrSignalId[rule.condition.signalTypeId] != null ||
        allSignalsBySignalTypeOrSignalId[rule.condition.signalId] != null
    );
  }

  if (
    (node.hideWhenNoSignalTypeId != null &&
      allSignalsBySignalTypeOrSignalId[node.hideWhenNoSignalTypeId] == null) ||
    (node.hideWhenNoSignalId != null &&
      allSignalsBySignalTypeOrSignalId[node.hideWhenNoSignalId] == null)
  ) {
    shouldHide = true;
  }

  const _onClick = useCallback(
    (event: MouseEvent) => {
      onClick?.(event, node, signal, value?.isWritable);
    },
    [onClick, node, signal, value]
  );

  const _onMouseOver = useCallback(
    (event: MouseEvent) => {
      onMouseOver?.(event, node, signal, value?.isWritable);
    },
    [onMouseOver, node, signal, value]
  );

  const _onMouseOut = useCallback(
    (event: MouseEvent) => {
      onMouseOut?.(event, node, signal, value?.isWritable);
    },
    [onMouseOut, node, signal, value]
  );

  if (!editMode && shouldHide) {
    return null;
  }

  return (
    <ProcessMapSymbolView
      key={node.id}
      isDragging={amongSelected}
      node={node as ProcessMapSymbolObject}
      isHovering={isHovering}
      svgContent={svgImages[node.svgMd5]}
      allSignalsBySignalTypeOrSignalId={allSignalsBySignalTypeOrSignalId}
      signalData={signalData}
      shouldHide={shouldHide}
      onClick={_onClick}
      onMouseOut={_onMouseOut}
      onMouseOver={_onMouseOver}
    />
  );
};

export const SymbolOverlay = ({
  node,
  selectedRectHandles,
  symbolLineConnections,
  connectionCircleRadius,
  pendingConnectionSymbol,
  showDeleteConnections,
  isHovering,
  zoom,
  isMouseDown,
  draggingSingleLinePoint
}: ProcessMapOverlayObjectProps) => {
  const amongSelected = selectedRectHandles.some(
    (handle) => handle.objectId === node.id
  );
  const shouldRender =
    (isMouseDown && draggingSingleLinePoint && isHovering) ||
    (showDeleteConnections && amongSelected);

  return (
    shouldRender && (
      <ProcessMapSymbolViewOverlay
        key={node.id}
        node={node as ProcessMapSymbolObject}
        symbolLineConnections={symbolLineConnections}
        connectionCircleRadius={connectionCircleRadius}
        pendingConnectionSymbol={pendingConnectionSymbol}
        showDeleteConnections={showDeleteConnections}
        zoom={zoom}
      />
    )
  );
};

const rotationOptions: GenericSelectOption<number>[] = [
  {
    label: '0',
    value: 0
  },
  {
    label: '90',
    value: 90
  },
  {
    label: '180',
    value: 180
  },
  {
    label: '270',
    value: 270
  }
];

export const symbolSections = (
  previewNodeId: string,
  nodeTreeSet: CustomNodeTreeSet,
  svgImages: Record<string, string>,
  library: SymbolModel[],
  states: string[]
): ModelFormSectionType<ProcessMapSymbolObject>[] => {
  return [
    {
      label: T.admin.processmaps.objecteditor.symbolobject,
      lines: _.compact([
        {
          models: [
            {
              key: (input) => input.svgMd5,
              modelType: ModelType.CUSTOM,
              render: (props) => (
                <ProcessMapSymbolEditor
                  {...props}
                  svgImages={svgImages}
                  library={library}
                />
              )
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.flipvertical,
              key: (input) => input.flipVertical,
              modelType: ModelType.BOOL
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.fliphorizontal,
              key: (input) => input.flipHorizontal,
              modelType: ModelType.BOOL
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.rotation,
              key: (input) => input.rotation,
              modelType: ModelType.OPTIONS,
              options: rotationOptions
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.scale,
              key: (input) => input.scale,
              modelType: ModelType.NUMBER,
              placeholder: '1.0'
            }
          ]
        },
        states?.length > 0 && {
          models: [
            {
              label: T.admin.processmaps.objecteditor.symbolrules,
              key: (input) => input.symbolRules,
              modelType: ModelType.CUSTOM,
              render: (props, model) => (
                <ProcessMapSymbolRuleEditor
                  {...props}
                  customNodeTreeSet={nodeTreeSet}
                  nodeId={previewNodeId}
                  states={states}
                  keyPath={getPathFromModelKeyFunc(model.key).join('-')}
                />
              )
            }
          ]
        }
      ])
    },
    {
      label: T.admin.processmaps.objecteditor.hideitem,
      initiallyCollapsed: false,
      lines: [
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.hiderequiredsignal,
              key: (input) => input.hideWhenNoSignalTypeId,
              modelType: ModelType.SIGNAL_TYPE,
              isClearable: true,
              helpText: T.admin.processmaps.objecteditor.hiderequiredsignalhelp,
              customNodeTreeSet: nodeTreeSet,
              nodeId: previewNodeId
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.hiderequiredsignalid,
              key: (input) => input.hideWhenNoSignalId,
              modelType: ModelType.SIGNAL,
              isClearable: true,
              helpText:
                T.admin.processmaps.objecteditor.hiderequiredsignalidhelp,
              customNodeTreeSet: nodeTreeSet,
              nodeId: previewNodeId
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.hiderulesignals,
              key: (input) => input.hideWhenNoSignalsForRules,
              visible: (input) => input.symbolRules?.length > 0,
              modelType: ModelType.BOOL,
              helpText: T.admin.processmaps.objecteditor.hiderulesignalshelp
            }
          ]
        }
      ]
    }
  ];
};
