import { useQueries } from '@tanstack/react-query';
import EctoplannerAPIGen from 'ecto-common/lib/API/EctoplannerAPIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  averageTimeSeriesToWeeks,
  binTimeSeriesToMonths,
  maxTimeSeriesToDays
} from 'js/components/Ectoplanner/EctoplannerDemandGraphs';
import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import {
  ectoplannerDayNames,
  ectoplannerHourNames,
  ectoplannerMonthNames,
  ectoplannerWeekNames
} from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerGraphBrowserTypes';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import dimensions from 'ecto-common/lib/styles/dimensions';
import { colorTable } from 'ecto-common/lib/SignalSelector/StockChart.config';
import T from 'ecto-common/lib/lang/Language';
import {
  SecosimForm,
  SecosimResult
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import { parseTimeSeriesFile } from 'js/components/Ectoplanner/Calculation/EctoplannerCalculationUtil';
import { averageTimeSeriesToMonths } from '../EctoplannerDemandGraphs';
import Spinner from 'ecto-common/lib/Spinner/Spinner';

const domProps = {
  style: {
    height: '100%'
  }
};

function minimumRollingAverage3Index(arr: number[]): number {
  let curLargestIndex = [Number.MAX_SAFE_INTEGER, -1];

  for (let i = 0; i < arr.length - 2; i++) {
    const sum = arr[i] + arr[i + 1] + arr[i + 2];
    if (sum < curLargestIndex[0]) {
      curLargestIndex = [sum, i];
    }
  }

  return curLargestIndex[1];
}

/* eslint-disable @typescript-eslint/no-var-requires */
require('highcharts/modules/sankey')(Highcharts);

const kpiDataSignals = {
  AirWaterHeatPumpGeneration: 'Air Water Heat Pump Generation',
  AirWaterHeatPumpElectricalDemand: 'Air Water Heat Pump Electrical Demand',
  OutdoorTemperature: 'Outdoor Temperature',
  BatteryCharge: 'battery_charge',
  BatteryDischarge: 'battery_discharge',
  CoolingPumpsDemand: 'Cooling Pumps Demand',
  HeatingDemand: 'Heating Demand',
  HeatingAirWaterHeatPump: 'Heating Air Water Heat Pump',
  HeatingWellSystem: 'Heating Well System',
  HeatingEBoiler: 'Heating E-Boiler',
  DHWAirWaterHeatPump: 'DHW Air Water Heat Pump',
  DHWWellSystem: 'DHW Well System',
  DHWEBoiler: 'DHW E-Boiler',
  Heating: 'heating',
  BufferTankTemperature: 'Buffer Tank Temperature',
  BuildingDemand: 'Building Demand',
  DHW: 'dhw',
  FeedIn: 'feed_in',
  FeedInRevenue: 'feed_in_revenue',
  HeatingWellSystemGeneration: 'Heating Well System Generation',
  HeatingWellNominalPower: 'Heating Well nominal Power',
  ElectricityPayment: 'Electricity Payment',
  GasBoilerCost: 'Gas-Boiler Cost',
  GasCost: 'Gas Cost',
  ElectricityHistorical: 'electricity_historical',
  TotalCo2Balance: 'Total CO₂ Balance',
  Co2Historical: 'co2_historical',
  GasHistoricalConsumption: 'Gas Historical Consumption',
  ElectricityImportCost: 'Electricity Import Cost',
  GridConnectionEnergyFlow: 'Grid Connection Energy Flow',
  GridImport: 'grid_import',
  PVGeneration: 'PV Generation',
  ThermalDemandSolar: 'thermal_demand_solar',
  ThermalDemandGrid: 'thermal_demand_grid'
};

const kpiDataSignalIds = Object.values(kpiDataSignals);

type SecosimSeries = {
  name: string;
  unit: string;
  data: number[];
  color?: string;
  type?: 'line' | 'column';
};

const commonOptions: Highcharts.Options = {
  navigator: {
    enabled: false
  },
  legend: {
    enabled: true
  },
  noData: {
    style: {
      display: 'none'
    }
  },
  tooltip: {
    valueDecimals: 2
  },
  plotOptions: {
    series: {
      cursor: 'pointer',
      // @ts-ignore-next-line
      groupPadding: 0.1,
      pointPadding: 0
    }
  }
};

function getColumnHighchartOptionsWithCategories(
  title: string,
  data: [string, number][],
  unit: string
): Highcharts.Options {
  // let seriesColor = colorTable[index % colorTable.length];

  return {
    xAxis: {
      type: 'category'
    },
    yAxis: {
      title: {
        text: unit
      }
    },
    chart: {
      type: 'column',
      height: 400,
      animation: false,
      resetZoomButton: {
        theme: {
          style: {
            display: 'none'
          }
        }
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: title
    },
    ...commonOptions,
    series: [
      {
        type: 'column',
        name: title,
        data: data.map((series, idx) => ({
          y: series[1],
          name: series[0],
          category: series[0],
          color: colorTable[idx % colorTable.length],
          unit,
          yAxis: unit
        }))
      }
    ]
  } as const;
}

const getHighchartOptions = (
  graphSeries: SecosimSeries[],
  type: 'column' | 'line',
  aggregation: 'day' | 'week' | 'month' | 'hour',
  title: string,
  hourStartIndex = 0
): Highcharts.Options => {
  const filteredSeries = _.filter(graphSeries, (series) => series.data != null);

  let colorIndex = 0;

  const yAxis = _.uniq(
    filteredSeries.map((series) => {
      return series?.unit ?? '';
    })
  ).map((unit, idx) => {
    return {
      id: unit,
      title: {
        text: unit
      },
      showEmpty: true,
      opposite: idx % 2 === 1
    };
  });

  let categories: string[] = [];

  switch (aggregation) {
    case 'hour':
      categories = ectoplannerHourNames.slice(hourStartIndex);
      break;
    case 'day':
      categories = ectoplannerDayNames;
      break;
    case 'week':
      categories = ectoplannerWeekNames;
      break;
    case 'month':
      categories = ectoplannerMonthNames;
      break;
    default:
      break;
  }

  return {
    yAxis,
    chart: {
      type,
      height: 400,
      animation: false,
      resetZoomButton: {
        theme: {
          style: {
            display: 'none'
          }
        }
      }
    },
    ...commonOptions,
    title: {
      text: title
    },
    xAxis: {
      categories
    },
    series: filteredSeries.map((series) => {
      let name = series.name;
      if (series.unit !== '') {
        name += ' (' + series.unit + ')';
      }

      let seriesColor = series.color;
      if (seriesColor == null) {
        seriesColor = colorTable[colorIndex];
        colorIndex = (colorIndex + 1) % colorTable.length;
      }
      return {
        type,
        yAxis: series.unit,
        name,
        data: series.data,
        unit: series.unit,
        color: seriesColor
      };
    })
  };
};

const SecosimGraph = ({ options }: { options: Highcharts.Options }) => {
  return (
    <>
      {options && (
        <PlainBox
          style={{
            marginBottom: dimensions.standardMargin,
            height: 440,
            flexShrink: 0
          }}
        >
          <div>
            <HighchartsReact
              options={options}
              highcharts={Highcharts}
              containerProps={domProps}
            />
          </div>
        </PlainBox>
      )}
    </>
  );
};

const SecosimKPIGraphs = ({
  result,
  buildId,
  form
}: {
  result: SecosimResult;
  buildId: string;
  form: SecosimForm;
}) => {
  const { contextSettings } = useContext(TenantContext);

  const seriesById = useMemo(() => {
    return _.keyBy(result?.timeseries, 'id');
  }, [result?.timeseries]);

  const seriesToRequest = useMemo(() => {
    return _.compact(_.map(kpiDataSignalIds, (id) => seriesById[id]));
  }, [seriesById]);

  const signalDataQueries = useQueries({
    queries: _.compact(
      seriesToRequest.map((reqSeries) => {
        return {
          queryKey: ['ectoplanner', 'graphsignaldata', buildId, reqSeries.id],
          queryFn: ({ signal }: { signal?: AbortSignal }) => {
            return EctoplannerAPIGen.EctoGridBuilds.timeseriesDetail.promise(
              contextSettings,
              {
                buildId,
                filename: reqSeries.id
              },
              signal
            );
          }
        };
      })
    )
  });

  const partiallyLoaded = _.some(signalDataQueries, (query) => query.isLoading);

  const seriesDataById = useMemo(() => {
    const allData = signalDataQueries.map((query) => query.data);
    if (partiallyLoaded) {
      return {};
    }

    const ret: Record<string, number[]> = {};

    for (let i = 0; i < allData.length; i++) {
      ret[seriesToRequest[i].id] = parseTimeSeriesFile(allData[i]);
    }

    return ret;
  }, [partiallyLoaded, seriesToRequest, signalDataQueries]);

  const sankeyOptions: Highcharts.Options = useMemo(() => {
    if (result == null || result.sankeyChart == null || partiallyLoaded) {
      return null;
    }

    const nodes = _.map(result.sankeyChart.labels, (label) => ({
      id: label
    }));

    const data = _.map(result.sankeyChart.values, (value, idx) => [
      result.sankeyChart.labels[result.sankeyChart.sourceIndices[idx]],
      result.sankeyChart.labels[result.sankeyChart.targetIndices[idx]],
      value
    ]);

    return {
      title: {
        text: T.ectoplanner.secosim.kpis.energyflow
      },
      accessibility: {
        point: {
          valueDescriptionFormat: T.format(
            T.ectoplanner.secosim.kpis.graphs.sankey.energyflowformat,
            '{index}. {point.from}',
            '{point.to}, {point.weight}.'
          ).join('')
        }
      },
      tooltip: {
        headerFormat: null,
        pointFormat:
          '{point.fromNode.name} \u2192 {point.toNode.name}: {point.weight:.2f} ' +
          T.ectoplanner.units.mwh,
        nodeFormat: '{point.name}: {point.sum:.2f} ' + T.ectoplanner.units.mwh
      },
      series: [
        {
          keys: ['from', 'to', 'weight'],
          nodes,
          data: data,
          type: 'sankey',
          name: T.ectoplanner.secosim.kpis.energyflow,
          dataLabels: {
            color: 'white',
            style: {
              textOutline: '1px solid #333'
            }
          }
        }
      ]
    } as const;
  }, [partiallyLoaded, result]);

  const atesOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series
                .weeklyoutdooravgtemp,
              unit: T.ectoplanner.units.degc,
              data: averageTimeSeriesToWeeks(
                seriesDataById[kpiDataSignals.OutdoorTemperature]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series
                .maxweeklypowerabsorptionashp,
              unit: T.ectoplanner.units.kw,
              data: averageTimeSeriesToWeeks(
                seriesDataById[kpiDataSignals.AirWaterHeatPumpElectricalDemand]
              )
            }
          ],
          'line',
          'week',
          T.ectoplanner.secosim.kpis.graphs.titles.atesconservation
        );
  }, [partiallyLoaded, seriesDataById]);

  const batteryOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.batterycharge,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.BatteryCharge]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.batterydischarge,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.BatteryDischarge]
              )
            }
          ],
          'column',
          'month',
          T.ectoplanner.secosim.kpis.graphs.titles.batterychargeanddischarge
        );
  }, [partiallyLoaded, seriesDataById]);

  const energyUsageByAssetOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.coolingpumps,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.CoolingPumpsDemand]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.airsourceheatpumps,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.HeatingAirWaterHeatPump]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series
                .watersourceheatpumps,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.HeatingWellSystem]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.eboilerheating,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.HeatingEBoiler]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.dhwairheatpump,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.DHWAirWaterHeatPump]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.dhwwellsystem,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.DHWWellSystem]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.dhweboiler,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.DHWEBoiler]
              )
            }
          ],
          'column',
          'month',
          T.ectoplanner.secosim.kpis.graphs.titles.energyusagebyasset
        );
  }, [partiallyLoaded, seriesDataById]);

  const energyUsageOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.heating,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.Heating]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.cooling,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.CoolingPumpsDemand]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.generalbuilding,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.BuildingDemand]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.dhw,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(seriesDataById[kpiDataSignals.DHW])
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.feedin,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(seriesDataById[kpiDataSignals.FeedIn])
            }
          ],
          'column',
          'month',
          T.ectoplanner.secosim.kpis.graphs.titles.energyusage
        );
  }, [partiallyLoaded, seriesDataById]);

  const feedinEnergyAndRevenueOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.feedinenergy,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(seriesDataById[kpiDataSignals.FeedIn])
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.feedinrevenues,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.FeedInRevenue]
              )
            }
          ],
          'column',
          'month',
          T.ectoplanner.secosim.kpis.graphs.titles.feedinenergyandrevenue
        );
  }, [partiallyLoaded, seriesDataById]);

  const heatPumpOperationOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series
                .heatingwellsystemgeneration,
              unit: T.ectoplanner.units.kw,
              data: maxTimeSeriesToDays(
                seriesDataById[kpiDataSignals.HeatingWellSystemGeneration]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series
                .heatingwellnominalpower,
              unit: T.ectoplanner.units.kw,
              data: new Array(365).fill(
                form?.buildings?.[0]?.heat_hp?.heat_hp_capacity ?? 0
              )
            }
          ],
          'line',
          'day',
          T.ectoplanner.secosim.kpis.graphs.titles.heatpumpoperation
        );
  }, [form?.buildings, partiallyLoaded, seriesDataById]);

  const heatingOffOptions = useMemo(() => {
    const h1 = seriesDataById[kpiDataSignals.HeatingWellSystemGeneration];
    const h2 = seriesDataById[kpiDataSignals.AirWaterHeatPumpGeneration];
    const heatingPower = _.zipWith(h1, h2, (a, b) => a + b);
    const minIndex = minimumRollingAverage3Index(heatingPower);

    const startIndex = Math.max(0, minIndex - 14);
    const endIndex = Math.min(heatingPower.length, minIndex + 14);

    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series
                .totalheatpumpgeneration,
              unit: T.ectoplanner.units.kw,
              data: heatingPower.slice(startIndex, endIndex)
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.heatingdemand,
              unit: T.ectoplanner.units.kw,
              data: seriesDataById[kpiDataSignals.HeatingDemand]?.slice(
                startIndex,
                endIndex
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series
                .buffertanktemperature,
              unit: T.ectoplanner.units.degc,
              data: seriesDataById[kpiDataSignals.BufferTankTemperature]?.slice(
                startIndex,
                endIndex
              )
            }
          ],
          'line',
          'hour',
          T.ectoplanner.secosim.kpis.graphs.titles.heatingoff,
          startIndex
        );
  }, [partiallyLoaded, seriesDataById]);

  const goalOptions = useMemo(() => {
    if (partiallyLoaded) {
      return null;
    }

    if (result.goal === 'cost') {
      const c1 = seriesDataById[kpiDataSignals.ElectricityPayment];
      const c2 = seriesDataById[kpiDataSignals.GasBoilerCost];
      const c3 = seriesDataById[kpiDataSignals.ElectricityHistorical];
      const c4 = seriesDataById[kpiDataSignals.GasCost];

      return getHighchartOptions(
        [
          {
            name: T.ectoplanner.secosim.kpis.graphs.series
              .energycostwithsectorcoupling,
            unit: T.ectoplanner.units.eur,
            data: binTimeSeriesToMonths(_.zipWith(c1, c2, (a, b) => a + b))
          },
          {
            name: T.ectoplanner.secosim.kpis.graphs.series.energycosthistorical,
            unit: T.ectoplanner.units.eur,
            data: binTimeSeriesToMonths(_.zipWith(c3, c4, (a, b) => a + b))
          }
        ],
        'column',
        'month',
        T.ectoplanner.secosim.kpis.graphs.titles.impactofsectorcouplingoncost
      );
    } else if (result.goal === 'co2') {
      return getHighchartOptions(
        [
          {
            name: T.ectoplanner.secosim.kpis.graphs.series
              .co2withsectorcoupling,
            unit: T.ectoplanner.units.kgCO2eq,
            data: binTimeSeriesToMonths(
              seriesDataById[kpiDataSignals.TotalCo2Balance]
            )
          },
          {
            name: T.ectoplanner.secosim.kpis.graphs.series.co2historical,
            unit: T.ectoplanner.units.tonnco2,
            data: binTimeSeriesToMonths(
              _.zipWith(
                seriesDataById[kpiDataSignals.Co2Historical],
                seriesDataById[kpiDataSignals.GasHistoricalConsumption],
                (historical, consumption) =>
                  historical + (consumption * 200.8) / 1000
              )
            )
          }
        ],
        'column',
        'month',
        T.ectoplanner.secosim.kpis.graphs.titles.impactofsectorcouplingonco2
      );
    }

    return null;
  }, [partiallyLoaded, result.goal, seriesDataById]);

  const opexSavingOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.opexsavings,
              unit: T.ectoplanner.units.eur,
              data: binTimeSeriesToMonths(
                _.zipWith(
                  seriesDataById[kpiDataSignals.ElectricityHistorical],
                  seriesDataById[kpiDataSignals.GasCost],
                  seriesDataById[kpiDataSignals.ElectricityPayment],
                  seriesDataById[kpiDataSignals.GasBoilerCost],
                  (
                    electricityHistorical,
                    gasCost,
                    electricityPayment,
                    gasBoilerCost
                  ) => {
                    const capped = Math.max(
                      0,
                      electricityHistorical + gasCost - electricityPayment
                    );

                    return capped - gasBoilerCost;
                  }
                )
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.co2savings,
              unit: T.ectoplanner.units.tonnco2,
              data: binTimeSeriesToMonths(
                _.zipWith(
                  seriesDataById[kpiDataSignals.Co2Historical],
                  seriesDataById[kpiDataSignals.GasHistoricalConsumption],
                  seriesDataById[kpiDataSignals.TotalCo2Balance],
                  (
                    co2Historical,
                    gasHistoricalConsumption,
                    totalCo2Balance
                  ) => {
                    const capped = Math.max(
                      0,
                      co2Historical +
                        (gasHistoricalConsumption * 200.8) / 1000 -
                        totalCo2Balance
                    );

                    return capped;
                  }
                )
              )
            }
          ],
          'column',
          'month',
          T.ectoplanner.secosim.kpis.graphs.titles.opexandco2savings
        );
  }, [partiallyLoaded, seriesDataById]);

  const outSideTempVsEnergyPricesOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.outdoortemperature,
              unit: T.ectoplanner.units.degc,
              data: averageTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.OutdoorTemperature]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series
                .electricityimportcost,
              unit: T.ectoplanner.units.eurmwh,
              data: averageTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.ElectricityImportCost]
              )
            }
          ],
          'column',
          'month',
          T.ectoplanner.secosim.kpis.graphs.titles
            .outdoortemperaturevsenergyprices
        );
  }, [partiallyLoaded, seriesDataById]);

  const powerPeakFromTheGridOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.powerlimit,
              unit: T.ectoplanner.units.kw,
              data: new Array(365).fill(result.grid_capacity)
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.dailypeakpower,
              unit: T.ectoplanner.units.kw,
              data: maxTimeSeriesToDays(
                seriesDataById[kpiDataSignals.GridConnectionEnergyFlow].map(
                  (x) => Math.abs(x)
                )
              )
            }
          ],
          'line',
          'day',
          T.ectoplanner.secosim.kpis.graphs.titles.powerpeakfromthegrid
        );
  }, [partiallyLoaded, result?.grid_capacity, seriesDataById]);

  const pvContributionOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.pvcontribution,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.GridImport]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.pvgeneration,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.PVGeneration]
              )
            }
          ],
          'column',
          'month',
          T.ectoplanner.secosim.kpis.graphs.titles
            .pvcontributiontotheenergyneeds
        );
  }, [partiallyLoaded, seriesDataById]);

  const pvGenerationOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.pvnominalpeakpower,
              unit: T.ectoplanner.units.kw,
              data: new Array(365).fill(result.pv_capacity)
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.pvgeneration,
              unit: T.ectoplanner.units.kw,
              data: maxTimeSeriesToDays(
                seriesDataById[kpiDataSignals.PVGeneration]
              )
            }
          ],
          'line',
          'day',
          T.ectoplanner.secosim.kpis.graphs.titles.pvgeneration
        );
  }, [partiallyLoaded, result.pv_capacity, seriesDataById]);

  const savingsPerSectorOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getColumnHighchartOptionsWithCategories(
          T.ectoplanner.secosim.kpis.graphs.titles.savingspersector,
          [
            [
              T.ectoplanner.secosim.kpis.graphs.series.heating,
              result.savings_heating
            ],
            [
              T.ectoplanner.secosim.kpis.graphs.series.cooling,
              result.savings_cooling
            ],
            [
              T.ectoplanner.secosim.kpis.graphs.series.generalbuilding,
              result.savings_general_building
            ],
            [T.ectoplanner.secosim.kpis.graphs.series.dhw, result.savings_dhw],
            [
              T.ectoplanner.secosim.kpis.graphs.series.feedin,
              result.savings_feed_in
            ]
          ],
          T.ectoplanner.units.eur
        );
  }, [
    partiallyLoaded,
    result.savings_cooling,
    result.savings_dhw,
    result.savings_feed_in,
    result.savings_general_building,
    result.savings_heating
  ]);

  const thermalDemandSplitOptions = useMemo(() => {
    return partiallyLoaded
      ? null
      : getHighchartOptions(
          [
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.feedinenergy,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.ThermalDemandSolar]
              )
            },
            {
              name: T.ectoplanner.secosim.kpis.graphs.series.feedinrevenues,
              unit: T.ectoplanner.units.kwhel,
              data: binTimeSeriesToMonths(
                seriesDataById[kpiDataSignals.ThermalDemandGrid]
              )
            }
          ],
          'column',
          'month',
          T.ectoplanner.secosim.kpis.graphs.titles.thermaldemandsplit
        );
  }, [partiallyLoaded, seriesDataById]);

  return (
    <>
      {partiallyLoaded && <Spinner />}
      <SecosimGraph options={sankeyOptions} />
      <SecosimGraph options={atesOptions} />
      <SecosimGraph options={batteryOptions} />
      <SecosimGraph options={energyUsageByAssetOptions} />
      <SecosimGraph options={energyUsageOptions} />
      <SecosimGraph options={feedinEnergyAndRevenueOptions} />
      <SecosimGraph options={heatPumpOperationOptions} />
      <SecosimGraph options={heatingOffOptions} />
      {goalOptions != null && <SecosimGraph options={goalOptions} />}
      <SecosimGraph options={opexSavingOptions} />
      <SecosimGraph options={outSideTempVsEnergyPricesOptions} />
      <SecosimGraph options={powerPeakFromTheGridOptions} />
      <SecosimGraph options={pvContributionOptions} />
      <SecosimGraph options={pvGenerationOptions} />
      <SecosimGraph options={savingsPerSectorOptions} />
      <SecosimGraph options={thermalDemandSplitOptions} />
    </>
  );
};

export default React.memo(SecosimKPIGraphs);
