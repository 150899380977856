import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { isValidEmail } from 'ecto-common/lib/utils/stringUtils';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import ModalInnerHeader from 'ecto-common/lib/Modal/ModalInnerHeader';
import styles from './ShareEctoPlannerProjectModal.module.css';
import AddButton from 'ecto-common/lib/Button/AddButton';
import useOnUpdateFormInput from 'ecto-common/lib/ModelForm/useOnUpdateFormInput';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import EctoplannerAPIGen, {
  UserResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { useParams } from 'react-router-dom';
import { EctoplannerParams } from 'js/utils/routeConstants';
import { useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const UserColumns = [
  {
    dataKey: 'displayName',
    label: T.ectoplanner.share.displayname
  },
  {
    dataKey: 'mail',
    label: T.ectoplanner.share.mail
  }
];

const NewUserModels: ModelDefinition<NewUserType>[] = [
  {
    key: (input) => input.mail,
    placeholder: T.ectoplanner.share.placeholder,
    modelType: ModelType.TEXT
  }
];

type NewUserType = {
  mail?: string;
};

const emptyUsers: UserResponse[] = [];

const ShareEctoplannerProjectModal = ({
  projectName,
  onModalClose,
  isOpen
}: {
  projectName: string;
  onModalClose: () => void;
  isOpen: boolean;
}) => {
  const params = useParams<EctoplannerParams>();

  const [newUserData, setNewUserData] = useState<NewUserType>({});
  const onUpdateInput = useOnUpdateFormInput(setNewUserData);
  const [deleteItem, setDeleteItem] = useState<UserResponse>(null);
  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const usersQuery = EctoplannerAPIGen.EctoGridProjects.usersDetail.useQuery(
    {
      projectId: params.projectId
    },
    {
      enabled: params.projectId != null
    }
  );

  const users = usersQuery.data?.items ?? emptyUsers;

  const invalidateUsers = () => {
    queryClient.invalidateQueries(
      EctoplannerAPIGen.EctoGridProjects.usersDetail.path(contextSettings, {
        projectId: params.projectId
      })
    );
  };

  const addUserMutation =
    EctoplannerAPIGen.EctoGridProjects.usersCreate.useMutation(
      {
        projectId: params.projectId
      },
      {
        onSuccess: () => {
          invalidateUsers();
          setNewUserData({});
        },
        onError: () => {
          toastStore.addErrorToast(T.ectoplanner.share.failedtoaddusers);
        }
      }
    );

  const removeUserMutation =
    EctoplannerAPIGen.EctoGridProjects.usersDelete.useMutation(
      {
        projectId: params.projectId
      },
      {
        onSuccess: () => {
          setDeleteItem(null);
          invalidateUsers();
        },
        onError: () => {
          toastStore.addErrorToast(T.ectoplanner.share.failedtoremoveuser);
        }
      }
    );

  useEffect(() => {
    setNewUserData({});
  }, [params.projectId]);

  const _addUser = useCallback(() => {
    addUserMutation.mutate({
      mailaddresses: [newUserData.mail]
    });
  }, [addUserMutation, newUserData.mail]);

  const _removeUser = useCallback((_user: UserResponse) => {
    setDeleteItem(_user);
  }, []);

  const clearDeleteItem = useCallback(() => {
    setDeleteItem(null);
  }, []);

  const _confirmRemoveUser = useCallback(() => {
    removeUserMutation.mutate({
      mailaddresses: [deleteItem.mail]
    });
  }, [deleteItem?.mail, removeUserMutation]);

  const shouldDisableDelete = useCallback(() => {
    return users?.length <= 1;
  }, [users]);

  const _userColumns: DataTableColumnProps<UserResponse>[] = useMemo(() => {
    return [
      ...UserColumns,
      ...standardColumns({ onDelete: _removeUser, shouldDisableDelete })
    ];
  }, [shouldDisableDelete, _removeUser]);

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      title={T.format(T.ectoplanner.share.title, projectName ?? '')}
      onConfirmClick={onModalClose}
      headerIcon={Icons.Share}
      disableCancel
      isLoading={
        usersQuery.isLoading ||
        addUserMutation.isLoading ||
        removeUserMutation.isLoading
      }
    >
      {(users.length > 0 || !usersQuery.isLoading) && (
        <DataTable<UserResponse>
          isLoading={usersQuery.isLoading}
          data={users}
          columns={_userColumns}
        />
      )}
      <ModalInnerHeader>{T.ectoplanner.share.adduser}</ModalInnerHeader>
      <div className={styles.formLayout}>
        <ModelForm
          models={NewUserModels}
          input={newUserData}
          onUpdateInput={onUpdateInput}
        />
        <AddButton
          disabled={!newUserData.mail || !isValidEmail(newUserData.mail)}
          onClick={_addUser}
        >
          {T.common.add}
        </AddButton>
      </div>

      <ConfirmDeleteDialog
        onDelete={_confirmRemoveUser}
        itemName={deleteItem?.displayName}
        onModalClose={clearDeleteItem}
        isOpen={deleteItem != null}
        isLoading={removeUserMutation.isLoading}
      />
    </ActionModal>
  );
};

export default React.memo(ShareEctoplannerProjectModal);
