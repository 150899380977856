import { useMemo, useEffect, useState, useContext } from 'react';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API, {
  CancellablePromise,
  cancellablePromiseList,
  cancellablePromiseSequence,
  RequestDate,
  CancellablePromiseCallback
} from 'ecto-common/lib/API/API';
import _ from 'lodash';

import moment, { Moment } from 'moment';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';

import { createSignalNamesModel } from 'ecto-common/lib/Dashboard/modelforms/SignalNamesModelEditor';
import {
  createSignalAndSignalProviderMapping,
  CreateSignalAndSignalProviderMappingResult,
  nodeIdsWithEquipmentIds
} from 'ecto-common/lib/hooks/useAvailableSignals';

import {
  AggregationText,
  ValidAggregations
} from 'ecto-common/lib/types/Aggregation';
import {
  AggregationType,
  SamplingInterval,
  SignalTypeResponseModel,
  TelemetryResponseModel,
  TelemetryValueResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SamplingIntervalText } from 'ecto-common/lib/types/SamplingInterval';

import NodesDataSource, {
  nodesDataSourceSections
} from 'ecto-common/lib/Dashboard/datasources/NodesDataSource';
import SectionListPriority from 'ecto-common/lib/Dashboard/SectionListPriority';
import useTimeRange from 'ecto-common/lib/Dashboard/context/useTimeRange';
import {
  getSignalColor,
  shadeColor
} from 'ecto-common/lib/SignalSelector/StockChart.config';
import {
  getSeriesName,
  TelemetrySeries
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { getSignalTypeUnitObject } from 'ecto-common/lib/SignalSelector/SignalUtils';
import {
  batchedGetSignalsForNodesPromise,
  PromiseCacheContext
} from 'ecto-common/lib/Dashboard/datasources/signalUtils';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { TimeRangeOptions } from 'ecto-common/lib/types/TimeRangeOptions';
import {
  EquipmentResponseModel,
  SignalProviderByNodeResponseModel,
  SignalResponseModel
} from 'ecto-common/lib/API/APIGen';
import {
  EmptyMatchSignalsForNodesResult,
  SignalInputType
} from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { ApiContextSettings } from '../../API/APIUtils';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import {
  getPathFromModelKeyFunc,
  getPathStringFromModelKeyFunc
} from 'ecto-common/lib/ModelForm/formUtils';

export const hoursRange = (hoursBackwards: number, hoursForward: number) =>
  Object.freeze({
    dateFrom: moment().add(-hoursBackwards, 'hours'),
    dateTo: moment().add(hoursForward, 'hours')
  });

/**
 * @typedef SignalMatchInfo
 * @property {string} signalProviderType
 * @property {string} equipmentTypeId
 * @property {string} signalTypeId
 */
/**
 * Will filter and append signalInfo to nodeSignals by matching with any of the items in signalsToFind = [ { signalProviderType, equipmentTypeId, signalTypeId }]
 * if an item in signalsToFind have signalProviderName empty then it will match with signalTypeId, signalProviderType (optional) and equipmentTypeId (optional, only valid if signalProviderType === Equipment)
 * @param {SignalInfo} signalsForNode array of signal information from node (from server)
 * @param {SignalMatchInfo[]} signalsToFind array of signal 'filter' to match signal information from node
 * @param {Object.<string, Object>} equipmentMap mapping of all available equipments
 * @param {string} grid the current grid to search equipments in
 * @return [{ signal, signalInfo }] array of node signal + matching signal info data that is used to match signal (signalInfo)
 */
export type MatchedSignalInput = SignalInputType & {
  matchIndex: number;
};

export type MatchedSignal = {
  signal: SignalResponseModel;
  signalInfo: MatchedSignalInput;
};

export const findMatchingSignals = (
  signalsForNode: MatchSignalsForNodesResult,
  signalsToFind: SignalInputType[],
  equipmentMap: Record<string, EquipmentResponseModel>
): MatchedSignal[] => {
  if (signalsToFind == null) {
    return [];
  }

  const nodeSignals = _.map(signalsForNode?.signals);

  return _.reduce<SignalResponseModel, MatchedSignal[]>(
    nodeSignals,
    (result, nodeSignal) => {
      const signalProvider =
        signalsForNode.signalProviders[
          signalsForNode.signalIdToProviderId[nodeSignal.signalId]
        ];

      // Concat all matching signals
      return _.reduce(
        signalsToFind,
        (foundSignals, signalInput, matchIndex) => {
          if (
            !_.isEmpty(signalInput.signalProviderType) &&
            signalInput.signalProviderType !== signalProvider.signalProviderType
          ) {
            return foundSignals;
          }

          if (!_.isEmpty(signalInput.equipmentTypeId)) {
            const equipmentObj = getNodeFromMap(
              equipmentMap,
              _.head(signalProvider.nodeIds)
            );
            if (equipmentObj?.equipmentTypeId !== signalInput.equipmentTypeId) {
              return foundSignals;
            }
          }
          if (signalInput.signalTypeId === nodeSignal.signalTypeId) {
            foundSignals.push({
              signal: nodeSignal,
              signalInfo: { ...signalInput, matchIndex }
            });
          }
          return foundSignals;
        },
        result
      );
    },
    []
  );
};

const DEFAULT_HOURS_BACKWARD: number = null;

const getSignalValuesForIds = (
  contextSettings: ApiContextSettings,
  signalIds: string[],
  dateFrom: RequestDate,
  dateTo: RequestDate,
  samplingInterval: SamplingInterval,
  aggregation: AggregationType,
  points: number,
  forceExactValues: boolean
) => {
  if (
    samplingInterval === SamplingInterval.Raw &&
    aggregation === AggregationType.None &&
    !forceExactValues
  ) {
    return API.Signals.getSignalValues(
      contextSettings,
      signalIds,
      dateFrom,
      dateTo,
      points
    );
  }

  return API.Signals.getSignalValuesTimeRange(
    contextSettings,
    signalIds,
    dateFrom,
    dateTo,
    samplingInterval,
    aggregation,
    true
  );
};

export type MatchSignalsForNodesResult =
  CreateSignalAndSignalProviderMappingResult & {
    signalInputs: SignalInputType[];
    matchingSignals: MatchedSignal[];
    timeRange: TimeRangeOptions;
  };

export type MatchSignalsResult = {
  signalsForNodes: MatchSignalsForNodesResult;
  filteredSignalInfos: SignalInputType[];
  matchingSignals: MatchedSignal[];
};

export const matchSignalsFromResult = (
  signalProvidersWithSignals: SignalProviderByNodeResponseModel[],
  signalInputs: SignalInputType[],
  timeRange: TimeRangeOptions,
  equipmentMap: Record<string, EquipmentResponseModel>
): MatchSignalsResult => {
  const signalsForNodes: MatchSignalsForNodesResult = {
    ...createSignalAndSignalProviderMapping(
      signalProvidersWithSignals,
      equipmentMap
    ),
    signalInputs: signalInputs,
    timeRange,
    matchingSignals: []
  };

  const filteredSignalInputs = timeRange
    ? _.filter(signalInputs, (signalInfo) => {
        return (
          signalInfo.timeRange == null || signalInfo.timeRange === timeRange
        );
      })
    : signalInputs;

  const matchingSignals = findMatchingSignals(
    signalsForNodes,
    filteredSignalInputs,
    equipmentMap
  );

  signalsForNodes.matchingSignals = matchingSignals;

  return {
    signalsForNodes,
    matchingSignals,
    filteredSignalInfos: filteredSignalInputs
  };
};

/**
 * Sort the values we receive from the backend based on the order in which the signals were specified
 * in the panel. Raw data only contains signal id, value and timestamp - find corresponding full signal
 * object in matchingSignals, get the associated signal info from that object, and then find out which
 * index it had in the original signalInfo array (filteredSignalInfos). Use those indices to sort the
 * values.
 *
 * @param values The raw signal values received from backend
 * @param filteredSignalInfos The ordered signal definitions with non-matching definitions removed
 * @param matchingSignals The full signal object (contains associated signal info object)
 */
interface ItemWithSignalInput {
  signalInput: MatchedSignalInput;
}

export function getSortedValues<SignalWithInput extends ItemWithSignalInput>(
  values: SignalWithInput[]
): SignalWithInput[] {
  return _.sortBy(values, (value) => value.signalInput.matchIndex);
}

type GetSignalValuesForNodesParams = {
  nodes: SingleGridNode[];
  signals: SignalInputType[];
  dateFrom: Moment;
  dateTo: Moment;
  timeRange: TimeRangeOptions;
  samplingInterval: SamplingInterval;
  aggregation: AggregationType;
  width: number;
  forceExactValues: boolean;
  isAdmin: boolean;
  equipmentMap: Record<string, EquipmentResponseModel>;
  cacheContext: PromiseCacheContext;
};

type TelemetryResponseModelWithSignalInput = TelemetryResponseModel & {
  signalInput: MatchedSignalInput;
};

/**
 * Fetch signals and values from server that matches the filter in 'signals'
 * @param nodes Search these nodes for signals
 * @param signals Match signal on the nodes to this
 * @param dateFrom Start date of signal values
 * @param dateTo End date of signal values
 * @param timeRange Query only signal values that matches this time range
 * @param samplingInterval Sampling interval
 * @param aggregation Aggregation
 * @param size Width and height in pixels of the view window to present the data in
 * @param forceExactValues With this value we will disable resolution sampling when fetching raw values - gives us the
 *                         exact values in Influx, but at the cost of potentially very large data sets.
 * @param isAdmin Whether or not we're inside the admin app
 * @param equipmentMap Key mapped list over given equipments
 * @param grid specifies which grid that is currently active
 * @returns {*|Promise<{signalsForNodes: {signals: {}, signalProviders: {}, signalIdToProviderId: {}}, values: *[]}>}
 */
export type GetSignalValuesForNodeResult = {
  signalsForNodes: MatchSignalsForNodesResult;
  values: TelemetryResponseModelWithSignalInput[];
};

const getSignalValuesForNodes = (
  contextSettings: ApiContextSettings,
  {
    nodes,
    signals,
    dateFrom,
    dateTo,
    timeRange,
    samplingInterval,
    aggregation,
    width,
    forceExactValues,
    isAdmin,
    equipmentMap,
    cacheContext
  }: GetSignalValuesForNodesParams
): CancellablePromise<GetSignalValuesForNodeResult> => {
  const ids = nodeIdsWithEquipmentIds(nodes);
  if (_.isEmpty(ids)) {
    return Promise.resolve({
      signalsForNodes: { ...EmptyMatchSignalsForNodesResult, timeRange },
      values: []
    });
  }

  const nodesPromise = batchedGetSignalsForNodesPromise(
    contextSettings,
    isAdmin
      ? API.Admin.Signals.getSignalsForNodeIds
      : API.Signals.getSignalsForNodeIds,
    cacheContext
  );

  return cancellablePromiseSequence(
    (withNextPromise: CancellablePromiseCallback) => {
      return withNextPromise(nodesPromise(ids)).then(
        (result: SignalProviderByNodeResponseModel[]) => {
          const { matchingSignals, signalsForNodes } = matchSignalsFromResult(
            result,
            signals,
            timeRange,
            equipmentMap
          );

          // Got matching signals
          // Group matching signals by samplingInterval and aggregation and create a promise per each group
          //
          // groups = { 'samplingInterval,aggregation': [ {}, ... ] }
          const groups = _.groupBy(matchingSignals, (signalData) => [
            signalData.signalInfo?.samplingInterval,
            signalData.signalInfo?.aggregation
          ]);
          const groupSignalInputs = _.values(groups);

          // Create one request per group
          const promises = _.map(groups, (groupValue) => {
            // groupValue is array of [{ signal: { signalId, ...etc }, signalInfo } ]
            const signalInfo = _.head(groupValue)?.signalInfo;

            return getSignalValuesForIds(
              contextSettings,
              _.flatMap(groupValue, 'signal.signalId'), // all values in groupValue have signal.signalId
              dateFrom,
              dateTo,
              signalInfo.samplingInterval ?? samplingInterval,
              signalInfo.aggregation ?? aggregation,
              width,
              forceExactValues
            );
          });

          return withNextPromise(cancellablePromiseList(promises)).then(
            (values: TelemetryResponseModel[][]) => {
              const valuesWithSignalInputs: TelemetryResponseModelWithSignalInput[] =
                _.flatMap(values, (value, index) => {
                  return _.map(value, (val) => {
                    const signalInput = groupSignalInputs[index].find(
                      (x) => x.signal.signalId === val.signalId
                    ).signalInfo;

                    return {
                      ...val,
                      signalInput
                    };
                  });
                });

              const sortedValues = getSortedValues(valuesWithSignalInputs);
              return { signalsForNodes, values: sortedValues };
            }
          );
        }
      );
    }
  );
};

export const getUniqueTimeRanges = (signals: SignalInputType[]) => {
  return _(signals).map('timeRange').compact().uniqBy(_.identity).value();
};

export type SignalValuesDataSourceResult = {
  isLoading: boolean;
  hasError: boolean;
  hasPointsOverflow: boolean;
  samplingInterval: SamplingInterval;
  signalValues: TelemetryResponseModelWithSignalInput[];
  signalInfo: MatchSignalsForNodesResult;
  dateFrom: Moment;
  dateTo: Moment;
};

export type SignalValuesDataSourceProps = {
  nodeId?: string;
  nodeIds?: string[];
  signals: SignalInputType[];
  hoursBackward?: number;
  hoursForward?: number;
  reloadTrigger?: number;
  samplingInterval?: SamplingInterval;
  aggregation?: AggregationType;
  size?: { width: number; height: number };
  cacheContext?: PromiseCacheContext;
  forceExactValues?: boolean;
};

/**
 * Fetches signal values
 * @returns {{isLoading: boolean, signals: [], signalInfo: Dictionary<unknown>, dateTo: *, hasError: boolean, dateFrom: *}}
 */
const SignalValuesDataSource = ({
  // Keep for backward compatibility (old node source)
  nodeId,

  // New nodes source uses multi nodes
  nodeIds,

  signals,
  hoursBackward = DEFAULT_HOURS_BACKWARD,
  hoursForward,
  reloadTrigger,
  samplingInterval,
  aggregation,
  size,
  cacheContext,
  forceExactValues = false
}: SignalValuesDataSourceProps): SignalValuesDataSourceResult => {
  const _nodeIdsProps = useMemo(
    () => ({ nodeIds: nodeIds ?? _.compact([nodeId]) }),
    [nodeId, nodeIds]
  );

  const specifiedTimeRanges = useMemo(
    () => getUniqueTimeRanges(signals),
    [signals]
  );

  const nodes = NodesDataSource(_nodeIdsProps);
  const [hasError, setError] = useState(false);
  const [hasPointsOverflow, setHasPointsOverflow] = useState(false);
  const [signalValues, setSignalValues] = useState<
    TelemetryResponseModelWithSignalInput[]
  >([]);
  const [signalsForNodes, setSignalsForNodes] =
    useState<MatchSignalsForNodesResult>({
      ...EmptyMatchSignalsForNodesResult,
      signalInputs: signals
    });
  const { isAdmin, equipmentMap } = useContext(DashboardDataContext);

  const [isLoading, getSignalValues, cancelGetValues] = usePromiseCall({
    promise: getSignalValuesForNodes,
    onSuccess: (result) => {
      setSignalsForNodes(result.signalsForNodes);

      const newValues = result.values.map((valueSet) => ({
        ...valueSet,
        signals: valueSet.signals
      }));

      setHasPointsOverflow(false);
      setSignalValues(newValues);
    },
    onError: () => {
      setError(true);
    }
  });

  useEffect(() => {
    if (isLoading) {
      setError(false);
    }
  }, [isLoading]);

  const {
    dateFrom,
    dateTo,
    timeRangeOption,
    samplingInterval: _samplingInterval,
    aggregation: _aggregation
  } = useTimeRange(
    hoursBackward,
    hoursForward,
    signals,
    samplingInterval,
    aggregation,
    specifiedTimeRanges,
    reloadTrigger
  );

  useEffect(() => {
    setSignalsForNodes({
      ...EmptyMatchSignalsForNodesResult,
      signalInputs: signals
    });
    setSignalValues([]);
  }, [nodes, signals]);

  // Load all signal values for the selected signal ids
  useEffect(() => {
    if (!_.isEmpty(nodes) && !_.isEmpty(signals) && size.width != null) {
      getSignalValues({
        nodes,
        signals,
        dateFrom,
        dateTo,
        timeRange: timeRangeOption,
        samplingInterval: _samplingInterval,
        aggregation: _aggregation,
        width: size.width,
        forceExactValues,
        isAdmin,
        equipmentMap,
        cacheContext
      });

      return () => {
        cancelGetValues();
      };
    }

    cancelGetValues();
    setSignalValues([]);
  }, [
    getSignalValues,
    cancelGetValues,
    signals,
    nodes,
    dateFrom,
    dateTo,
    timeRangeOption,
    _samplingInterval,
    _aggregation,
    size.width,
    forceExactValues,
    isAdmin,
    equipmentMap,
    cacheContext
  ]);

  useEffect(() => {
    return () => {
      cancelGetValues();
    };
  }, [cancelGetValues]);

  return useMemo(() => {
    return {
      signalValues,
      signalInfo: signalsForNodes ?? {
        ...EmptyMatchSignalsForNodesResult,
        signalInputs: signals
      },
      isLoading,
      hasError,
      dateFrom,
      dateTo,
      hasPointsOverflow,
      samplingInterval: _samplingInterval
    };
  }, [
    signalValues,
    signalsForNodes,
    isLoading,
    hasError,
    dateFrom,
    dateTo,
    _samplingInterval,
    signals,
    hasPointsOverflow
  ]);
};

type ObjectWithSignalAggregationSettings = {
  aggregation?: AggregationType;
  samplingInterval?: SamplingInterval;
};

/**
 * Both global options and optional details for signals.
 * Used as global options for signals that does not have these options set specifically.
 * @param enableTimeRangeBasedOption - Whether or not to show the setting "Time range based". Currently only valid for dashboards
 * @param isClearable - Whether to allow the sampling interval to be cleared. Set implicitly when enableTimeRangeBasedOption is true
 * @param placeholderText - Placeholder text to show when value not set. Automatically set if enableTimeRangeBasedOption is true
 * TODO: Our graph editor should also use time ranges
 */
export const getSignalAggregationModels = (
  enableTimeRangeBasedOption: boolean,
  isClearable = false,
  placeholderText: string = null
): ModelDefinition<ObjectWithSignalAggregationSettings>[] => [
  {
    key: (input) => input.samplingInterval,
    label: T.graphs.exportdialog.samplinginterval,
    modelType: ModelType.OPTIONS,
    options: _.compact([
      enableTimeRangeBasedOption && {
        label: T.admin.dashboards.datasources.proptexts.defaulttotimerange,
        value: null
      },
      ..._.map(SamplingIntervalText, (label, value) => ({ label, value }))
    ]),
    isClearable: enableTimeRangeBasedOption || isClearable,
    hasError: (
      value: string,
      input: ObjectWithSignalAggregationSettings,
      _unused,
      model
    ) => {
      // Since the key can either be nested under 'targets.values.samplingInterval' (for data source)
      // or just specified directly like 'samplingInterval' (for signal settings) we replace the
      // key identifier here to support both use cases. Not ideal.
      const name = getPathFromModelKeyFunc(model.key);
      const aggregationKey = [...name];
      aggregationKey[aggregationKey.length - 1] = 'aggregation';
      return (
        value === SamplingInterval.Raw &&
        _.get(input, aggregationKey) !== AggregationType.None
      );
    },
    placeholder: enableTimeRangeBasedOption
      ? T.admin.dashboards.datasources.proptexts.defaulttotimerange
      : placeholderText ?? SamplingIntervalText[SamplingInterval.Raw],
    onDidUpdate: (
      name: string[],
      value: SamplingInterval,
      input: ObjectWithSignalAggregationSettings
    ) => {
      // Here we can't use a keypath string since the key is an array, so we have to swap
      // the last part of the array to get the corresponding aggregation field
      const aggregationKey = [...name];
      aggregationKey[aggregationKey.length - 1] = 'aggregation';

      // Using _.get in a key function is also OK, it will correctly deduce the keypath
      if (value === null) {
        return [[(formInput) => _.get(formInput, aggregationKey), null]];
      } else if (value === SamplingInterval.Raw) {
        return [
          [
            (formInput) => _.get(formInput, aggregationKey),
            AggregationType.None
          ]
        ];
      } else if (
        !input.aggregation ||
        input.aggregation === AggregationType.None
      ) {
        return [
          [
            (formInput) => _.get(formInput, aggregationKey),
            AggregationType.Mean
          ]
        ];
      }
    }
  },
  {
    key: (input) => input.aggregation,
    modelType: ModelType.OPTIONS,
    label: T.graphs.exportdialog.aggregationtitle,
    options: _.compact([
      enableTimeRangeBasedOption && {
        label: T.admin.dashboards.datasources.proptexts.defaulttotimerange,
        value: undefined
      },
      ..._.map(ValidAggregations, (value) => ({
        label: AggregationText[value],
        value
      }))
    ]),
    placeholder: enableTimeRangeBasedOption
      ? T.admin.dashboards.datasources.proptexts.defaulttotimerange
      : placeholderText ?? AggregationText[AggregationType.None],
    isMultiOption: false,
    isClearable: enableTimeRangeBasedOption,
    enabled: (input, _unused, model) => {
      const key = getPathStringFromModelKeyFunc(model.key);
      const samplingKey = key.replace('aggregation', 'samplingInterval');
      const samplingInterval = _.get(input, samplingKey);
      return (
        samplingInterval !== SamplingInterval.Raw &&
        (enableTimeRangeBasedOption || samplingInterval != null)
      );
    },
    hasError: (value, input, _unused, model) => {
      const key = getPathStringFromModelKeyFunc(model.key);
      const samplingKey = key.replace('aggregation', 'samplingInterval');
      return (
        value === AggregationType.None &&
        _.get(input, samplingKey) !== SamplingInterval.Raw
      );
    }
  }
];

type SignalValuesDataSourceArgs = {
  minItems: number;
  optionalSignalModels: ModelDefinition<SignalInputType>[];
};

export const signalValuesDataSourceSections: (
  props: SignalValuesDataSourceArgs
) => ModelFormSectionType<SignalValuesDataSourceProps>[] = ({
  minItems,
  optionalSignalModels
}) => [
  ...nodesDataSourceSections(),
  {
    label: T.admin.dashboards.sections.aggregation,
    initiallyCollapsed: true,
    lines: [
      {
        models: [
          ...getSignalAggregationModels(true),
          {
            key: (input) => input.forceExactValues,
            modelType: ModelType.BOOL,
            label: T.admin.dashboards.datasources.proptexts.forceexactvalues,
            isHorizontal: false
          }
        ]
      }
    ]
  },
  {
    label: T.admin.dashboards.sections.datarange,
    lines: [
      {
        models: [
          {
            key: (input) => input.hoursBackward,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.datasources.proptexts.hoursbackward,
            placeholder:
              T.admin.dashboards.datasources.proptexts.hoursbackplaceholder,
            hasError: (value: number) => value < 0
          },
          {
            key: (input) => input.hoursForward,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.datasources.proptexts.hoursforward,
            placeholder: '0',
            hasError: (value: number) => value < 0
          }
        ]
      }
    ],
    listPriority: SectionListPriority.SignalsDataRange // Should always be together with signals
  },
  {
    label: T.admin.dashboards.sections.signals,
    lines: [
      {
        models: [createSignalNamesModel(minItems, optionalSignalModels)]
      }
    ],
    listPriority: SectionListPriority.Signals // Should always be last since it looks odd otherwise
  }
];

const convertToGraphValue = ({ time, value }: TelemetryValueResponseModel) => [
  new Date(time).getTime(),
  value
];

export const createTelemetryFromSignalValues = (
  signalInfo: MatchSignalsForNodesResult,
  signalValues: TelemetryResponseModelWithSignalInput[],
  nodeMap: Record<string, SingleGridNode>,
  equipmentMap: Record<string, EquipmentResponseModel>,
  signalTypesMap: Record<string, SignalTypeResponseModel>,
  signalUnitTypesMap: Record<string, UnitResponseModel>
): TelemetrySeries[] => {
  // Create a new signal setting that also keep count of number of usages of the default setting
  // that way we can create a new shade of colors that are used more than once
  // Ex: color1.usage: 3 => shade color 1 in 3 different ways
  const usageCount: Record<string, number> = {};

  return _.reduce(
    signalValues,
    (telemetryData, values, index) => {
      const signal = signalInfo.signals[values.signalId];
      if (signal) {
        const signalProvider =
          signalInfo.signalProviders[
            signalInfo.signalIdToProviderId[signal.signalId]
          ];
        const signalSetting = values.signalInput;
        const unit = getSignalTypeUnitObject(
          signal.signalTypeId,
          signalTypesMap,
          signalUnitTypesMap
        );
        const identifier = signalSetting.id + '-' + signalSetting.matchIndex;
        const usage = usageCount[identifier] ?? 1;

        telemetryData.push({
          signal,
          groupName: signalProvider.signalProviderName,
          chartSignalId: signal.signalId, // Not 100% accurate, but we don't have a better id to use
          unitId: unit?.id ?? '',
          unit: unit?.unit ?? '',
          color:
            shadeColor(signalSetting?.color, usage) ??
            getSignalColor(null, index),
          name: getSeriesName(
            signal,
            signalProvider,
            nodeMap,
            equipmentMap,
            signalTypesMap,
            signalUnitTypesMap
          ),
          dataFormat: signal.dataFormat,
          data: _.map(values.signals, convertToGraphValue)
        });

        usageCount[identifier] = usage + 1;
      }
      return telemetryData;
    },
    [] as TelemetrySeries[]
  );
};

export default SignalValuesDataSource;
