import _ from 'lodash';
import panels from 'ecto-common/lib/Dashboard/panels';
import { DashboardFile } from 'ecto-common/lib/DashboardEditor/DashboardConstants';
import {
  DashboardCollectionViewResponseModel,
  GetEnumsAndFixedConfigurationsResponseModel,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';

export const applyDashboardMigrations = (
  originalData: DashboardFile,
  enums: GetEnumsAndFixedConfigurationsResponseModel,
  signalTypesNameMap: Record<string, SignalTypeResponseModel>
): DashboardFile => {
  const data = _.cloneDeep(originalData);
  data.panels = _.reject(data.panels, (panel) => !panels?.[panel.type]);

  // Make sure that all panel ID:s are unique. This should never happen
  // in practice, but we had a bug previously that could generate duplicate
  // ID:s, so we keep it here for safety.
  data.panels = _.uniqBy(data.panels, (panel) => panel.id);

  _.forEach(data.panels, (panel) => {
    const currentVersion = panel.version ?? 0;
    const migrations = panels[panel.type].data.migrations;
    const validMigrations = _.filter(
      migrations,
      (migrationData) => migrationData.version > currentVersion
    );

    _.forEach(validMigrations, (migrationData) => {
      migrationData.migration(panel, enums, signalTypesNameMap);
      panel.version = migrationData.version;
    });
  });

  return data;
};

export const applyDashboardMigrationsToCollection = (
  collection: DashboardCollectionViewResponseModel,
  enums: GetEnumsAndFixedConfigurationsResponseModel,
  signalTypesNameMap: Record<string, SignalTypeResponseModel>
): DashboardCollectionViewResponseModel => {
  const data = _.cloneDeep(collection);

  _.forEach(data.dashboards, (dashboard) => {
    dashboard.jsonData = applyDashboardMigrations(
      dashboard.jsonData,
      enums,
      signalTypesNameMap
    );
  });

  return data;
};
