import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Navigate, matchPath, useNavigate, useParams } from 'react-router-dom';

import {
  EctoplannerParams,
  EctoplannerRoute,
  ectoplannerMasterBuildId,
  getEctoplannerUrl
} from 'js/utils/routeConstants';
import EctoplannerAPIGen, {
  BuildResponse,
  BuildResponseListResponse,
  ProjectResponse,
  SetBuildDescriptionRequest
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import T from 'ecto-common/lib/lang/Language';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';
import {
  EctoplannerForm,
  SecosimForm
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import _ from 'lodash';
import {
  insertEctoplannerDefaultValues,
  insertSecosimDefaultValues
} from './EctoplannerModelUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import {
  BuildEvent,
  useBuildUpdates
} from 'js/components/Ectoplanner/useBuildUpdates';
import { usePromptFunction } from 'ecto-common/lib/hooks/useBlockerListener';
import CopyToClipboardTooltip from 'ecto-common/lib/CopyToClipboardTooltip/CopyToClipboardTooltip';
import {
  EctoplannerAddNewToolbarMenuButton,
  EctoplannerNavigationTree,
  useEctoplannerPersistentBuilds
} from 'js/components/Ectoplanner/EctoplannerNavigationControls';
import ToolbarNavControl from 'ecto-common/lib/ToolbarContentPage/ToolbarNavControl';
import Icons from 'ecto-common/lib/Icons/Icons';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import Spinner from 'ecto-common/lib/Spinner/Spinner';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import {
  EctoplannerProjectType,
  EctoplannerProjectTypes
} from 'js/components/Ectoplanner/EctoplannerTypes';
import ModelFormDialog from 'ecto-common/lib/ModelForm/ModelFormDialog';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import ShareEctoplannerProjectModal from 'js/components/Ectoplanner/ShareEctoplannerProjectModal';
import EditSecosimProject from 'js/components/Ectoplanner/EditSecosimProject';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import {
  buildIdIsNullOrMaster,
  defaultGetEctoplannerBuildArgs
} from 'js/components/Ectoplanner/EctoplannerUtils';
import { ObjectValues } from 'ecto-common/lib/utils/typescriptUtils';
import createEctoplannerGraphStore, {
  EctoplannerGraphStoreContext,
  EctoplannerGraphStoreType,
  emptyEctoplannerGraphStore
} from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerGraphStore';
import { useStore } from 'zustand';
import EditEctoplannerProject from 'js/components/Ectoplanner/EditEctoplannerProject';
import moment from 'moment';
import { downloadBlob } from 'ecto-common/lib/utils/downloadBlob';
import defaultSecosimFormUrl from 'js/components/Ectoplanner/assets/defaultSecosimForm.jsonasset';

const CreateFormProjectTypes = {
  BlankProject: 'blankproject',
  CopyProject: 'copyproject',
  Version: 'version'
} as const;

type CreateFormProjectType = ObjectValues<typeof CreateFormProjectTypes>;

const allStores: Record<string, Record<string, EctoplannerGraphStoreType>> = {};

const emptyProjects: ProjectResponse[] = [];
const ECTOPLANNER_LAST_PROJECT = 'ectoplanner_last_project';

const useRememberLastProject = () => {
  const params = useParams<EctoplannerParams>();
  const ref = useRef(params.projectId);
  if (ref.current !== params.projectId) {
    ref.current = params.projectId;
    localStorage.setItem(ECTOPLANNER_LAST_PROJECT, params.projectId);
  }
};

// To be compatible with ModelFormDialog
type SetBuildDescriptionRequestWithId = SetBuildDescriptionRequest & {
  id: string;
};

const buildModels: ModelDefinition<SetBuildDescriptionRequestWithId>[] = [
  {
    key: (input) => input.description,
    modelType: ModelType.TEXT,
    label: T.ectoplanner.calculations.description,
    hasError: isNullOrWhitespace,
    autoFocus: true
  }
];

type CreateFormSettings = {
  id: string;
  projectType: EctoplannerProjectType;
  description: string;
  type: CreateFormProjectType;
};

const EctoplannerProjectContent = ({
  projectId,
  buildId
}: {
  projectId: string;
  buildId: string;
}) => {
  const allProjectsQuery =
    EctoplannerAPIGen.EctoGridProjects.listProjects.useQuery({});

  useRememberLastProject();

  const weatherCountriesQuery =
    EctoplannerAPIGen.Weather.countriesList.useQuery(
      {},
      {
        refetchOnWindowFocus: false
      }
    );
  const { contextSettings } = useContext(TenantContext);
  const [changedForms, setChangedForms] = useState<Record<string, boolean>>({});
  const [migratedForms, setMigratedForms] = useState<Record<string, boolean>>(
    {}
  );
  const params = useParams<EctoplannerParams>();
  const [isShowingShareDialog, showShareDialog, hideShareDialog] =
    useDialogState('show-share-dialog');
  const [isShowingDeleteDialog, showDeleteDialog, hideDeleteDialog] =
    useDialogState('show-delete-dialog');
  const isEditingProject = buildIdIsNullOrMaster(params.buildId);
  const [updateDescriptionRequest, setUpdateDescriptionRequest] =
    useState<SetBuildDescriptionRequestWithId>(null);

  const exportBuildMutation =
    EctoplannerAPIGen.EctoGridProjects.buildsExportDetail.useMutation(
      {
        projectId: projectId,
        buildId: buildId
      },
      {
        onSuccess: (blob) => {
          const timestamp = moment().format('YYYY-MM-DD HH.mm');
          downloadBlob(
            blob,
            'Ectoplanner ' +
              selectedBuild?.description +
              ' - ' +
              timestamp +
              '.zip'
          );
        },
        onError: () => {
          toastStore.addErrorToast(T.ectoplanner.failedtoloadproject);
        }
      }
    );

  const exportProjectMutation =
    EctoplannerAPIGen.EctoGridProjects.exportDetail.useMutation(
      { projectId: projectId },
      {
        onSuccess: (blob) => {
          const timestamp = moment().format('YYYY-MM-DD HH.mm');
          downloadBlob(
            blob,
            'Ectoplanner ' + project?.name + ' - ' + timestamp + '.zip'
          );
        },
        onError: () => {
          toastStore.addErrorToast(T.ectoplanner.failedtoloadproject);
        }
      }
    );

  const [projectVersionStore, setProjectVersionStore] = useState<
    Record<string, unknown>
  >({});

  const versionId =
    params.buildId != null && params.buildId !== ectoplannerMasterBuildId
      ? params.buildId
      : params.projectId;

  const setForm: Dispatch<SetStateAction<unknown>> = useCallback(
    (updater) => {
      if (_.isFunction(updater)) {
        setProjectVersionStore((oldStore) => ({
          ...oldStore,
          [versionId]: updater(oldStore[versionId])
        }));
      } else {
        setProjectVersionStore((oldStore) => ({
          ...oldStore,
          [versionId]: updater
        }));
      }
    },
    [versionId]
  );

  const setFormFromUserInput: Dispatch<SetStateAction<unknown>> = useCallback(
    (updater) => {
      setForm(updater);
      setChangedForms((oldChangedForms) => ({
        ...oldChangedForms,
        [versionId]: true
      }));
    },
    [setForm, versionId]
  );

  const queryClient = useQueryClient();

  const onRecalculate = useCallback(
    (buildResponse: BuildResponse) => {
      queryClient.setQueryData<BuildResponseListResponse>(
        [
          ...EctoplannerAPIGen.EctoGridProjects.buildsDetail.path(
            contextSettings,
            {
              projectId: params.projectId
            }
          ),
          defaultGetEctoplannerBuildArgs
        ],
        (oldData) => {
          const newItems = [...oldData.items];
          const existingIndex = oldData.items.findIndex(
            (item) => item.id === buildResponse.id
          );
          if (existingIndex === -1) {
            newItems.push(buildResponse);
          } else {
            newItems[existingIndex] = buildResponse;
          }

          return {
            ...oldData,
            items: newItems
          };
        }
      );
    },
    [contextSettings, params.projectId, queryClient]
  );

  const [builds, buildsQueryIsLoading, buildsQueryError, setBuildStatuses] =
    useEctoplannerPersistentBuilds({ projectId: params.projectId });

  useBuildUpdates(
    useCallback(
      (event: BuildEvent) => {
        setBuildStatuses((oldStatuses) => ({
          ...oldStatuses,
          [event.buildId]: [event.status, new Date().toISOString()]
        }));
      },
      [setBuildStatuses]
    ),
    null
  );

  const [visitedProjectIds, setVisitedProjectIds] = useState<string[]>(
    params.projectId ? [params.projectId] : []
  );

  if (params.projectId && !visitedProjectIds.includes(params.projectId)) {
    setVisitedProjectIds((oldVisitedProjectIds) => [
      ...oldVisitedProjectIds,
      params.projectId
    ]);
  }

  const formData = projectVersionStore[versionId];
  const project = _.find(allProjectsQuery.data?.items, { id: projectId });

  const getDefaultSecosimFormQuery = useQuery(
    ['defaultSecosimForm'],
    () => fetch(defaultSecosimFormUrl).then((res) => res.json()),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: params.projectType === 'secosim'
    }
  );

  const getFormQuery = useQuery(
    ['EctoplannerForm', projectId, buildId],
    ({ signal }) => {
      const promise =
        buildId != null && buildId !== ectoplannerMasterBuildId
          ? EctoplannerAPIGen.EctoGridBuilds.formdataDetail.promise(
              contextSettings,
              {
                buildId
              },
              signal
            )
          : EctoplannerAPIGen.EctoGridProjects.formdataDetail.promise(
              contextSettings,
              { projectId: projectId },
              signal
            );

      return promise.catch((error) => {
        if (error?.response.status === HttpStatus.NOT_FOUND) {
          return {};
        }

        throw error;
      });
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: formData == null && project != null
    }
  );

  if (
    projectVersionStore[versionId] == null &&
    getFormQuery.data != null &&
    (getDefaultSecosimFormQuery.data != null ||
      params.projectType !== 'secosim')
  ) {
    let loadedForm: unknown = null;
    let loadedFormHasChanges = false;

    if (project.projectType === EctoplannerProjectTypes.Secosim) {
      [loadedForm, loadedFormHasChanges] = insertSecosimDefaultValues(
        getFormQuery.data as unknown as SecosimForm,
        getDefaultSecosimFormQuery.data as unknown as SecosimForm
      );
    } else {
      [loadedForm, loadedFormHasChanges] = insertEctoplannerDefaultValues(
        getFormQuery.data as unknown as EctoplannerForm
      );
    }

    setProjectVersionStore((oldStore) => ({
      ...oldStore,
      [versionId]: loadedForm
    }));
    setMigratedForms((oldChangedProjects) => ({
      ...oldChangedProjects,
      [versionId]: loadedFormHasChanges
    }));
  }

  const clearHasChanges = useCallback(() => {
    setMigratedForms((oldChangedProjects) => ({
      ...oldChangedProjects,
      [versionId]: false
    }));

    setChangedForms((oldChangedProjects) => ({
      ...oldChangedProjects,
      [versionId]: false
    }));
  }, [versionId]);

  const { tenantId } = useContext(TenantContext);
  const [newBuild, setNewBuild] = useState<CreateFormSettings>(null);
  const clearNewBuild = useCallback(() => setNewBuild(null), []);

  const addVersionMutation =
    EctoplannerAPIGen.EctoGridProjects.createBuild.useMutation(
      {
        projectId: params.projectId
      },
      {
        onSuccess: (build) => {
          queryClient.setQueryData<BuildResponseListResponse>(
            [
              ...EctoplannerAPIGen.EctoGridProjects.buildsDetail.path(
                contextSettings,
                {
                  projectId: params.projectId
                }
              ),
              defaultGetEctoplannerBuildArgs
            ],
            (oldData) => {
              return {
                ...oldData,
                items: [build, ...oldData.items]
              };
            }
          );

          navigate(
            getEctoplannerUrl(
              tenantId,
              params.projectType,
              params.projectId,
              build.id,
              params.section
            )
          );
          setNewBuild(null);
        }
      }
    );

  const editProjectNameMutation =
    EctoplannerAPIGen.EctoGridProjects.nameCreate.useMutation(
      {
        projectId: params.projectId
      },
      {
        onSuccess: () => {
          setUpdateDescriptionRequest(null);
          queryClient.invalidateQueries(
            EctoplannerAPIGen.EctoGridProjects.listProjects.path(
              contextSettings
            )
          );
        },
        onError: () => {
          toastStore.addErrorToast(T.common.unknownerror);
        }
      }
    );

  const editBuildDescriptionMutation =
    EctoplannerAPIGen.EctoGridBuilds.descriptionCreate.useMutation(
      {
        buildId: updateDescriptionRequest?.id
      },
      {
        onSuccess: (_ununused, args) => {
          setUpdateDescriptionRequest(null);
          queryClient.setQueryData<BuildResponseListResponse>(
            [
              ...EctoplannerAPIGen.EctoGridProjects.buildsDetail.path(
                contextSettings,
                {
                  projectId: params.projectId
                }
              ),
              defaultGetEctoplannerBuildArgs
            ],
            (oldData) => {
              const indexToUpdate = oldData.items.findIndex(
                (item) => item.id === selectedBuild.id
              );

              if (indexToUpdate !== -1) {
                const newItems = [...oldData.items];
                newItems[indexToUpdate] = {
                  ...newItems[indexToUpdate],
                  description: args.description
                };

                return {
                  ...oldData,
                  items: newItems
                };
              }

              return oldData;
            }
          );
        },
        onError: () => {
          setUpdateDescriptionRequest(null);
          toastStore.addErrorToast(T.common.unknownerror);
        }
      }
    );

  const deleteProjectMutation =
    EctoplannerAPIGen.EctoGridProjects.delete.useMutation(
      {
        projectId: params.projectId
      },
      {
        onSuccess: () => {
          const nextProject = allProjects.find(
            (x) => x.id !== params.projectId
          );

          hideDeleteDialog();

          queryClient.invalidateQueries(
            EctoplannerAPIGen.EctoGridProjects.listProjects.path(
              contextSettings
            )
          );

          navigate(
            getEctoplannerUrl(tenantId, params.projectType, nextProject?.id)
          );
        },
        onError: (e) => {
          // TODO: Improve error signature
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e as any).response?.status === HttpStatus.CONFLICT) {
            toastStore.addErrorToast(T.ectoplanner.share.failedtodeleteproject);
          } else {
            toastStore.addErrorToast(T.common.unknownerror);
          }
        }
      }
    );

  const deleteBuildMutation =
    EctoplannerAPIGen.EctoGridBuilds.delete.useMutation(
      {
        buildId: params.buildId
      },
      {
        onSuccess: () => {
          queryClient.setQueryData<BuildResponseListResponse>(
            [
              ...EctoplannerAPIGen.EctoGridProjects.buildsDetail.path(
                contextSettings,
                {
                  projectId: params.projectId
                }
              ),
              defaultGetEctoplannerBuildArgs
            ],
            (oldData) => {
              const newItems = oldData.items.filter(
                (item) => item.id !== params.buildId
              );
              return {
                ...oldData,
                items: newItems
              };
            }
          );

          navigate(
            getEctoplannerUrl(
              tenantId,
              params.projectType,
              params.projectId,
              ectoplannerMasterBuildId,
              params.section,
              params.itemId
            )
          );
          hideDeleteDialog();
        }
      }
    );

  useEffect(() => {
    if (project?.name != null) {
      document.title =
        T.ectoplanner.title + (project?.name ? ' > ' + project?.name : '');
    } else {
      document.title = T.ectoplanner.title;
    }
  }, [project?.name]);

  const confirmDelete = useCallback(() => {
    if (isEditingProject) {
      deleteProjectMutation.mutate();
    } else {
      deleteBuildMutation.mutate();
    }
  }, [deleteBuildMutation, deleteProjectMutation, isEditingProject]);

  const selectedBuild = isEditingProject
    ? builds.find(
        (build) => build.isMasterBuild && build.projectId === params.projectId
      )
    : builds.find((build) => build.id === params.buildId);

  const addProjectMutation =
    EctoplannerAPIGen.EctoGridProjects.create.useMutation({
      onSuccess: (res) => {
        setNewBuild(null);
        queryClient.invalidateQueries(
          EctoplannerAPIGen.EctoGridProjects.listProjects.path(contextSettings)
        );

        if (res.id !== params.projectId) {
          navigate(getEctoplannerUrl(tenantId, params.projectType, res.id));
        }
      },
      onError: () => {
        toastStore.addErrorToast(T.common.unknownerror);
      }
    });

  const allProjects = useMemo(() => {
    return sortByLocaleCompare(
      _.filter(
        allProjectsQuery.data?.items ?? emptyProjects,
        (filterProject) => filterProject.projectType === params.projectType
      ),
      'name'
    );
  }, [allProjectsQuery.data?.items, params.projectType]);

  const someProjectHasChanges = _.some(changedForms);

  const [projectTreeOpen, setProjectTreeOpen] = useState(true);

  const setProjectOpenWithResize = useCallback((newIsOpen: boolean) => {
    setProjectTreeOpen(newIsOpen);
    _.defer(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }, []);

  const currentWeatherStationId = (formData as EctoplannerForm)?.location?.city
    ?.id;

  const confirmAddNewVersion = useCallback(
    (build: CreateFormSettings) => {
      if (build.type === CreateFormProjectTypes.BlankProject) {
        addProjectMutation.mutate({
          name: build.description,
          exampleWeatherYear: 2021,
          projectType: build.projectType
        });
      } else if (build.type === CreateFormProjectTypes.CopyProject) {
        addProjectMutation.mutate({
          name: build.description,
          exampleWeatherYear: 2021,
          copySourceId: isEditingProject ? params.projectId : params.buildId,
          copyWeatherStationId: currentWeatherStationId,
          projectType: project?.projectType
        });
      } else if (build.type === CreateFormProjectTypes.Version) {
        addVersionMutation.mutate({
          description: build.description,
          projectId: params.projectId,
          calculateMasterBuild: false,
          sourceBuildId:
            params.buildId === ectoplannerMasterBuildId ? null : params.buildId,
          startBuild: false
        });
      }
    },
    [
      addProjectMutation,
      addVersionMutation,
      currentWeatherStationId,
      isEditingProject,
      params.buildId,
      params.projectId,
      project?.projectType
    ]
  );

  const newBuildModels: ModelDefinition<CreateFormSettings>[] = useMemo(() => {
    const sourceText =
      selectedBuild && !selectedBuild.isMasterBuild
        ? selectedBuild?.description
        : project?.name;
    const sourceHelpText =
      params.projectId == null
        ? ''
        : T.format(T.ectoplanner.datacopyformat, sourceText).join('');

    return _.compact([
      {
        key: (input) => input.description,
        label: T.ectoplanner.calculations.builds.description,
        modelType: ModelType.TEXT,
        hasError: isNullOrWhitespace,
        autoFocus: true
      },
      {
        key: (input) => input.type,
        label: T.ectoplanner.newtype,
        modelType: ModelType.CHECKBOX_OPTIONS,
        onDidUpdate: (_name, value, lastInput) => {
          if (
            lastInput.description !== T.ectoplanner.newbuildname &&
            lastInput.description !== T.ectoplanner.newprojectname
          ) {
            return [];
          }

          if (value === CreateFormProjectTypes.Version) {
            return [[(input) => input.description, T.ectoplanner.newbuildname]];
          }

          return [[(input) => input.description, T.ectoplanner.newprojectname]];
        },
        options: _.compact([
          params.projectId != null && {
            label: T.ectoplanner.newtypes.version,
            value: CreateFormProjectTypes.Version
          },
          params.projectId != null && {
            label: T.ectoplanner.newtypes.copyproject,
            value: CreateFormProjectTypes.CopyProject
          },
          {
            label: T.ectoplanner.newtypes.blankproject,
            value: CreateFormProjectTypes.BlankProject
          }
        ]),
        helpText: (value: string) => {
          return value === CreateFormProjectTypes.BlankProject
            ? undefined
            : sourceHelpText;
        }
      }
    ]);
  }, [params.projectId, project?.name, selectedBuild]);

  const [storeRef, setStoreRef] = useState<EctoplannerGraphStoreType>(null);

  const storeBuildId = useStore(
    storeRef ?? emptyEctoplannerGraphStore,
    (state) => state.buildId
  );

  const selectedBuildId = selectedBuild?.id;

  const collectionsQuery =
    EctoplannerAPIGen.EctoGridBuilds.graphsDetail.useQuery(
      {
        buildId: selectedBuildId
      },
      {
        enabled: selectedBuildId != null,
        refetchOnWindowFocus: false
      }
    );

  if (collectionsQuery.data != null && selectedBuild != null) {
    if (storeRef == null || storeBuildId !== selectedBuildId) {
      const newStore =
        allStores[params.projectId]?.[selectedBuildId] ??
        createEctoplannerGraphStore(
          collectionsQuery.data.items,
          selectedBuildId,
          false
        );

      allStores[params.projectId] = {
        ...allStores[params.projectId],
        [selectedBuildId]: newStore
      };

      setStoreRef(newStore);
    }
  }

  const navTitle = (
    <div>
      <CopyToClipboardTooltip valueToCopy={project?.name}>
        <label>{project?.name}</label>
      </CopyToClipboardTooltip>
    </div>
  );

  const navigate = useNavigate();

  const onNavigateProject = useCallback(
    (newProjectId: string) => {
      navigate(
        getEctoplannerUrl(
          tenantId,
          params.projectType,
          newProjectId,
          ectoplannerMasterBuildId,
          params.section,
          params.itemId
        )
      );
    },
    [navigate, params.itemId, params.projectType, params.section, tenantId]
  );

  const onNavigateBuild = useCallback(
    (newProjectId: string, newBuildId: string) => {
      navigate(
        getEctoplannerUrl(
          tenantId,
          params.projectType,
          newProjectId,
          newBuildId,
          params.section,
          params.itemId
        )
      );
    },
    [navigate, params.itemId, params.projectType, params.section, tenantId]
  );

  const navigationTree = projectTreeOpen && (
    <EctoplannerNavigationTree
      builds={builds}
      allProjects={allProjects}
      isLoadingBuildsForProject={buildsQueryIsLoading}
      changedForms={changedForms}
      onNavigateProject={onNavigateProject}
      onNavigateBuild={onNavigateBuild}
      projectId={params.projectId}
      buildId={params.buildId}
    />
  );

  const navigationControl =
    allProjects.length === 0 ? undefined : (
      <div>
        <ToolbarNavControl
          title={navTitle}
          icon={params.projectId != null && <Icons.File />}
          subtitle={
            selectedBuild?.description ??
            (params.projectId != null &&
              isEditingProject &&
              T.ectoplanner.currentversion)
          }
          isOpen={projectTreeOpen}
          setIsOpen={setProjectOpenWithResize}
        />
      </div>
    );

  const onClickAddNew = useCallback(() => {
    setNewBuild({
      description:
        params.projectId === null
          ? T.ectoplanner.newprojectname
          : T.ectoplanner.newbuildname,
      type:
        params.projectId == null
          ? CreateFormProjectTypes.BlankProject
          : CreateFormProjectTypes.Version,
      id: null,
      projectType: params.projectType as EctoplannerProjectType
    });
  }, [params.projectId, params.projectType]);

  const onClickEditName = useCallback(() => {
    setUpdateDescriptionRequest({
      description: isEditingProject
        ? project?.name
        : selectedBuild?.description,
      id: isEditingProject ? params.projectId : params.buildId
    });
  }, [
    isEditingProject,
    params.buildId,
    params.projectId,
    project?.name,
    selectedBuild?.description
  ]);

  usePromptFunction(
    useCallback(({ nextLocation }) => {
      const newParams = matchPath<
        EctoplannerParams,
        typeof EctoplannerRoute.path
      >(EctoplannerRoute.path, nextLocation.pathname)?.params;

      return newParams == null ? T.admin.form.unsavedstate : null;
    }, []),
    someProjectHasChanges
  );

  if (allProjects.length > 0 && params.projectId == null) {
    let projectToSwitch = allProjects[0];
    const lastProjectId = localStorage.getItem(ECTOPLANNER_LAST_PROJECT);
    if (lastProjectId != null) {
      projectToSwitch =
        allProjects.find((otherProject) => otherProject.id === lastProjectId) ??
        allProjects[0];
    }

    if (weatherCountriesQuery.data != null) {
      return (
        <Navigate
          to={getEctoplannerUrl(
            tenantId,
            params.projectType,
            projectToSwitch.id
          )}
        />
      );
    }
  }

  const pageTitle =
    params.projectType === EctoplannerProjectTypes.Secosim
      ? T.ectoplanner.secosim.title
      : T.ectoplanner.title;

  const onExport = isEditingProject
    ? exportProjectMutation.mutate
    : exportBuildMutation.mutate;

  const isLoadingForm = formData == null && getFormQuery.isLoading;

  const isExporting =
    exportBuildMutation.isLoading || exportProjectMutation.isLoading;

  const hasChanges =
    changedForms[versionId] === true || migratedForms[versionId] === true;
  if (weatherCountriesQuery.data != null && !allProjectsQuery.isLoading) {
    if (formData == null && project != null) {
      return (
        <ToolbarContentPage
          navigationControl={navigationControl}
          navigationTree={navigationTree}
          title={pageTitle}
          showLocationPicker={false}
        >
          <Spinner />
        </ToolbarContentPage>
      );
    }

    let projectEditor: React.ReactNode = null;

    if (allProjects.length === 0) {
      projectEditor = (
        <ToolbarContentPage
          navigationControl={navigationControl}
          navigationTree={navigationTree}
          title={pageTitle}
          showLocationPicker={false}
          toolbarItems={
            <ToolbarItem>
              <ToolbarMenu>
                <EctoplannerAddNewToolbarMenuButton
                  onClickAddNew={onClickAddNew}
                />
              </ToolbarMenu>
            </ToolbarItem>
          }
        >
          <NoDataMessage message={T.ectoplanner.noprojectshelp} />
        </ToolbarContentPage>
      );
    } else if (project?.projectType === EctoplannerProjectTypes.Secosim) {
      projectEditor = (
        <EditSecosimProject
          navigationControl={navigationControl}
          navigationTree={navigationTree}
          onClickAddNew={onClickAddNew}
          onClickShare={showShareDialog}
          onClickDelete={showDeleteDialog}
          onClickEditName={onClickEditName}
          onClickExport={onExport}
          selectedBuild={selectedBuild}
          form={formData as SecosimForm}
          setForm={setFormFromUserInput}
          clearHasChanges={clearHasChanges}
          hasChanges={hasChanges}
          onRecalculate={onRecalculate}
          isLoadingForm={isLoadingForm}
          isExporting={isExporting}
          weatherCountries={weatherCountriesQuery.data.items}
        />
      );
    } else {
      projectEditor = (
        <EditEctoplannerProject
          allProjects={allProjects}
          weatherCountries={weatherCountriesQuery.data.items}
          form={formData as EctoplannerForm}
          isLoadingProjects={allProjectsQuery.isLoading}
          isLoadingForm={isLoadingForm}
          setForm={setForm}
          setFormFromUserInput={setFormFromUserInput}
          clearHasChanges={clearHasChanges}
          hasChanges={hasChanges}
          onRecalculate={onRecalculate}
          onClickExport={onExport}
          selectedBuild={selectedBuild}
          navigationControl={navigationControl}
          navigationTree={navigationTree}
          onClickAddNew={onClickAddNew}
          onClickShare={showShareDialog}
          onClickEditName={onClickEditName}
          onClickDelete={showDeleteDialog}
          isExporting={isExporting}
        />
      );
    }

    if (params.projectId != null) {
      projectEditor = (
        <EctoplannerGraphStoreContext.Provider
          value={storeRef ?? emptyEctoplannerGraphStore}
        >
          {projectEditor}
        </EctoplannerGraphStoreContext.Provider>
      );
    }

    return (
      <>
        {projectEditor}
        <ModelFormDialog
          isSavingInput={
            addVersionMutation.isLoading || addProjectMutation.isLoading
          }
          addTitle={(input) =>
            input?.type === CreateFormProjectTypes.Version
              ? T.ectoplanner.addnewversion
              : T.ectoplanner.form.shared.addproject
          }
          actionText={T.common.add}
          input={newBuild}
          onModalClose={clearNewBuild}
          saveAsArray={false}
          models={newBuildModels}
          saveInput={confirmAddNewVersion}
        />

        <ConfirmDeleteDialog
          onModalClose={hideDeleteDialog}
          isOpen={isShowingDeleteDialog}
          itemName={T.ectoplanner.calculations.genericbuildname}
          onDelete={confirmDelete}
          isLoading={
            deleteBuildMutation.isLoading || deleteProjectMutation.isLoading
          }
        >
          {isEditingProject
            ? T.ectoplanner.confirmdeleteproject
            : T.ectoplanner.confirmdeleteversion}
        </ConfirmDeleteDialog>

        <ModelFormDialog<SetBuildDescriptionRequestWithId, false>
          title={T.ectoplanner.calculations.editdescription}
          onModalClose={() => setUpdateDescriptionRequest(null)}
          input={updateDescriptionRequest}
          models={buildModels}
          saveAsArray={false}
          saveInput={(newInput) => {
            if (buildIdIsNullOrMaster(buildId)) {
              editProjectNameMutation.mutate({
                name: newInput.description
              });
            } else {
              editBuildDescriptionMutation.mutate({
                description: newInput.description
              });
            }
          }}
          isLoading={
            editBuildDescriptionMutation.isLoading ||
            editProjectNameMutation.isLoading
          }
        />

        <ShareEctoplannerProjectModal
          onModalClose={hideShareDialog}
          isOpen={isShowingShareDialog}
          projectName={project?.name}
        />
      </>
    );
  } else if (
    allProjectsQuery.isLoading ||
    weatherCountriesQuery.isLoading ||
    getFormQuery.isLoading ||
    buildsQueryIsLoading
  ) {
    return <LoadingContainer isLoading />;
  } else if (
    allProjectsQuery.error != null ||
    weatherCountriesQuery.error != null ||
    getFormQuery.error != null ||
    buildsQueryError != null
  ) {
    return <ErrorNotice>{T.ectoplanner.failedtoloadproject} </ErrorNotice>;
  }
};

const EctoplannerPage = () => {
  const params = useParams<EctoplannerParams>();

  if (params.projectType == null) {
    return <Navigate to={getEctoplannerUrl(params.tenantId, 'ectoplanner')} />;
  }

  return (
    <EctoplannerProjectContent
      projectId={params.projectId}
      buildId={params.buildId}
    />
  );
};

export default EctoplannerPage;
