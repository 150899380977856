import queryString from 'query-string';
import { CancellablePromise } from 'ecto-common/lib/API/API';
import { ApiContextSettings } from '../API/APIUtils';
import { APIFetchType } from 'ecto-common/lib/utils/APIFetchType';

// Retrieves a JSON array from the API in several chunked requests to avoid URL length limits
export function GetJSONArrayFromAPI<ReturnType = unknown>(
  contextSettings: ApiContextSettings,
  fetch: APIFetchType,
  path: string,
  ids: string[],
  propertyName: string,
  chunkSize = 20,
  extraQueryArgs = {}
): CancellablePromise<ReturnType> {
  const chunks = [];

  for (let i = 0; i < ids.length; i += chunkSize) {
    chunks.push(ids.slice(i, i + chunkSize));
  }

  const fetches = chunks.map((chunk) =>
    fetch(
      contextSettings,
      path +
        '?' +
        queryString.stringify({ ...extraQueryArgs, [propertyName]: chunk })
    )
  );

  const ret: CancellablePromise<ReturnType> = Promise.all(fetches)
    // eslint-disable-next-line prefer-spread
    .then((jsons) => [].concat.apply([], jsons));

  ret.cancel = () => {
    fetches.forEach((f) =>
      (f as unknown as CancellablePromise<ReturnType>)?.cancel()
    );
  };

  return ret;
}
