import React, { useCallback } from 'react';
import _ from 'lodash';
import { ProcessMapDropdownShowMenuCallback } from './useProcessMapDropdown';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import Icons from 'ecto-common/lib/Icons/Icons';
import { beautifyEquipmentName } from '../utils/equipmentTypeUtils';

const useNodeNavigationLinkAction = ({
  showMenu,
  hideMenu,
  confirmNavigationToNodeId
}: {
  showMenu: ProcessMapDropdownShowMenuCallback;
  hideMenu: () => void;
  confirmNavigationToNodeId: (nodeId: string) => void;
}) => {
  const equipmentMap = useCommonSelector((state) => state.general.equipmentMap);
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const equipmentTypes = useCommonSelector(
    (state) => state.general.equipmentTypes
  );
  return useCallback(
    (event: MouseEvent, nodeId: string) => {
      const equipment = equipmentMap[nodeId];

      const node = nodeMap[nodeId] ?? equipment;

      let subtitle: string = null;

      if (equipment != null) {
        const eqType = equipmentTypes.find(
          (type) => type.equipmentTypeId === equipment.equipmentTypeId
        );
        subtitle = beautifyEquipmentName(eqType?.name);
      }

      const x = event.clientX;
      const y = event.clientY;

      showMenu(
        [
          {
            label: node?.name,
            icon: <Icons.ArrowRight />,
            subtitle,
            action: () => {
              hideMenu();
              confirmNavigationToNodeId(nodeId);
            }
          }
        ],
        {
          x,
          y
        }
      );
    },
    [
      equipmentMap,
      nodeMap,
      showMenu,
      equipmentTypes,
      hideMenu,
      confirmNavigationToNodeId
    ]
  );
};

export default useNodeNavigationLinkAction;
