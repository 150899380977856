import { useNavigate } from 'react-router-dom';
import { getSignalProvidersPage } from '../utils/commonLinkUtil';
import React, { useCallback, useContext } from 'react';
import TenantContext from '../hooks/TenantContext';
import _ from 'lodash';
import { NodeResponseModel } from '../API/APIGen';
import { ProcessMapDropdownShowMenuCallback } from './useProcessMapDropdown';
import sortByLocaleCompare from '../utils/sortByLocaleCompare';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { beautifyEquipmentName } from '../utils/equipmentTypeUtils';
import Icons from 'ecto-common/lib/Icons/Icons';

const useEquipmentLinkAction = ({
  node,
  showMenu,
  hideMenu
}: {
  node: NodeResponseModel;
  showMenu: ProcessMapDropdownShowMenuCallback;
  hideMenu: () => void;
}) => {
  const navigate = useNavigate();
  const { contextSettings } = useContext(TenantContext);
  const equipmentTypes = useCommonSelector(
    (state) => state.general.equipmentTypes
  );

  return useCallback(
    (event: MouseEvent, equipmentTypeId: string) => {
      if (node == null) {
        return;
      }

      const equipments = _.filter(node?.equipments, {
        equipmentTypeId: equipmentTypeId
      });

      if (equipments.length === 0) {
        return;
      }

      const sortedEquipment = sortByLocaleCompare(equipments, 'name');
      showMenu(
        sortedEquipment.map((equipment) => ({
          label: equipment.name,
          subtitle: beautifyEquipmentName(
            equipmentTypes.find(
              (type) => type.equipmentTypeId === equipment.equipmentTypeId
            )?.name
          ),
          icon: <Icons.ArrowRight />,
          action: () => {
            hideMenu();
            navigate(
              getSignalProvidersPage(
                contextSettings.tenantId,
                node.nodeId,
                equipment.equipmentId
              )
            );
          }
        })),
        {
          x: event.clientX,
          y: event.clientY
        }
      );
    },
    [
      node,
      showMenu,
      equipmentTypes,
      hideMenu,
      navigate,
      contextSettings.tenantId
    ]
  );
};

export default useEquipmentLinkAction;
