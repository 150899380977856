import React, { useContext, useEffect, useMemo, useState } from 'react';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import { matchPath, useLocation } from 'react-router-dom';
import T from 'ecto-common/lib/lang/Language';
import { Navigate } from 'react-router-dom';
import {
  getLastTenantId,
  setLastTenantId
} from 'ecto-common/lib/utils/localStorageUtil';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import _ from 'lodash';
import { cancellablePromiseList } from 'ecto-common/lib/API/API';
import IdentityServiceAPI from 'ecto-common/lib/utils/IdentityServiceAPI';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import {
  ResourceModel,
  RoleModel,
  TenantUserModel
} from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { TenantModel } from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import Page from 'ecto-common/lib/Page/Page';
import { PageNavLink } from '../Page/Page';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useCommonSelector } from '../reducers/storeCommon';

const tenantPath = '/:tenantId/*';
type TenantParams = 'tenantId';

const getTenantFromPathname = (pathname: string) => {
  return matchPath<TenantParams, string>(tenantPath, pathname)?.params
    ?.tenantId;
};

const loadTenantDataPromise = (contextSettings: ApiContextSettings) => {
  return cancellablePromiseList([
    IdentityServiceAPI.User.getTenants(contextSettings),
    IdentityServiceAPI.Resources.getResources(contextSettings),
    IdentityServiceAPI.Roles.getRoles(contextSettings),
    IdentityServiceAPI.User.getWhoAmI(contextSettings),
    IdentityServiceAPI.User.getTenantUsers(contextSettings)
  ] as const);
};

interface TenantContainerProps {
  children?: React.ReactNode;
}

const emptyNavLinks: PageNavLink[] = [];

const NoTenantsError = () => {
  const content = (
    <ToolbarContentPage title={T.common.error} showLocationPicker={false}>
      {T.tenants.noaccess}
    </ToolbarContentPage>
  );

  return (
    <div style={{ width: '100%' }}>
      <Page navLinks={emptyNavLinks} content={content} />
    </div>
  );
};

const ADMIN_SPECIFIC_TENANT_IDS = [
  'template-administration',
  'tenant-administration',
  'device-administration'
];

const TenantContainer = ({ children }: TenantContainerProps) => {
  const { userId, setUserData } = useContext(UserContext);
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  const isAdmin = useCommonSelector((state) => state.general.isAdmin);

  // No routes here yet, extract tenant name manually from pathname
  const parsedTenantId = getTenantFromPathname(location.pathname);

  const [availableTenantRoles, setAvailableTenantRoles] = useState<RoleModel[]>(
    []
  );
  const [availableTenantResources, setAvailableTenantResources] = useState<
    ResourceModel[]
  >([]);

  const [tenantList, setTenantList] = useState<TenantModel[]>([]);
  const [tenantUsers, setTenantUsers] = useState<TenantUserModel[]>([]);
  const tenantObject = _.find(tenantList, ['id', parsedTenantId]);
  const tenantUser = _.find(tenantUsers, ['tenantId', parsedTenantId]);

  const [isLoadingTenants, loadTenants] = usePromiseCall({
    promise: loadTenantDataPromise,
    onSuccess: ([tenants, resources, roles, _userData, _tenantUsers]) => {
      setAvailableTenantResources(resources.resources);
      setAvailableTenantRoles(roles.roles);
      if (!isAdmin) {
        setTenantList(
          _.reject(tenants, (tenant) =>
            _.includes(ADMIN_SPECIFIC_TENANT_IDS, tenant.id)
          )
        );
      } else {
        setTenantList(tenants);
      }
      setUserData(_userData);
      setTenantUsers(_tenantUsers);
    },
    onError: () => {
      setHasError(true);
    },
    initiallyLoading: true
  });

  const [, loadUserData] = usePromiseCall({
    promise: IdentityServiceAPI.User.getWhoAmI,
    onSuccess: (_userData) => {
      setUserData(_userData);
    },
    onError: () => {
      setHasError(true);
    }
  });

  useEffect(() => {
    if (userId != null) {
      loadTenants();
    }
  }, [loadTenants, userId]);

  let redirectTenantId: string = null;
  let tenantId: string = null;
  let error: React.ReactNode = null;
  if (hasError) {
    // Let MainContainer handle error instead.
  } else if (!isLoadingTenants) {
    if (tenantList.length === 0) {
      // Redirect to "no tenants available" screen
      error = <NoTenantsError />;
    } else {
      const foundParsedTenantId = _.find(tenantList, [
        'id',
        parsedTenantId
      ])?.id;
      const oldTenantId = _.find(tenantList, ['id', getLastTenantId()])?.id;
      if (foundParsedTenantId == null) {
        const newTenantId = oldTenantId ?? tenantList[0].id;
        tenantId = newTenantId;
        redirectTenantId = newTenantId;
      } else {
        tenantId = foundParsedTenantId;
      }

      setLastTenantId(tenantId);
    }

    // The specified tenant does not exist
  }

  const tenantData = useMemo(() => {
    return {
      availableTenantRoles,
      availableTenantResources,
      tenantId,
      tenantResources: tenantObject?.resources ?? [],
      tenants: tenantList,
      tenantUserRoles: tenantUser?.roles ?? [],
      isLoadingTenants,
      tenantsFailedToLoad: hasError,
      reloadTenants: loadTenants,
      reloadUser: loadUserData,
      contextSettings: {
        tenantId
      }
    };
  }, [
    tenantObject?.resources,
    tenantId,
    isLoadingTenants,
    loadUserData,
    tenantList,
    tenantUser,
    loadTenants,
    availableTenantRoles,
    availableTenantResources,
    hasError
  ]);

  if (redirectTenantId) {
    return <Navigate to={'/' + redirectTenantId} replace />;
  }

  let content: React.ReactNode = null;
  if (error != null) {
    content = error;
  } else {
    content = children;
  }

  return (
    <TenantContext.Provider value={tenantData}>
      {content}
    </TenantContext.Provider>
  );
};

export const NestedTenantContainer = ({
  tenantId,
  children
}: {
  tenantId: string;
  children: React.ReactNode;
}) => {
  const tenantValue = useContext(TenantContext);
  const overrideValue = useMemo(() => {
    if (tenantId == null) {
      return tenantValue;
    }

    return {
      ...tenantValue,
      tenantId,
      contextSettings: {
        ...tenantValue.contextSettings,
        tenantId
      }
    };
  }, [tenantValue, tenantId]);

  return (
    <TenantContext.Provider value={overrideValue}>
      {children}
    </TenantContext.Provider>
  );
};

export default React.memo(TenantContainer);
