import React, { useMemo } from 'react';

import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import _ from 'lodash';
import { CustomNodeTreeSet } from 'ecto-common/lib/SelectNodeDialog/SelectNode';
import T from 'ecto-common/lib/lang/Language';
import {
  ProcessMapDocument,
  BaseProcessMapObject,
  ProcessMapLineModes,
  ProcessMapObjectTypes,
  ProcessMapRect,
  ProcessMapRectHandle,
  ProcessMapSymbolRule,
  ProcessMapActionTypes,
  EquipmentProcessMapAction,
  ActionTypeTranslations,
  NodeProcessMapAction
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { SymbolModel } from 'ecto-common/lib/API/PresentationAPIGen';
import {
  rectPointsSections,
  textPointSections
} from '../ProcessMap/Object/commonModels';
import { symbolSections } from '../ProcessMap/Object/SymbolView';
import { textSections } from '../ProcessMap/Object/TextView';
import { lineRectSections, lineSections } from '../ProcessMap/Object/LineView';
import { signalSections } from '../ProcessMap/Object/SignalView';
import { rectSections } from '../ProcessMap/Object/RectView';
import { getEquipmentName } from '../utils/equipmentTypeUtils';
import { useCommonSelector } from '../reducers/storeCommon';
export type ProcessMapSymbolRuleEditorProps = Omit<
  ModelEditorProps,
  'rawValue'
> & {
  rawValue: ProcessMapSymbolRule[];
  customNodeTreeSet: CustomNodeTreeSet;
  nodeId: string;
  states: string[];
  keyPath: string;
};

type ProcessMapObjectEditorProps = {
  selectedRectHandles: ProcessMapRectHandle[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateObject: (objectIndex: number, key: string[], value: any) => void;
  updateRect: (
    objectIndex: number,
    rectIndex: number,
    update: Partial<ProcessMapRect>
  ) => void;
  nodeTreeSet: CustomNodeTreeSet;
  previewNodeId: string;
  processMap: ProcessMapDocument;
  svgImages: Record<string, string>;
  library: SymbolModel[];
};

const ProcessMapObjectEditor = ({
  selectedRectHandles,
  processMap,
  updateObject,
  updateRect,
  nodeTreeSet,
  previewNodeId,
  svgImages,
  library
}: ProcessMapObjectEditorProps) => {
  const signalModels = useMemo(
    () => signalSections(previewNodeId, nodeTreeSet),
    [nodeTreeSet, previewNodeId]
  );

  const equipmentTypes = useCommonSelector(
    (state) => state.general.equipmentTypes
  );

  const commonSections =
    useMemo((): ModelFormSectionType<BaseProcessMapObject>[] => {
      const equipmentOptions = _.map(equipmentTypes, (type) => ({
        value: type.equipmentTypeId,
        label: getEquipmentName(type.equipmentTypeId, equipmentTypes)
      }));

      return [
        {
          label: T.admin.processmaps.objecteditor.clickaction,
          initiallyCollapsed: false,
          lines: [
            {
              models: [
                {
                  label: T.admin.processmaps.objecteditor.actiontype,
                  key: (input) => input.action?.type,
                  modelType: ModelType.OPTIONS,
                  options: Object.values(ProcessMapActionTypes)
                    .filter(
                      (actionType) =>
                        actionType !== ProcessMapActionTypes.Signal
                    )
                    .map((actionType) => ({
                      label: ActionTypeTranslations[actionType],
                      value: actionType
                    })),
                  helpText: (actionType) => {
                    if (actionType === ProcessMapActionTypes.EquipmentType) {
                      return T.admin.processmaps.objecteditor
                        .equipmentactionhelp;
                    }
                    return null;
                  },
                  isClearable: true
                }
              ]
            },
            {
              models: [
                {
                  label: T.admin.equipment.type,
                  key: (input) =>
                    (input.action as EquipmentProcessMapAction).equipmentType,
                  visible: (input) =>
                    input.action?.type === ProcessMapActionTypes.EquipmentType,
                  modelType: ModelType.OPTIONS,
                  options: equipmentOptions
                }
              ]
            },
            {
              models: [
                {
                  label: T.admin.processmaps.clickaction.node,
                  key: (input) => (input.action as NodeProcessMapAction).nodeId,
                  visible: (input) =>
                    input.action?.type === ProcessMapActionTypes.Node,
                  modelType: ModelType.NODE,
                  selectEquipment: true,
                  customNodeTreeSet: nodeTreeSet
                }
              ]
            }
          ]
        }
      ];
    }, [equipmentTypes, nodeTreeSet]);

  if (selectedRectHandles.length === 0) {
    return null;
  } else if (
    selectedRectHandles.length > 1 &&
    _.some(
      selectedRectHandles,
      (rectHandle) =>
        rectHandle.objectIndex !== selectedRectHandles[0].objectIndex
    )
  ) {
    // TODO: Translation
    return <span>{selectedRectHandles.length} objects selected</span>;
  }

  const objectIndex = selectedRectHandles[0].objectIndex;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateInput = (key: string[], value: any) => {
    updateObject(objectIndex, key, value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateRectInput = (key: string[], value: any) => {
    // Key is in this format: index,width, parse out the index and property
    const rectIndex = parseInt(key[0], 10);
    const rectProperty = key[1];

    updateRect(objectIndex, rectIndex, {
      [rectProperty]: value
    });
  };

  const object = processMap.objects[selectedRectHandles[0].objectIndex];
  let sections: ModelFormSectionType<object>[] = null;
  let rectsSections: ModelFormSectionType<object>[] = null;

  switch (object.type) {
    case ProcessMapObjectTypes.Text:
      sections = textSections(previewNodeId, nodeTreeSet);
      rectsSections = textPointSections;
      break;
    case ProcessMapObjectTypes.Line:
      sections = lineSections;
      rectsSections = lineRectSections(
        selectedRectHandles,
        object.mode === ProcessMapLineModes.Path
      );
      break;
    case ProcessMapObjectTypes.Signal:
      sections = signalModels;
      rectsSections = textPointSections;
      break;
    case ProcessMapObjectTypes.Rect:
      sections = rectSections(
        previewNodeId,
        nodeTreeSet,
        object.fillColorRuleName
      );
      rectsSections = rectPointsSections;
      break;
    case ProcessMapObjectTypes.Symbol:
      sections = symbolSections(
        previewNodeId,
        nodeTreeSet,
        svgImages,
        library,
        object.states
      );
      rectsSections = rectPointsSections;
      break;
    default:
      sections = null;
      break;
  }

  if (sections != null) {
    const fullSections = [...commonSections, ...sections];
    return (
      <>
        <ModelForm
          input={object}
          sections={fullSections}
          onUpdateInput={onUpdateInput}
        />
        {rectsSections && (
          <ModelForm
            input={object.rects}
            sections={rectsSections}
            onUpdateInput={onUpdateRectInput}
          />
        )}
      </>
    );
  }

  const content = JSON.stringify(object, null, 2);

  return (
    <div>
      <pre>{content}</pre>
    </div>
  );
};

export default ProcessMapObjectEditor;
