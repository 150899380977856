import {
  EctoplannerForm,
  EctoplannerFormBuilding,
  EctoplannerFormTotalAndPeak
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import React, { useMemo } from 'react';
import DataTable, {
  DataTableColumnProps,
  DataTableSectionHeader
} from 'ecto-common/lib/DataTable/DataTable';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import styles from './EctoplannerNetworkOverview.module.css';
import Heading from 'ecto-common/lib/Heading/Heading';
import _ from 'lodash';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import colors from 'ecto-common/lib/styles/variables/colors';
import { EctoplannerNetworkOverviewEnergyGraphs } from 'js/components/Ectoplanner/EctoplannerEnergyGraphs';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import { EctoplannerFormError } from 'js/components/Ectoplanner/EctoplannerTypes';
import T from 'ecto-common/lib/lang/Language';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import dimensions from 'ecto-common/lib/styles/dimensions';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import Button from 'ecto-common/lib/Button/Button';
import EctoplannerBalancingGraphInfoDialog from 'js/components/Ectoplanner/EctoplannerBalancingGraphInfoDialog';
import {
  standardColumns,
  widthForTableColumnButtons
} from 'ecto-common/lib/utils/dataTableUtils';
import networkOverview from './assets/NetworkOverview.svg';
import networkArrowDown from './assets/NetworkArrowDown.svg';
import networkArrowLeft from './assets/NetworkArrowLeft.svg';
import networkArrowUp from './assets/NetworkArrowUp.svg';

type EctoplannerDiagramTableEntry = {
  label: React.ReactNode;
  value: React.ReactNode;
};

const EctoplannerNetworkOverviewDiagram = ({
  form
}: {
  form: EctoplannerForm;
}) => {
  if (
    form.calculations.allBuildingImportsSummary == null ||
    form.calculations.allBuildingDemandsSummary == null
  ) {
    return null;
  }

  const columns: DataTableColumnProps<EctoplannerDiagramTableEntry>[] = [
    {
      dataKey: 'label',
      label: null,
      flexGrow: 2
    },
    {
      dataKey: 'value',
      label: null,
      flexGrow: 1
    }
  ];

  const buildingDemands = [
    DataTableSectionHeader(T.ectoplanner.buildingsummary.buildingdemands),
    {
      label: T.ectoplanner.buildingsummary.heatcapacity,
      value: formatNumberUnit(
        form.calculations.allBuildingDemandsSummary.heatPeak,
        T.ectoplanner.units.kw,
        0
      )
    },
    {
      label: T.ectoplanner.buildingsummary.coolingcapacity,
      value: formatNumberUnit(
        form.calculations.allBuildingDemandsSummary.coolPeak,
        T.ectoplanner.units.kw,
        0
      )
    },
    {
      label: T.ectoplanner.buildingsummary.heatdemand,
      value: formatNumberUnit(
        form.calculations.allBuildingDemandsSummary.heatTot,
        T.ectoplanner.units.mwha,
        0
      )
    },
    {
      label: T.ectoplanner.buildingsummary.coolingdemand,
      value: formatNumberUnit(
        form.calculations.allBuildingDemandsSummary.coolTot,
        T.ectoplanner.units.mwha,
        0
      )
    }
  ];

  const buildingImports = [
    DataTableSectionHeader(T.ectoplanner.buildingsummary.buildingimports),
    {
      label: T.ectoplanner.buildingsummary.heatimportcapacity,
      value: formatNumberUnit(
        form.calculations.allBuildingImportsSummary.heatPeak,
        T.ectoplanner.units.kw,
        0
      )
    },
    {
      label: T.ectoplanner.buildingsummary.coolingimportcapacity,
      value: formatNumberUnit(
        form.calculations.allBuildingImportsSummary.coolPeak,
        T.ectoplanner.units.kw,
        0
      )
    },
    {
      label: T.ectoplanner.buildingsummary.importedheat,
      value: formatNumberUnit(
        form.calculations.allBuildingImportsSummary.heatTot,
        T.ectoplanner.units.mwha,
        0
      )
    },
    {
      label: T.ectoplanner.buildingsummary.importedcooling,
      value: formatNumberUnit(
        form.calculations.allBuildingImportsSummary.coolTot,
        T.ectoplanner.units.mwha,
        0
      )
    },
    {
      label: T.ectoplanner.buildingsummary.electricityimportcapacity,
      value: formatNumberUnit(
        form.calculations.allBuildingImportsSummary.elecPeak,
        T.ectoplanner.units.kw,
        0
      )
    },
    {
      label: T.ectoplanner.buildingsummary.importedelectricity,
      value: formatNumberUnit(
        form.calculations.allBuildingImportsSummary.elecTot,
        T.ectoplanner.units.mwha,
        0
      )
    }
  ];

  const balancingUnitLoads = [
    DataTableSectionHeader(T.ectoplanner.buildingsummary.balancingunitloads),
    {
      label: T.ectoplanner.buildingsummary.heatloaddemand,
      value: formatNumberUnit(
        form.calculations.ehLoadProfilesSummary.heatTot,
        T.ectoplanner.units.mwha,
        0
      )
    },
    {
      label: T.ectoplanner.buildingsummary.coolingloaddemand,
      value: formatNumberUnit(
        form.calculations.ehLoadProfilesSummary.coolTot,
        T.ectoplanner.units.mwha,
        0
      )
    },
    {
      label: T.ectoplanner.buildingsummary.balancedloadsbetweenbuildings,
      value: formatNumberUnit(
        form.calculations.netwBalanced.heat * 2.0,
        T.ectoplanner.units.mwha,
        0
      )
    }
  ];

  return (
    <div className={styles.diagramGrid}>
      <div>
        <img src={networkArrowDown} />
        <DataTable columns={columns} data={buildingDemands} disableHeader />
        <div className={styles.expander} />
      </div>
      <div>
        <img src={networkOverview} />
      </div>
      <div className={styles.importTable}>
        <img src={networkArrowLeft} className={styles.importArrow} />
        <DataTable columns={columns} data={buildingImports} disableHeader />
      </div>
      <div>
        <img src={networkArrowUp} className={styles.upArrow} />
        <DataTable columns={columns} data={balancingUnitLoads} disableHeader />
      </div>
    </div>
  );
};

type EctoplannerNetworkOverviewProps = {
  form: EctoplannerForm;
  isOpen: boolean;
  onModalClose: () => void;
};

const BalancingGraph = ({ form }: { form: EctoplannerForm }) => {
  const calcs = form.calculations;

  const config: Highcharts.Options = useMemo(
    () => ({
      rangeSelector: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      plotOptions: {
        column: {
          animation: true,
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return formatNumberUnit(this.y, T.ectoplanner.units.mwha, 1);
            },
            color: 'white',
            style: {
              textOutline: 'none'
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      scrollbar: {
        liveRedraw: false,
        enabled: false
      },
      chart: {
        type: 'column',
        zoomType: null,
        backgroundColor: null
      },
      xAxis: {
        categories: [
          T.ectoplanner.buildingsummary.xaxis.demandofallbuildings,
          T.ectoplanner.buildingsummary.xaxis.importofallbuildings,
          T.ectoplanner.buildingsummary.balancingunitloads
        ],
        crosshair: true
      },
      legend: {
        enabled: false
      },
      yAxis: {
        crosshair: false,
        opposite: false
      },
      tooltip: {
        valueDecimals: 0,
        valueSuffix: ' ' + T.ectoplanner.units.mwh
      },
      series: [
        {
          type: 'column',
          name: T.ectoplanner.buildingsummary.series.totalspaceheating,
          color: colors.heatingColor,
          data: [calcs.allBuildingDemandsSummary.heatTot, null, null]
        },
        {
          type: 'column',
          name: T.ectoplanner.buildingsummary.series.totalspacecooling,
          data: [calcs.allBuildingDemandsSummary.coolTot, null, null],
          color: colors.coolingColor
        },
        {
          type: 'column',
          name: T.ectoplanner.buildingsummary.series.electricityimport,
          color: colors.electricityColor,
          data: [null, calcs.allBuildingImportsSummary.elecTot, null]
        },
        {
          type: 'column',
          name: T.ectoplanner.buildingsummary.series.heatimported,
          color: colors.heatingColor,
          data: [null, calcs.allBuildingImportsSummary.heatTot, null]
        },
        {
          type: 'column',
          name: T.ectoplanner.buildingsummary.series.wasteheatfed,
          color: colors.coolingColor,
          data: [null, calcs.allBuildingImportsSummary.coolTot, null]
        },
        {
          type: 'column',
          name: T.ectoplanner.buildingsummary.series.balanced,
          color: colors.successColor,
          data: [null, null, form.calculations.netwBalanced.heat * 2.0]
        },
        {
          type: 'column',
          name: T.ectoplanner.buildingsummary.series.electricity,
          color: colors.electricityColor,
          data: [null, null, calcs.ehLoadProfilesSummary.elecTot]
        },
        {
          type: 'column',
          name: T.ectoplanner.buildingsummary.series.heatfed,
          color: colors.heatingColor,
          data: [null, null, calcs.ehLoadProfilesSummary.heatTot]
        },
        {
          type: 'column',
          name: T.ectoplanner.buildingsummary.series.coldfed,
          color: colors.coolingColor,
          data: [null, null, calcs.ehLoadProfilesSummary.coolTot]
        }
      ]
    }),
    [
      calcs.allBuildingDemandsSummary.coolTot,
      calcs.allBuildingDemandsSummary.heatTot,
      calcs.allBuildingImportsSummary.coolTot,
      calcs.allBuildingImportsSummary.elecTot,
      calcs.allBuildingImportsSummary.heatTot,
      calcs.ehLoadProfilesSummary.coolTot,
      calcs.ehLoadProfilesSummary.elecTot,
      calcs.ehLoadProfilesSummary.heatTot,
      form.calculations.netwBalanced.heat
    ]
  );

  return (
    <>
      <div style={{ width: '100%', height: 300 }}>
        <StockChart enableAnimation config={config} />
      </div>
    </>
  );
};

type EctoplannerBuildingOverviewTableProps = {
  form: EctoplannerForm;
  formErrors: EctoplannerFormError[];
  onEditBuilding: (index: number) => void;
  onCopyBuilding: (index: number) => void;
  onDeleteBuilding: (index: number) => void;
};

type EctoplannerFormBuildingWithErrorState = EctoplannerFormBuilding & {
  hasError: boolean;
};

export const EctoplannerBuildingOverviewTable = ({
  form,
  onEditBuilding,
  onCopyBuilding,
  onDeleteBuilding,
  formErrors
}: EctoplannerBuildingOverviewTableProps) => {
  const buildings = form.buildings;
  const buildingsWithErrors: EctoplannerFormBuildingWithErrorState[] =
    useMemo(() => {
      return buildings.map((building, buildingIndex) => {
        const hasError =
          formErrors.find((formError) =>
            formError.errorKeyPath.startsWith(
              'buildings[' + buildingIndex + ']'
            )
          ) != null;
        return {
          ...building,
          hasError
        };
      });
    }, [formErrors, buildings]);

  const totalAndPeakColumns = (dataKey: string) => [
    {
      label: T.ectoplanner.buildingsummary.tablecolumns.heat,
      dataKey: dataKey,
      tooltip: T.ectoplanner.buildingsummary.tabletooltips.heat,
      dataFormatter: (summary: EctoplannerFormTotalAndPeak) =>
        summary == null ? null : (
          <TableColumn
            title={formatNumberUnit(
              summary.heatPeak,
              T.ectoplanner.units.kw,
              0
            )}
            subtitle={formatNumberUnit(
              summary.heatTot,
              T.ectoplanner.units.mwh,
              1
            )}
          />
        )
    },
    {
      label: T.ectoplanner.buildingsummary.tablecolumns.cold,
      tooltip: T.ectoplanner.buildingsummary.tabletooltips.cold,
      dataKey: dataKey,
      dataFormatter: (summary: EctoplannerFormTotalAndPeak) =>
        summary == null ? null : (
          <TableColumn
            title={formatNumberUnit(
              summary.coolPeak,
              T.ectoplanner.units.kw,
              0
            )}
            subtitle={formatNumberUnit(
              summary.coolTot,
              T.ectoplanner.units.mwh,
              1
            )}
          />
        )
    },
    {
      label: T.ectoplanner.buildingsummary.tablecolumns.electricity,
      tooltip: T.ectoplanner.buildingsummary.tabletooltips.electricity,
      dataKey: dataKey,
      dataFormatter: (summary: EctoplannerFormTotalAndPeak) =>
        summary == null ? null : (
          <TableColumn
            title={formatNumberUnit(
              summary.elecPeak,
              T.ectoplanner.units.kw,
              0
            )}
            subtitle={formatNumberUnit(
              summary.elecTot,
              T.ectoplanner.units.mwh,
              1
            )}
          />
        )
    }
  ];

  const columns: DataTableColumnProps<EctoplannerFormBuildingWithErrorState>[] =
    useMemo(
      () => [
        {
          label: T.ectoplanner.buildingsummary.tablecolumns.name,
          dataKey: 'name',
          linkColumn: true,
          dataFormatter: (
            name: string,
            building: EctoplannerFormBuildingWithErrorState
          ) => {
            let title: React.ReactNode = name;

            if (building.hasError) {
              title = <span className={styles.hasError}>{name}</span>;
            }

            const floorArea = formatNumberUnit(
              building.params.floorArea,
              T.ectoplanner.units.m2,
              0
            );

            if (
              building.params.buildingType == null ||
              building.params.buildingSubtype == null
            ) {
              return <TableColumn title={title} subtitle={floorArea} />;
            }

            return (
              <TableColumn
                title={title}
                subtitle={
                  floorArea +
                  ' ' +
                  building.params.buildingType +
                  ' / ' +
                  building.params.buildingSubtype
                }
              />
            );
          }
        },
        ...totalAndPeakColumns('params.calculations.importSummary'),
        ...standardColumns<EctoplannerFormBuilding>({
          onDuplicate: (_unused, index) => onCopyBuilding(index),
          onDelete: (_unused, index) => onDeleteBuilding(index),
          onEdit: (_unnused, index) => onEditBuilding(index)
        })
      ],
      [onCopyBuilding, onDeleteBuilding, onEditBuilding]
    );

  type SummaryEntry = {
    label: React.ReactNode;
    data: EctoplannerFormTotalAndPeak;
  };

  const summaryColumns: DataTableColumnProps<SummaryEntry>[] = useMemo(
    () => [
      {
        dataKey: 'label',
        dataFormatter: (label) => <strong>{label} </strong>
      },
      ...totalAndPeakColumns('data'),
      {
        // Dummy column to align with table above
        dataKey: '_unused',
        minWidth: widthForTableColumnButtons(3),
        flexGrow: 0,
        flexShrink: 0,
        dataFormatter: () => null
      }
    ],
    []
  );

  const summaryData = form.calculations?.allBuildingImportsSummary
    ? [
        {
          label: (
            <Tooltip
              text={
                T.ectoplanner.buildingsummary.tabletooltips
                  .energyimportallbuildings
              }
              multiline
              withIcon
            >
              {T.ectoplanner.buildingsummary.rows.energyimportallbuildings}
            </Tooltip>
          ),
          data: form.calculations.allBuildingImportsSummary
        },
        {
          label: (
            <Tooltip
              text={T.ectoplanner.buildingsummary.tabletooltips.loadatenergyhub}
              multiline
              withIcon
            >
              {T.ectoplanner.buildingsummary.rows.loadatenergyhub}
            </Tooltip>
          ),
          data: form.calculations.ehLoadProfilesSummary
        }
      ]
    : [];

  const onClickRow = (
    _unused: EctoplannerFormBuildingWithErrorState,
    index: number
  ) => {
    onEditBuilding(index);
  };

  return (
    <div>
      <DataTable<EctoplannerFormBuildingWithErrorState>
        columns={columns}
        data={buildingsWithErrors}
        onClickRow={onClickRow}
        noDataText={T.ectoplanner.form.nobuildings.label}
      />
      {summaryData.length > 0 && (
        <DataTable<SummaryEntry>
          columns={summaryColumns}
          data={summaryData}
          disableHeader
          verticalPadding={dimensions.standardMargin}
        />
      )}
    </div>
  );
};

const EctoplannerNetworkOverview = ({ form }: { form: EctoplannerForm }) => {
  const [showingInfoDialog, showInfoDialog, hideInfoDialog] =
    useDialogState('show-network-info');

  if (form?.calculations?.allBuildingDemandsSummary == null) {
    return null;
  }

  return (
    <>
      <EctoplannerBalancingGraphInfoDialog
        isOpen={showingInfoDialog}
        onModalClose={hideInfoDialog}
      />
      <EctoplannerNetworkOverviewDiagram form={form} />
      <div className={styles.printBreak} />
      <Heading withMarginTop level={3}>
        {T.ectoplanner.buildingsummary.sections.loadprofiles}
      </Heading>
      <EctoplannerNetworkOverviewEnergyGraphs form={form} />

      <Heading
        withMarginTop
        level={3}
        tooltip={T.ectoplanner.buildingsummary.sectiontooltips.balancing}
      >
        {T.ectoplanner.buildingsummary.sections.balancing}
      </Heading>
      {form?.calculations?.ehLoadProfiles != null && (
        <BalancingGraph form={form} />
      )}
      <Button onClick={showInfoDialog} className={styles.infoButton}>
        <Icons.InfoCircle /> {T.ectoplanner.buildingsummary.moreinfo}
      </Button>
    </>
  );
};

export default React.memo(EctoplannerNetworkOverview);

export const EctoplannerNetworkOverviewDialog = React.memo(
  ({ form, isOpen, onModalClose }: EctoplannerNetworkOverviewProps) => {
    return (
      <ActionModal
        className={styles.modal}
        headerIcon={Icons.Building}
        isOpen={isOpen}
        onModalClose={onModalClose}
        actionText={T.common.done}
        onConfirmClick={onModalClose}
        disableCancel
        title={T.ectoplanner.buildingsummary.title}
      >
        <EctoplannerNetworkOverview form={form} />
      </ActionModal>
    );
  }
);
