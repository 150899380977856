import React, { useMemo } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import Icons from 'ecto-common/lib/Icons/Icons';
import {
  checkboxColumn,
  standardColumns
} from 'ecto-common/lib/utils/dataTableUtils';

import styles from './ProcessMapTable.module.css';
import { ProcessMapResponseModel } from 'ecto-common/lib/API/APIGen';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';

interface ProcessMapTableProps {
  onClickRow?(
    processMap: ProcessMapResponseModel,
    row: number,
    column: number
  ): void;
  searchString?: string;
  showCheckboxes?: boolean;
  selectedId?: string;
  isLoading?: boolean;
  items: Record<string, ProcessMapResponseModel>;
  hasError?: boolean;
  defaultItemId?: string;
  onEdit?: (processMap: ProcessMapResponseModel) => void;
  nameColumnTitle?: string;
}

const ProcessMapTable = ({
  items,
  onClickRow,
  searchString,
  showCheckboxes = false,
  selectedId,
  isLoading = false,
  hasError,
  defaultItemId,
  nameColumnTitle = T.admin.processmaps.name,
  onEdit = null
}: ProcessMapTableProps) => {
  const filteredProcessMaps = useMemo(() => {
    const searchStringLowerCase = _.toLower(searchString);
    return _.filter(
      items,
      (item) => item && _.includes(_.toLower(item?.name), searchStringLowerCase)
    );
  }, [items, searchString]);

  const allColumns: DataTableColumnProps<ProcessMapResponseModel>[] =
    useMemo(() => {
      return _.compact([
        showCheckboxes &&
          checkboxColumn({
            rowIsChecked: (item) => item.id === selectedId
          }),
        {
          label: nameColumnTitle,
          dataKey: 'name',
          idKey: 'id',
          linkColumn: true,
          dataFormatter: (value: string, item: ProcessMapResponseModel) => {
            return (
              <>
                <Icons.File /> {value}{' '}
                {item.id === defaultItemId ? (
                  <span className={styles.defaultText}>
                    ({T.admin.processmaps.default})
                  </span>
                ) : (
                  ''
                )}
              </>
            );
          }
        },
        {
          label: T.admin.processmaps.description,
          dataKey: 'description'
        },
        ...(onEdit ? standardColumns({ onEdit }) : [])
      ]);
    }, [showCheckboxes, nameColumnTitle, onEdit, selectedId, defaultItemId]);

  return (
    <DataTable<ProcessMapResponseModel>
      columns={allColumns}
      data={filteredProcessMaps}
      hasError={hasError}
      noDataText={T.admin.processmaps.nofound}
      isLoading={isLoading}
      onClickRow={onClickRow}
    />
  );
};

export default ProcessMapTable;
