import ReactDOM from 'react-dom';
import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import _ from 'lodash';
import { CancelledErrorName } from 'ecto-common/lib/constants';
import localStore from 'store';
import { CancellablePromise } from 'ecto-common/lib/API/API';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cancelPromises = (promisesRef: any) => {
  const prev = promisesRef.current;
  promisesRef.current = [];
  _.forEach(prev, (prevPromise) => _.invoke(prevPromise, 'cancel'));
};

type cancelFunction = () => void;

// We support promises with up to 9 type-safe arguments. This is slightly ugly, perhaps something better could be
// done with the Parameters utility type: https://www.typescriptlang.org/docs/handbook/utility-types.html
// But since we are phasing out usePromiseCall in favor of react-query, this is good enough for now.
type UsePromiseProps<ReturnType, A extends readonly unknown[]> = {
  promise: (
    contextOptions: ApiContextSettings,
    ...args: A
  ) => CancellablePromise<ReturnType>;
  onSuccess?: (result: ReturnType, ...params: A) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (error: any, ...params: A) => void;
  async?: boolean;
  ignoreCancel?: boolean;
  initiallyLoading?: boolean;
  retryAfter?: number;
  cacheKey?: string;
};

/**
 * @param {function} promise
 * Function to call.
 *
 * @param {function} onSuccess
 * Function to call on promise success. Declare this as useCallback if you the hook is going to be used in a useEffect.
 *
 * @param {function} onError
 * Function to call on promise failure. Declare this as useCallback if you the hook is going to be used in a useEffect.
 *
 * @param {boolean} async
 * Whether or not each new call to load cancels the previously running request.
 *
 * @param {boolean} ignoreCancel
 * Boolean to prevent hook to throw warnings if they are not needed.
 *
 * @param {boolean} initiallyLoading
 * Initial value of the loading state (does not call the promise).
 *
 * @param retryAfter
 * Set this value in milliseconds to automatically retry after a promise call has failed.
 *
 * @param cacheKey
 * Set this value to return cached result immediately, if available. Needs to be unique per call. Will still do
 * network call afterwards, so onSuccess will be called twice if cached results are available.
 *
 * @return [{boolean} isLoading, function(...*): usePromiseCall, function(): cancelLoading]
 *
 * isLoading: A boolean that tells when the promise call is ongoing
 * usePromiseCall: A method that executes the promise call with given props
 * cancelLoading: A method that cancels the currently loading promise
 *
 */
function usePromiseCall<ReturnType, ArgsType extends readonly unknown[]>({
  promise,
  onSuccess = null,
  onError = null,
  async = false,
  ignoreCancel = true,
  initiallyLoading = false,
  retryAfter = null,
  cacheKey = null
}: UsePromiseProps<ReturnType, ArgsType>): [
  boolean,
  (...args: ArgsType) => void,
  cancelFunction
] {
  const [isLoading, setIsLoading] = useState(initiallyLoading);
  const retryTimerRef = useRef(null);
  const runPromiseRef = useRef(null);
  const _promises = useRef([]);

  const onSuccessRef = useRef(onSuccess);
  const onErrorRef = useRef(onError);

  onSuccessRef.current = onSuccess;
  onErrorRef.current = onError;
  const { contextSettings } = useContext(TenantContext);

  const runPromise = useCallback(
    (...props: ArgsType) => {
      const curPromise = promise(contextSettings, ...props);

      const _onSuccess = onSuccessRef.current;
      const _onError = onErrorRef.current;

      let fullCacheKey: string;

      if (cacheKey) {
        fullCacheKey =
          cacheKey + '-' + props.map((arg) => arg.toString()).join('-');
        const cachedEntry = localStore.get(fullCacheKey);

        if (cachedEntry && _onSuccess) {
          _onSuccess(JSON.parse(cachedEntry), ...props);
        }
      }

      clearTimeout(retryTimerRef.current);
      retryTimerRef.current = null;

      if (!async) {
        cancelPromises(_promises);
      }

      _promises.current.push(curPromise);
      setIsLoading(true);

      curPromise
        .then((data: ReturnType) => {
          _promises.current = _.without(_promises.current, curPromise);

          // TODO: Remove this unstable API once React does state batching by default.
          // See this comment for justification: https://github.com/facebook/react/issues/16387#issuecomment-521623662
          // If we don't use this, setIsLoading(false) will trigger a separate render before the
          // data can be set in the component. This will lead to flashes with no data texts etc.
          // State batching is on per default in react event handlers (but not in this context).
          ReactDOM.unstable_batchedUpdates(() => {
            setIsLoading(_promises.current.length > 0);

            if (_onSuccess) {
              if (fullCacheKey) {
                try {
                  localStore.set(fullCacheKey, JSON.stringify(data ?? {}));
                } catch (e) {
                  // Silently fail. Result will still be returned, just not cached.
                  // Can be because of local storage limits etc.
                  console.error(e);
                }
              }
              _onSuccess(data, ...props);
            }
          });
        })
        .catch((error: { name?: string }) => {
          if (_promises.current.includes(curPromise)) {
            _promises.current = _.without(_promises.current, curPromise);

            if (retryAfter != null) {
              retryTimerRef.current = setTimeout(() => {
                runPromiseRef.current?.(...props);
              }, retryAfter);
            }

            if (error?.name !== CancelledErrorName) {
              setIsLoading(_promises.current.length > 0);
              _onError?.(error, ...props);
            } else if (!ignoreCancel) {
              setIsLoading(_promises.current.length > 0);
            }
          }
        });
    },
    [
      _promises,
      promise,
      ignoreCancel,
      async,
      retryAfter,
      cacheKey,
      contextSettings
    ]
  );

  runPromiseRef.current = runPromise;

  const cancelLoading = useCallback(() => {
    cancelPromises(_promises);
    setIsLoading(false);
  }, [_promises]);

  useEffect(() => {
    return () => cancelPromises(_promises);
  }, [_promises]);

  return [isLoading, runPromise, cancelLoading];
}

export default usePromiseCall;
