import React, { useCallback, useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import UUID from 'uuidjs';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import panelDefinitions, {
  PanelDataDefinition
} from 'ecto-common/lib/Dashboard/panels';
import { AllDataSources } from 'ecto-common/lib/Dashboard/datasources';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { modelFormSectionsAreValid } from 'ecto-common/lib/ModelForm/validateForm';
import { NodeNamePanelData } from 'ecto-common/lib/Dashboard/panels/NodeNamePanel';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import Icons from 'ecto-common/lib/Icons/Icons';
import SectionListPriority from 'ecto-common/lib/Dashboard/SectionListPriority';
import HelpPaths from 'ecto-common/help/tocKeys';

import styles from './EditDashboardPanelModal.module.css';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { DashboardPanel, PanelTarget } from 'ecto-common/lib/Dashboard/Panel';
import { PanelType } from 'ecto-common/lib/Dashboard/panels/PanelTypes';
import { getSignalNameModels } from 'ecto-common/lib/SignalNamesTable/SignalNamesTable';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import { SignalValuesDataSourceProps } from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';
import {
  LastSignalValuesDataSourceProps,
  SignalInputType
} from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';

const SharedSection: ModelFormSectionType<DashboardPanel> = {
  lines: [
    {
      models: [
        {
          key: (input) => input.title,
          modelType: ModelType.TEXT,
          label: T.admin.dashboards.panels.title,
          hasError: _.isEmpty
        },
        {
          key: (input) => input.type,
          modelType: ModelType.OPTIONS,
          label: T.admin.dashboards.panels.type,
          options: _.orderBy(
            Object.keys(panelDefinitions).map((type) => ({
              label:
                T.admin.dashboards.panels[
                  type.toLowerCase() as keyof typeof T.admin.dashboards.panels
                ],
              value: type
            })),
            'label'
          ),
          isMultiOption: false
        }
      ]
    },
    {
      models: [
        {
          key: (input) => input.description,
          modelType: ModelType.TEXT,
          label: T.common.description,
          multiline: true,
          rows: 3
        }
      ]
    }
  ],
  listPriority: SectionListPriority.Panel
};

const SharedSections = [SharedSection];

const TARGETS_KEY = 'targets';

const DEFAULT_EMPTY_TARGETS: Record<string, PanelTarget> = {};
const DEFAULT_SECTIONS: ModelFormSectionType<DashboardPanel>[] = [];
const DEFAULT_VERSION = 0;

// Get the current version of the component by looking at the list of migrations, choose
// the greatest version number from the migrations.
const componentVersion = (componentData: PanelDataDefinition) => {
  return (
    _.maxBy(componentData?.migrations ?? [], 'version')?.version ??
    DEFAULT_VERSION
  );
};

export const InitialPanel: DashboardPanel = {
  id: null,
  gridPos: {
    x: 0,
    y: 0,
    w: 0,
    h: 0
  },
  type: PanelType.NODE_NAME,
  version: componentVersion(NodeNamePanelData),
  [TARGETS_KEY]: { ...NodeNamePanelData.emptyTargets }
};

interface EditDashboardPanelModalProps {
  isOpen?: boolean;
  onModalClose?(): void;
  item?: DashboardPanel;
  onSubmitPanel?(panel: DashboardPanel): void;
  onDeletePanel?(panel: DashboardPanel): void;
  isNew?: boolean;
}

type NestedPanelModelForm<ObjectType extends object> = {
  sections: ModelFormSectionType<ObjectType>[];
  nestedKey: string[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onUpdateInput: (name: string[], value: any) => void;
};

const EditDashboardPanelModal = ({
  isOpen,
  onModalClose,
  item,
  onSubmitPanel,
  onDeletePanel,
  isNew
}: EditDashboardPanelModalProps) => {
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const equipmentTypes = useCommonSelector(
    (state) => state.general.equipmentTypes
  );
  const signalProviderTypes = useCommonSelector(
    (state) => state.general.enums?.signalProviderTypes
  );
  const [panel, setPanel] = useState(_.cloneDeep(InitialPanel));

  const allSignalInputs = _.flatMap(panel.targets, (target) => {
    if (_.isObject(target) && target.sourceType != null) {
      if (target.sourceType === DataSourceTypes.SIGNALS) {
        return (
          ((target as unknown as SignalValuesDataSourceProps)
            .signals as SignalInputType[]) ?? []
        );
      } else if (target.sourceType === DataSourceTypes.SIGNALS_LAST_VALUE) {
        return (
          ((target as unknown as LastSignalValuesDataSourceProps)
            .signals as SignalInputType[]) ?? []
        );
      }

      return [];
    }

    return [];
  });

  const signalNamesModels = useMemo(() => {
    return getSignalNameModels(equipmentTypes, signalProviderTypes);
  }, [equipmentTypes, signalProviderTypes]);

  const environment = {
    signalTypesMap,
    equipmentTypes,
    signalProviderTypes,
    signalNamesModels,
    allSignalInputs
  };

  useEffect(() => {
    setPanel(item != null ? _.cloneDeep(item) : _.cloneDeep(InitialPanel));
  }, [item]);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const onUpdateInput = useCallback((name: string[], value: any) => {
    setPanel((oldPanel) => {
      const newPanel = { ...oldPanel };
      _.set(newPanel, name, value);

      if (name[0] === 'type') {
        const component = panelDefinitions[value].component;
        const componentData = panelDefinitions[value].data;

        if (component) {
          _.set(
            newPanel,
            TARGETS_KEY,
            _.cloneDeep(componentData.emptyTargets ?? DEFAULT_EMPTY_TARGETS)
          );
          // Regenerate the ID to force the Panel to remount when changing panel type (it uses ID as a key)
          _.set(newPanel, 'id', UUID.generate());
          _.set(newPanel, 'version', componentVersion(componentData));
        }
      }
      return newPanel;
    });
  }, []);

  const componentData = panelDefinitions[panel.type]?.data;
  const emptyTargets = componentData?.emptyTargets ?? DEFAULT_EMPTY_TARGETS;
  const helpPath =
    componentData?.helpPath ?? HelpPaths.docs.admin.manage.dashboards;

  const nestedForms: NestedPanelModelForm<object>[] = useMemo(() => {
    const dataSourcesSections = _.flatMap(emptyTargets, (dataSource, key) => {
      const dataSourceMetadata = AllDataSources[dataSource.sourceType];
      const dataSourceSectionsArgs =
        componentData?.dataSourceSectionsConfig?.[dataSource.sourceType] ?? {};

      return _.map(
        dataSourceMetadata.sections(dataSourceSectionsArgs),
        (section) => {
          const parentKey = [TARGETS_KEY, key];
          const ret = _.cloneDeep(section);

          return {
            sections: [ret],
            nestedKey: parentKey,
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            onUpdateInput: (name: string[], value: any) => {
              onUpdateInput(parentKey.concat(name), value);
            }
          };
        }
      );
    });

    const componentDataSections = componentData.sections;

    const allComponentSections = (
      componentDataSections ?? DEFAULT_SECTIONS
    ).map((componentSection) => {
      return {
        sections: [componentSection],
        nestedKey: [TARGETS_KEY],
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        onUpdateInput: (name: string[], value: any) => {
          onUpdateInput([TARGETS_KEY].concat(name), value);
        }
      };
    });

    return [...dataSourcesSections, ...allComponentSections];
  }, [
    componentData?.dataSourceSectionsConfig,
    componentData.sections,
    emptyTargets,
    onUpdateInput
  ]);

  const isValid = _.every(nestedForms, (nestedForm) => {
    return modelFormSectionsAreValid(
      nestedForm.sections,
      _.get(panel, nestedForm.nestedKey),
      environment
    );
  });

  const _onSubmitPanel = useCallback(() => {
    onSubmitPanel(panel);
  }, [onSubmitPanel, panel]);

  const _onDeletePanel = useCallback(() => {
    onDeletePanel(item);
  }, [onDeletePanel, item]);

  return (
    <ActionModal
      isOpen={isOpen}
      title={
        isNew ? T.admin.dashboards.panels.add : T.admin.dashboards.panels.edit
      }
      onModalClose={onModalClose}
      onConfirmClick={_onSubmitPanel}
      headerIcon={isNew ? Icons.Add : Icons.Edit}
      leftSideButton={
        !isNew && (
          <DeleteButton onClick={_onDeletePanel}>
            {' '}
            {T.admin.dashboards.deletepanel}
          </DeleteButton>
        )
      }
      disableActionButton={!isValid}
      className={styles.editorModal}
      helpPath={helpPath}
    >
      <ModelForm
        input={panel}
        onUpdateInput={onUpdateInput}
        sections={SharedSections}
        environment={environment}
      />
      {nestedForms.map((nestedForm, index) => (
        <ModelForm
          key={index}
          input={_.get(panel, nestedForm.nestedKey)}
          onUpdateInput={nestedForm.onUpdateInput}
          sections={nestedForm.sections}
          environment={environment}
        />
      ))}
    </ActionModal>
  );
};

export default React.memo(EditDashboardPanelModal);
