import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import styles from './SignalsToolbar.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import useDetailedGraph from 'js/components/DashboardPage/useDetailedGraph';
import ExportDataDialog from 'js/components/ExportData/ExportDataDialog';
import _ from 'lodash';
import DropdownButton, {
  DropdownButtonOptionType
} from 'ecto-common/lib/DropdownButton/DropdownButton';
import {
  ChartSignal,
  getNodeName
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import ToolbarSearch from 'ecto-common/lib/Toolbar/ToolbarSearch';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import {
  SeriesInterval,
  SingleGridNode
} from 'ecto-common/lib/types/EctoCommonTypes';
import { Moment } from 'moment';
import ToolbarDateTimePicker from 'ecto-common/lib/ToolbarDateTimePicker/ToolbarDateTimePicker';
import APIGen, {
  FullSignalProviderResponseModel,
  EquipmentResponseModel
} from 'ecto-common/lib/API/APIGen';
import LogViewModal from 'js/components/Logs/LogViewModal';
import ManualAlarmsAPIGen, {
  CreateAlarmRequest
} from 'ecto-common/lib/API/ManualAlarmsAPIGen';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { modelFormIsValid } from 'ecto-common/lib/ModelForm/validateForm';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import {
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS
} from 'ecto-common/lib/utils/requestStatus';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import ToolbarFixedSpace from 'ecto-common/lib/Toolbar/ToolbarFixedSpace';
import dimensions from 'ecto-common/lib/styles/dimensions';
import ToolbarMenuDivider from 'ecto-common/lib/Toolbar/ToolbarMenuDivider';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';

const nodeNameWithSignalProviderName = (
  signalProvider: Omit<FullSignalProviderResponseModel, 'signals'>,
  nodeMap: Record<string, SingleGridNode>,
  equipmentMap: Record<string, EquipmentResponseModel>
) => {
  let ret = getNodeName(signalProvider, nodeMap, equipmentMap);

  if (ret !== signalProvider.signalProviderName) {
    ret += ' - ' + signalProvider.signalProviderName;
  }

  return ret;
};

interface SignalsToolbarProps {
  nodeId: string;
  equipmentId: string;
  searchFilter?: string;
  fromDate?: Moment;
  setFromDate: Dispatch<SetStateAction<Moment>>;
  setSearchFilter?(searchFilter: string): void;
  selectedSignalIds?: Record<string, boolean>;
  setSelectedSignalIds?: Dispatch<SetStateAction<Record<string, boolean>>>;
}

const AlarmSeverityLevelsV2 = {
  HIGH: 801,
  MEDIUM: 401,
  LOW: 1
} as const;

const AlarmSeverityTextV2 = {
  [AlarmSeverityLevelsV2.HIGH]: 'A',
  [AlarmSeverityLevelsV2.MEDIUM]: 'B',
  [AlarmSeverityLevelsV2.LOW]: 'C'
};

export type AlarmSeverityLevelV2 =
  (typeof AlarmSeverityLevelsV2)[keyof typeof AlarmSeverityLevelsV2];

const severityOptions = [
  {
    label: AlarmSeverityTextV2[AlarmSeverityLevelsV2.HIGH],
    value: AlarmSeverityLevelsV2.HIGH
  },
  {
    label: AlarmSeverityTextV2[AlarmSeverityLevelsV2.MEDIUM],
    value: AlarmSeverityLevelsV2.MEDIUM
  },
  {
    label: AlarmSeverityTextV2[AlarmSeverityLevelsV2.LOW],
    value: AlarmSeverityLevelsV2.LOW
  }
];

const createAlarmsModels: ModelDefinition<CreateAlarmRequest>[] = [
  {
    modelType: ModelType.TEXT,
    label: T.alarms.columns.comment,
    key: (input) => input.message,
    autoFocus: true,
    hasError: isNullOrWhitespace,
    placeholder: T.equipment.addalarm.placeholder
  },
  {
    modelType: ModelType.OPTIONS,
    label: T.alarms.columns.severity,
    key: (input) => input.severity,
    options: severityOptions
  }
];

type CachedSignal = {
  provider: Omit<FullSignalProviderResponseModel, 'signals'>;
  signal: FullSignalProviderResponseModel['signals'][0];
};

const fillSignalLookup = (
  signalLookup: Record<string, CachedSignal>,
  data: FullSignalProviderResponseModel[]
) => {
  const newLookup = { ...signalLookup };
  for (const provider of data) {
    const providerWithoutSignals = _.omit(provider, 'signals');
    for (const signal of provider.signals) {
      newLookup[signal.signalId] = {
        provider: providerWithoutSignals,
        signal
      };
    }
  }
  return newLookup;
};

/**
 *
 * Usually, the user will select a signal from a table where we already have loaded signal data. However,
 * we also have a persistent list of selected signal ID:s in the URL. If the user has selected signals
 * from multiple nodes, and then reloads the page, we will only fetch signal data for the current node.
 * Selected signal ID:s from other parts of the node tree will not have signal data available.
 *
 * This hook handles this case. First we fetch the signal data for the current node. Most of the time,
 * this will have signal data for all of the selected signals. If not, it means that the user has selected
 * signals from another node. For this case, we fire off another request to fetch the missing signal data using
 * those signal ID:s as parameters.
 *
 * This other request will only need to be fired once, since subsequent additions to the list of selected
 * signals will already contain signal data. This hook maintains a lookup table of signal ID -> signal data
 * for all visited nodes.
 *
 * @param selectedSignalIds
 * @returns
 */
const useSelectedSignalList = (selectedSignalIds: Record<string, boolean>) => {
  const providersRequest = useOperatorSelector(
    (state) => state.signals.providersReqState
  );

  const [signalLookup, setSignalLookup] = useState<
    Record<string, CachedSignal>
  >({});

  const prevReqState = useRef<string>(null);
  const missingSignalsMutation =
    APIGen.Signals.getProvidersBySignalIds.useMutation({
      onSuccess: (providers) => {
        const newLookup = fillSignalLookup(signalLookup, providers);
        setSignalLookup(newLookup);
      },
      retry: 3
    });

  if (prevReqState.current !== providersRequest.state) {
    prevReqState.current = providersRequest.state;
    if (providersRequest.state === REQ_STATE_SUCCESS) {
      const newLookup = fillSignalLookup(
        signalLookup,
        providersRequest.payload as FullSignalProviderResponseModel[]
      );

      setSignalLookup(newLookup);
      const signalIdsWithMissingData = [];

      for (const signalId of _.keys(selectedSignalIds)) {
        if (!newLookup[signalId]) {
          signalIdsWithMissingData.push(signalId);
        }
      }

      if (signalIdsWithMissingData.length > 0) {
        missingSignalsMutation.mutate({
          signalIds: signalIdsWithMissingData
        });
      }
    }
  }

  const loadingInitial =
    !_.isEmpty(selectedSignalIds.length) &&
    _.isEmpty(signalLookup.length) &&
    providersRequest.state === REQ_STATE_PENDING;

  const selectedSignalsList = useMemo(() => {
    const signals: ChartSignal[] = [];
    for (const selectedSignalId of _.keys(selectedSignalIds)) {
      const cachedSignal = signalLookup[selectedSignalId];
      if (cachedSignal) {
        signals.push({
          item: cachedSignal.signal,
          chartSignalId: cachedSignal.signal.signalId,
          group: cachedSignal.provider
        });
      }
    }

    return signals;
  }, [selectedSignalIds, signalLookup]);

  return [
    loadingInitial || missingSignalsMutation.isLoading,
    selectedSignalsList
  ] as const;
};

const SignalsToolbar = ({
  nodeId,
  equipmentId,
  searchFilter,
  setSearchFilter,
  selectedSignalIds,
  setSelectedSignalIds,
  fromDate,
  setFromDate
}: SignalsToolbarProps) => {
  const numSelectedSignals = _.keys(selectedSignalIds).length;
  const [showingExportData, showExportData, hideExportData] =
    useDialogState('show-export-data');
  const [showChartData, , , ChartComponent] = useDetailedGraph();
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const equipmentMap = useCommonSelector((state) => state.general.equipmentMap);
  const [createAlarmsRequest, setCreateAlarmsRequest] =
    useState<CreateAlarmRequest>(null);

  useSelectedSignalList(selectedSignalIds);
  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );

  const [isLoadingSelectedSignalsList, selectedSignalsList] =
    useSelectedSignalList(selectedSignalIds);

  const showInGraph = useCallback(() => {
    toastStore.clear();

    let dateFrom: Moment | number = -1;
    let dateTo: Moment | number = -1;

    if (fromDate != null) {
      dateFrom = fromDate.clone().add(-15, 'day');
      dateTo = fromDate.clone().add(15, 'day');
    }

    showChartData(
      selectedSignalsList,
      'Signals',
      dateFrom,
      dateTo,
      SeriesInterval.MONTH
    );
  }, [fromDate, selectedSignalsList, showChartData]);

  const signalOptions = useMemo(() => {
    const ret: DropdownButtonOptionType[] = _.map(
      selectedSignalsList,
      (signal) => ({
        icon: <Icons.Close />,
        label: signal.item.name,
        subtitle: nodeNameWithSignalProviderName(
          signal.group,
          nodeMap,
          equipmentMap
        ),
        disableCloseOnClick: true,
        action: (e) => {
          e.stopPropagation();
          _.defer(() => {
            setSelectedSignalIds((oldSelectedSignalIds) =>
              _.omit(oldSelectedSignalIds, signal.item.signalId)
            );
          });
        }
      })
    );

    if (ret.length > 0) {
      ret.splice(0, 0, {
        icon: <Icons.Close />,
        label: T.equipment.searchbar.clear,
        action: () => {
          _.defer(() => {
            setSelectedSignalIds({});
          });
        }
      });
    }

    return ret;
  }, [selectedSignalsList, nodeMap, equipmentMap, setSelectedSignalIds]);

  const onShowExportData = useCallback(() => {
    toastStore.clear();
    showExportData();
  }, [showExportData]);

  const [logsOpen, showLogs, hideLogs] = useDialogState('show-logs');
  const selectedIds = useMemo(
    () => Object.keys(selectedSignalIds),
    [selectedSignalIds]
  );

  const addAlarmMutation =
    ManualAlarmsAPIGen.ManualAlarm.alarmsCreateManual.useMutation({
      onSuccess: () => {
        toastStore.addSuccessToast(T.equipment.addalarm.addedalarm);
        setCreateAlarmsRequest(null);
      },
      onError: () => {
        toastStore.addErrorToast(T.common.unknownerror);
      }
    });

  let alarmInfoText: React.ReactNode = '';
  const targetNodeName =
    equipmentMap[equipmentId]?.name ?? nodeMap[nodeId]?.name ?? '';
  if (createAlarmsRequest != null) {
    if (selectedIds.length === 0) {
      alarmInfoText = T.format(
        T.equipment.addalarm.nodeformat,
        <strong key="name">{targetNodeName}</strong>
      );
    } else if (selectedIds.length === 1) {
      const selectedSignal = selectedSignalsList.find(
        (signal) => signal.item.signalId === createAlarmsRequest.signalId
      );

      alarmInfoText = T.format(
        T.equipment.addalarm.nodeformat,
        <strong key="name">{selectedSignal?.item?.name ?? ''}</strong>
      );
    } else {
      alarmInfoText = T.format(
        T.equipment.addalarm.nodeformatwarning,
        <strong key="name">{targetNodeName}</strong>
      );
    }
  }

  const isValid =
    createAlarmsRequest != null &&
    modelFormIsValid(createAlarmsModels, createAlarmsRequest);

  return (
    <>
      <ToolbarSearch
        value={searchFilter}
        wrapperClassName={styles.searchField}
        placeholder={T.equipment.searchbar.searchplaceholder}
        onChange={setSearchFilter}
      />
      <ToolbarFixedSpace width={dimensions.standardMargin} />
      <ToolbarDateTimePicker
        fromDate={fromDate}
        setFromDate={setFromDate}
        placeholder={T.equipment.searchbar.dateplaceholder}
        label={T.equipment.searchbar.datefrom}
      />
      <ToolbarFlexibleSpace />
      <ToolbarItem>
        <DropdownButton
          options={signalOptions}
          loading={isLoadingSelectedSignalsList}
          disabled={numSelectedSignals === 0}
          optionsMenuClassName={styles.optionsMenu}
          withArrow
        >
          <span key={numSelectedSignals}>
            {T.format(
              T.equipment.searchbar.selectedsignalsformat,
              numSelectedSignals
            )}
          </span>
        </DropdownButton>
      </ToolbarItem>
      <ToolbarMenu>
        {featureFlagState.newalarms && (
          <ToolbarMenuButton
            icon={<Icons.Alarm />}
            tooltipText={T.equipment.addalarm.title + '...'}
            loading={isLoadingSelectedSignalsList}
            onClick={() => {
              let reqSignalId: string = null;

              if (selectedIds.length === 1) {
                reqSignalId = selectedIds[0];
              }

              setCreateAlarmsRequest({
                nodeId: equipmentId ?? nodeId,
                signalId: reqSignalId,
                severity: AlarmSeverityLevelsV2.MEDIUM,
                message: ''
              });
            }}
          />
        )}
        <ToolbarMenuButton
          icon={<Icons.Download />}
          disabled={numSelectedSignals === 0}
          loading={isLoadingSelectedSignalsList}
          tooltipText={T.equipment.searchbar.export}
          onClick={onShowExportData}
        />
        <ToolbarMenuButton
          icon={<Icons.Message />}
          disabled={numSelectedSignals === 0}
          loading={isLoadingSelectedSignalsList}
          tooltipText={T.equipment.searchbar.showlogs}
          onClick={showLogs}
        />
        <ToolbarMenuDivider />
        <ToolbarMenuButton
          icon={<Icons.Graph />}
          tooltipText={T.equipment.searchbar.showingraph}
          onClick={showInGraph}
          loading={isLoadingSelectedSignalsList}
          disabled={numSelectedSignals === 0}
          title={T.equipment.searchbar.showingraph}
        />
      </ToolbarMenu>
      <LogViewModal
        isOpen={logsOpen}
        onModalClose={hideLogs}
        nodeId={nodeId}
        selectedSignalIds={selectedIds}
      />
      <ExportDataDialog
        isOpen={showingExportData}
        selectedSignals={selectedSignalsList}
        onModalClose={hideExportData}
      />
      {ChartComponent}
      <ActionModal
        title={T.equipment.addalarm.title}
        headerIcon={Icons.Alarm}
        isOpen={createAlarmsRequest != null}
        onModalClose={() => setCreateAlarmsRequest(null)}
        disableActionButton={!isValid}
        isLoading={addAlarmMutation.isLoading}
        onConfirmClick={() => {
          addAlarmMutation.mutate(createAlarmsRequest);
        }}
      >
        <ModelForm
          input={createAlarmsRequest}
          models={createAlarmsModels}
          setInput={setCreateAlarmsRequest}
        />
        <p>{alarmInfoText}</p>
      </ActionModal>
    </>
  );
};

export default React.memo(SignalsToolbar);
